import Intercom, { update } from "@intercom/messenger-js-sdk";
import { InitType } from "@intercom/messenger-js-sdk/dist/types";
import { User } from "../types/type";

export const initIntercom = (user?: User | null) => {
  const config: InitType = {
    app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    custom_launcher_selector: "#contact-us, #support-here",
  };

  if (user) {
    config.user_id = user.id;
    config.name = user.firstName;
    config.email = user.email;
  }

  if (!window.Intercom) {
    Intercom(config);
  } else {
    update(config);
  }
};
