import { Image } from "@chakra-ui/react";
import { ComponentPropsWithoutRef, useEffect } from "react";
import Optin1 from "../../assets/images/optin-1.png";
import Optin2 from "../../assets/images/optin-2.png";
import SalesArrow from "../../assets/images/sales_arrow.png";
import { avatars } from "../../constants";
import { useIntersectionElement } from "../../hooks/useIntersectionElement";
import { cn } from "../../utils/utils";
import SalesButton from "../SalesButton";
import GetInstantAccessVideo from "./parts/GetInstantAccessVideo";
import GetInstantParticipated from "./parts/GetInstantParticipated";
import GetInstantServices from "./parts/GetInstantServices";
import clsx from "clsx";

export interface GetInstantAccessProps
  extends ComponentPropsWithoutRef<"section"> {}

export const getInstantAccessIntersectingEvent = "getInstantAccessIntersecting";

const GetInstantAccess = ({ className, ...props }: GetInstantAccessProps) => {
  const [ref, entry] = useIntersectionElement();

  useEffect(() => {
    document.dispatchEvent(
      new CustomEvent("getInstantAccessIntersecting", {
        detail: entry?.isIntersecting,
      }),
    );
  }, [entry?.isIntersecting]);

  return (
    <section
      {...props}
      className={cn(
        "relative bg-lightPink pb-[49px] pt-12.5 text-center md:pb-[60px]",
        className,
      )}
    >
      <Image
        src={Optin2}
        alt="Opt In Top"
        position="absolute"
        top="0"
        right="0"
        className="pointer-events-none z-0"
      />
      <Image
        className="pointer-events-none absolute z-0"
        src={Optin1}
        alt="Opt In Bottom"
        bottom="0"
        left="0"
      />

      <h1
        className={clsx(
          "relative mb-6 inline-block text-center text-32 font-black md:mb-8.75 md:text-48",
          "max-w-[319px] md:max-w-[685px] text-black",
        )}
      >
        Please watch this important video with your results below
        <Image
          src={SalesArrow}
          alt="Sale arrow action"
          loading="lazy"
          className={cn(
            "absolute -bottom-[30px] right-[15px] xs:-right-[0] md:-bottom-[80px] md:-right-[45px]",
            "h-[45px] w-[25px] md:h-[80px] md:w-[45px]",
            "origin-top -rotate-45 md:rotate-0",
          )}
        />
      </h1>

      <GetInstantAccessVideo className={cn("mx-auto mb-10")} />

      <SalesButton ref={ref} className="mb-[13px] md:mb-[21px] md:mt-9" />

      <GetInstantParticipated
        items={avatars}
        className="mb-[28px] justify-center md:mb-10"
      />

      <GetInstantServices />
    </section>
  );
};

export default GetInstantAccess;
