import { LabelCheckbox } from "../ui/Checkbox";
import { GraySection } from "../ui";
import { YoutubeScreenIcon } from "../../assets/icons";
import { checkoutPriceOption } from "../../constants";
import Tooltip from "../ui/Tooltip";
import { formatCurrency } from "../../utils/money";
import Cookies from "js-cookie";
import { decodeValue } from "../../libs/common";

export const ChooseYourPrice = ({
  getCheckboxProps,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCheckboxProps: any;
}) => {
  const salesExpire = Cookies.get("_seefg")
    ? decodeValue(Cookies.get("_seefg")!)
    : "";

  const isFinishQuiz = Cookies.get("_vrfq")
    ? decodeValue(Cookies.get("_vrfq")!)
    : "";

  const checkoutPriceOptions =
    (salesExpire === "0" && isFinishQuiz?.length > 0) || salesExpire === "0"
      ? 3
      : 7;

  return (
    <GraySection
      className="mt-5 md:mt-[25px]"
      label="Choose Your Price Option"
      icon={<YoutubeScreenIcon />}
    >
      <LabelCheckbox alwayChecked>
        <div className="flex w-full justify-between">
          <span className="mt-[2px]">5-Day Fast Start</span>
          <div className="flex items-center gap-[10px]">
            <span className="text-16 font-bold-450 text-[#888888] line-through">
              $39
            </span>
            <span className="text-16 font-bold text-black">
              {formatCurrency(checkoutPriceOptions)}
            </span>
          </div>
        </div>
      </LabelCheckbox>

      <Tooltip
        label={
          <>
            <div className="bg-[#FFFB19] text-14 font-bold">
              Get endless video ideas for your channel in 10 minutes
            </div>
            <div className="mt-[6px] text-12 text-xs">
              To keep earning, you need to stay creative and keep posting new
              videos. Jake’s system <b>automates</b> the brainstorming process
              with a proven formula for only <b>$27</b>
            </div>
          </>
        }
        tooltipLabelClassName="!top-[2px] !left-[5px] md:!top-[5px] md:!w-[396px]"
      >
        <LabelCheckbox
          {...getCheckboxProps({
            value: Number(checkoutPriceOption["5_day_start_endless"]),
          })}
        >
          <div className="flex w-full flex-col justify-between align-top md:flex-row">
            <span className="text-pretty md:text-balance">
              5-Day Fast Start + Endless Video Ideas System
            </span>
            <div className="mt-2 flex items-start gap-[10px] md:mt-0">
              <span className="text-16 font-bold-450 text-[#888888] line-through">
                $297
              </span>
              <span className="text-nowrap text-16 font-bold text-black">
                Add for only $27 more
              </span>
            </div>
          </div>
        </LabelCheckbox>
      </Tooltip>
    </GraySection>
  );
};
