import { Box } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { unlockNextVideo, updateVideoProgress } from "../../apis/user";
import { kThreshold, ROUTES } from "../../constants";
import { VideoType } from "../../pages/membership";
import { useAuth } from "../../providers/AuthProvider";
import { Dict } from "../../types/type";
import { cn, getLocalStorage, setLocalStorage } from "../../utils/utils";
import CTAButton from "../CTAButton";
import FastStartPopUp from "../FastStartPopUp";
import RequestNextDayVideo from "../RequestNextDayVideo";
import WistiaPlayer, { VideoProgress } from "../WistiaPlayer";
import FastStartHeadLine from "./FastStartHeadline";

type FastStartVideoProps = {
  selectedVideo: VideoType;
  activeVideoIndex: number;
};

const FastStartMainVideo = ({
  selectedVideo,
  activeVideoIndex,
}: FastStartVideoProps) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showUnlockNextDay, setShowUnlockNextDay] = useState(false);
  const [showQualifyButton, setShowQualifyButton] = useState(false);

  const { user } = useAuth();
  const { watchedVideos } = user || {};
  const initialValue: VideoProgress = { seconds: 0, percent: 0, duration: 1 };

  useEffect(() => {
    // if any video has percentWatched over the threshold, show qualify button forever
    const videos = watchedVideos ?? [];

    if (Array.isArray(videos)) {
      const any = videos.find(
        (x) => x.percent > kThreshold.qualifyButton.percentWatched,
      );
      if (any) setShowQualifyButton(true);
    }
  }, []);

  const onWistiaUpdate = debounce((data: VideoProgress) => {
    /// do not process any update for day 0 or day 6 or completed video
    if (
      activeVideoIndex == -1 ||
      activeVideoIndex == 5 ||
      watchedVideos?.[activeVideoIndex]?.completed
    )
      return;

    const { percent, seconds, duration } = data;
    const progress = seconds / duration;
    console.log("[PROGRESS]::", progress);
    console.log("[PERCENT]::", percent);
    if (
      percent > kThreshold.unlockNextDay.percentWatched &&
      progress > kThreshold.unlockNextDay.seekDuration &&
      !getLocalStorage("requestedVideo")[activeVideoIndex]
    ) {
      setShowUnlockNextDay(true);
    }

    if (
      percent > kThreshold.qualifyButton.percentWatched &&
      progress > kThreshold.qualifyButton.seekDuration
    ) {
      setShowQualifyButton(true);
    }

    if (
      !(
        progress > kThreshold.updateProgress.progress &&
        percent > kThreshold.updateProgress.percentWatched
      )
    ) {
      updateVideoProgress({
        percent,
        progress,
        videoIndex: activeVideoIndex,
      });
    }
  }, 1000);

  const handleClickRequestNextDayVideo = async () => {
    try {
      await unlockNextVideo();
    } catch (error) {
      console.log("Error requesting next day video", error);
    }
  };

  const handleCloseRequestNextDayVideo = () => {
    const requestedVideo: Dict = getLocalStorage("requestedVideo") || {};
    requestedVideo[activeVideoIndex + 1] = true;
    setLocalStorage("requestedVideo", requestedVideo);
    setShowUnlockNextDay(false);
  };

  return (
    <Box>
      <FastStartHeadLine text={selectedVideo.title} />

      <Box
        className={cn(
          `mb-[15px] min-[1440px]:mb-[20px]`,
          showUnlockNextDay && "!mb-0",
        )}
      >
        <WistiaPlayer
          id={selectedVideo.videoId}
          initialValue={initialValue}
          onWistiaUpdate={onWistiaUpdate}
          activeVideoIndex={activeVideoIndex}
        />
      </Box>

      {showUnlockNextDay && (
        <RequestNextDayVideo
          onClick={handleClickRequestNextDayVideo}
          onClose={handleCloseRequestNextDayVideo}
        />
      )}

      {showQualifyButton && (
        <CTAButton onClick={() => navigate(ROUTES.SEE_IF_YOU_QUALIFY)} />
      )}

      <FastStartPopUp isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </Box>
  );
};

export default FastStartMainVideo;
