import day1 from "./assets/sylalbus/Day1.png";
import day2 from "./assets/sylalbus/Day2.png";
import day3 from "./assets/sylalbus/Day3.png";
import day4 from "./assets/sylalbus/Day4.png";
import day5 from "./assets/sylalbus/Day5.png";

import { ComponentPropsWithoutRef } from "react";
import { cn } from "../../utils/utils";
import arrow1 from "./assets/arrows/Arrow1.png";
import arrow2 from "./assets/arrows/Arrow2.png";
import arrow5 from "./assets/arrows/Arrow5.png";

const DayCardOdd = ({
  imgUrl,
  day,
  content,
}: {
  imgUrl: string;
  day: number;
  content: string;
}) => {
  return (
    <div className="mb-[30px] lg:h-[266px] flex w-full flex-col justify-between gap-x-[35px] gap-y-12 lg:mb-0 lg:max-w-[988px] lg:flex-row">

       <div className="relative z-10">
      <img
        src={imgUrl}
        alt=""
        className="z-10 lg:mt-5 h-auto lg:h-[239px] rounded-[12px] object-cover w-full lg:flex lg:max-w-[425px]"
        loading="lazy"
      />
       <div className={`absolute bottom-0 left-0 lg:left-[unset] lg:bottom-[8px] lg:right-0 bg-[#0000006B] p-2.5 px-6 rounded-tr-[10px] lg:rounded-tr-[0] lg:rounded-bl-[0] rounded-bl-[10px] lg:rounded-br-[10px] lg:rounded-tl-[10px]`}>
          <p className="font-bold text-20 text-[#FFFFFF]">
            Day {day}
          </p>
        </div>
      </div>
      <div className="-mt-8 flex-col lg:mt-5">
        <p className="hidden text-28 font-bold md:mb-[15px] md:text-28 lg:flex">
          DAY {day}
        </p>
        <div className="flex flex-col text-18 font-[400] md:mx-auto md:max-w-[528px] md:text-26">
          <p dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </div>
  );
};

const DayCardEven = ({
  imgUrl,
  day,
  content,
  isMargin = false,
  custom= ''
}: {
  imgUrl: string;
  day: number;
  content: string;
  custom?: string;
  isMargin?: boolean
}) => {
  return (
    <div
      className={cn(
        "mb-[30px] flex w-full flex-col justify-between",
        "gap-x-[35px] gap-y-12 lg:mb-0 lg:max-w-[988px] lg:flex-row",
      )}
    >
      <div className="hidden flex-col lg:flex">
        <p className="hidden text-28 font-bold md:mb-[15px] md:text-28 lg:flex">
          DAY {day}
        </p>
        <div className="flex flex-col text-18 font-[400] md:mx-auto md:max-w-[528px] md:text-26">
          <p dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>

      <div className="relative z-10">
        <img
          src={imgUrl}
          alt=""
          className={`z-10 h-auto lg:h-[239px] ${isMargin ? 'mt-5' : 'mt-0'} rounded-[12px] object-cover w-full lg:max-w-[425px]`}
          loading="lazy"
        />
        <div className={`${custom} absolute bottom-0 left-0 bg-[#0000006B] p-2.5 px-6 rounded-tr-[10px] rounded-bl-[10px]`}>
          <p className="font-bold text-20 text-[#FFFFFF]">
            Day {day}
          </p>
        </div>
      </div>

      <div className="-mt-8 flex flex-col lg:mt-0 lg:hidden">
        <p className="hidden font-bold text-[20] lg:flex lg:text-[28]">
          DAY {day}
        </p>
        <div className="flex flex-col text-[18px] md:mx-auto md:max-w-[528px] md:text-[26px] 2xl:text-[28px]">
          <p dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </div>
  );
};

export interface SyllabusProps extends ComponentPropsWithoutRef<"div"> {}

const Syllabus = ({ className, ...props }: SyllabusProps) => {
  return (
    <div {...props} className={cn("flex justify-center", className)}>
      <div className="flex w-full items-center justify-center">
        <div className="flex flex-col items-center justify-center 2xl:w-[1500px]">
          <DayCardOdd
            imgUrl={day1}
            day={1}
            content="In the first video, you'll learn exactly how <br class='sm:hidden' /> the system works, how
            to get the most out of this Fast Start, why YouTube is the best
            recession-proof source of income for the next decade, and how you
            can start <br class='sm:hidden' /> earning from YouTube too!"
          />

          <div className="-my-6 hidden w-1/3 translate-x-8 translate-y-4 transform items-center justify-center lg:flex">
            <img className="h-[120.18px] w-[248.36px]" src={arrow1} alt="" />
          </div>

          <DayCardEven
            imgUrl={day2}
            isMargin
            day={2}
            custom="lg:bottom-[55px]"
            content="Once you understand how the system <br class='sm:hidden' /> works, we'll dive into the most
              crucial aspect: finding your YouTube channel <br class='sm:hidden' /> idea-that's where
              many beginners stumble unless they're following the right process.
              We'll tackle it together, and my team will <br class='sm:hidden' /> be right there to hold
              your hand every step of the way."
          />

          <div className="-mx-[62px] -my-12 hidden w-1/3 -translate-x-7 -translate-y-4 -rotate-6 transform items-center justify-center lg:flex">
            <img className="h-[158.36px] w-[258.36px]" src={arrow2} alt="" />
          </div>

          <DayCardOdd
            imgUrl={day3}
            day={3}
            content={`This is where the magic happens— this is where we'll build your
              entire "profit <br class='sm:hidden' /> factory" together. You'll learn how to turn your
              new videos into profit without having <br class='sm:hidden' /> to create the videos
              yourself.`}
          />

          <div className="-my-6 hidden w-1/3 translate-x-4 transform items-center justify-center lg:flex">
            <img className="h-[120.18px] w-[248.36px]" src={arrow1} alt="" />
          </div>

          <DayCardEven
            imgUrl={day4}
            day={4}
             custom="lg:bottom-[8px]"
            content="This is the part our students love the most-you'll discover how to
              earn your first paycheck from YouTube and how to implement my
              little-known income stream on your channel that allows you to get <br class='sm:hidden' />
              paid even before posting a video."
          />

          <div className="-mx-[62px] -my-12 hidden w-1/3 -rotate-12 transform items-center justify-center lg:flex">
            <img
              className="h-[158.36px] w-[258.36px] -translate-x-4 -translate-y-5 transform"
              src={arrow2}
              alt=""
            />
          </div>

          <DayCardOdd
            imgUrl={day5}
            day={5}
            content="On your last day, I'll show you how to take everything we've built
              over the past four days and transform it into a six-figure
              business that runs on autopilot, requiring <br class'sm:hidden' /> no more than seven
              hours a week to manage."
          />

          <div className="-mt-28 hidden w-1/3 items-center transform translate-x-8 justify-start lg:flex">
            <img className="" src={arrow5} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Syllabus;
