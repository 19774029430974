import { CheckboxGroup, Image } from "@chakra-ui/react";
import { shutdown } from "@intercom/messenger-js-sdk";
import { useCallback, useEffect, useMemo, useState } from "react";
import checkout from "../assets/images/checkout.png";
import {
  BillingInformation,
  CheckoutCountdown,
  CheckoutHeader,
  CheckoutSteps,
  ChooseYourPrice,
  CustomerInformation,
  GuarantyDays,
  LoaderModal,
  MostPopularOption,
  SummaryPrice,
} from "../components/CheckoutPage";
import { PaymentDeclineModal } from "../components/CheckoutPage/PaymentDeclineModal";
import { ReminderModal } from "../components/CheckoutPage/ReminderModal/ReminderModal";
import Footer from "../components/shared/Footer";
import { useCheckout } from "../providers/CheckoutProvider";
import { gtag_report_conversion, GTAGEvent } from "../utils/gtag";
import { LOCAL_STORAGE_KEY } from "../constants";

const CheckoutPage = () => {
  const [hasOpen, setHasOpen] = useState(false);
  const [isLoaderSuccess, setLoaderSuccess] = useState(false);
  const {
    control,
    errors,
    optionValues: value,
    getCheckboxProps,
    watch,
    setValue,
    isProcessing,
    paymentError,
    getValues,
  } = useCheckout();

  // on page load
  useEffect(() => {
    gtag_report_conversion(GTAGEvent.BEGIN_CHECKOUT_FRONT_END, {
      value: 3,
    });
  }, []);

  useEffect(() => {
    const userData = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEY.SALE_USER_DATA) || "{}",
    );

    setValue("country", userData?.country);
    setValue("firstName", userData?.firstName);
    setValue("lastName", userData?.lastName);
    setValue("email", userData?.email);
    setValue("phone", userData?.phone);
  }, [setValue]);

  useEffect(() => {
    const handleScroll = () => {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    };

    const handleBeforeUnload = () => {
      shutdown();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    handleScroll();

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      shutdown();
    };
  }, [isLoaderSuccess]);

  const amount = value.reduce(
    (acc, option) => Number(acc) + Number(option),
    0,
  ) as number;

  const firstName = useMemo(() => {
    const queryData = getValues("firstName");
    if (typeof queryData === "string") {
      return queryData;
    } else {
      return "";
    }
  }, []);

  const handleCloseReminderModalCallback = useCallback(() => {
    setHasOpen(true);
  }, []);

  const callbackSuccessLoader = useCallback(() => {
    setLoaderSuccess(true);
  }, []);

  return (
    <>
      {/* Payment decline and spinning modal */}
      <PaymentDeclineModal />
      {!isProcessing && !paymentError && (
        <ReminderModal
          firstName={firstName}
          onCloseModalCallback={handleCloseReminderModalCallback}
          isLoaderSuccess={isLoaderSuccess}
          hasOpen={hasOpen}
        />
      )}
      <LoaderModal callbackSuccessLoader={callbackSuccessLoader} />
      <CheckboxGroup colorScheme="green" defaultValue={["5_day_start"]}>
        <CheckoutHeader />
        <CheckoutCountdown />
        <CheckoutSteps />

        <div className="container-1200 mb-[100px] mt-[30px] lg:mt-[69px]">
          <div className="grid grid-cols-1 gap-10 overflow-hidden lg:flex">
            {/* left/top column */}
            <div className="p-2.5 lg:max-w-[554px] xl:p-0">
              <CustomerInformation
                control={control}
                errors={errors}
                watch={watch}
                setValue={setValue}
              />
              <ChooseYourPrice getCheckboxProps={getCheckboxProps} />
              <BillingInformation amount={amount} />
              <GuarantyDays />
            </div>
            {/* right/bottom column */}
            <div className="p-2.5 xl:p-0">
              <div className="">
                <p className="mb-6 text-28 font-black">
                  Here’s what you’re getting
                </p>
                <div className="w-full px-[20px] py-[4px] md:px-[14px] md:py-[7px]">
                  <Image
                    src={checkout}
                    alt="checkout"
                    w={{
                      base: "330px",
                      md: "577px",
                    }}
                    height={{
                      base: "158px",
                      md: "278px",
                    }}
                    objectFit="cover"
                    margin="0 auto"
                  />
                </div>
              </div>
              <MostPopularOption getCheckboxProps={getCheckboxProps} />
              <SummaryPrice />
            </div>
          </div>
        </div>
      </CheckboxGroup>
      <Footer />
    </>
  );
};

export default CheckoutPage;
