import { InputHTMLAttributes, forwardRef } from "react";
import { cn } from "../../../utils/utils";

type InputProps = {
  className?: string;
  placeholder?: string;
} & InputHTMLAttributes<unknown>;

export const Input = forwardRef(
  (
    { className, placeholder, ...field }: InputProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <input
        {...field}
        ref={ref}
        placeholder={placeholder}
        className={cn(
          "px-3 md:px-[16px] py-2 rounded-lg border border-[#E7E7E7]",
          "placeholder:text-base",
          className,
        )}
      />
    );
  },
);
