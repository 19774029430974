import StorytellingParagraph from "../../ui/StoryTelling/StoryTellingParagraph";

export const WhyTheSecondOptionMoreExpensive = () => {
  return (
    <div className="bg-[#FFF8F8] px-6 py-12.5 md:px-0 md:py-15">
      <div className="mx-auto max-w-[597px]">
        <StorytellingParagraph
          title={
            <p className="mx-auto max-w-[308px] md:max-w-[597px] md:uppercase">
              Why is the second option more expensive?
            </p>
          }
        >
          <p>
            The second choice, priced at $297, is higher because it not only
            includes everything from the first option —{" "}
            <i>
              all my fill-in-the-blank scripts, templates, formulas, ideas,
              hooks, stories, and more that will get you your first YouTube
              paycheck sooner than anyone else because I did the hard work for
              you
            </i>{" "}
            — but it also provides my
            <span className="font-semibold">
              {" "}
              step-by-step guide to installing several recession-proof income
              streams on your new channel.
            </span>
          </p>
          <p>
            This is the same system my students have successfully used to earn
            multiple thousands of dollars right from the start on YouTube
            without making any videos.
          </p>
          <p>See this student here:</p>
          <img src="/images/upsell1/hoan-thai.png" className="py-5" />
          <p>
            I'll give you multiple ways to install multiple income streams on
            your channel, and it's up to you to choose which ones you want to
            use.
          </p>
        </StorytellingParagraph>
      </div>
    </div>
  );
};
