import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import React, { ComponentPropsWithoutRef, ReactNode } from "react";
import { HashLink } from "react-router-hash-link";
import { cn } from "../../utils/utils";
import { Minus } from "lucide-react";
import { ROUTES } from "../../constants";
import { useSalesExpired } from "../CountDown/useSalesExpired";

export type SalesFAQProps = ComponentPropsWithoutRef<typeof Accordion> & {
  allowExpandedAll?: boolean;
};

export interface FaqItem {
  label: ReactNode;
  content: ReactNode;
}

const faqs: FaqItem[] = [
  {
    label: "WHO IS THIS FOR?",
    content: (
      <>
        It doesn't really matter whether you're working a 9-5, studying,
        freelancing, or <br className="sm:hidden" /> even running a business.
        What truly <br className="sm:hidden" /> matters is this - if you're
        someone who's looking for more ways to improve your life, this is the
        right choice for you. You're <br className="sm:hidden" /> going to get
        new tools that will allow you <br className="sm:hidden" /> to start your
        own faceless channel, <br className="sm:hidden" /> improve financially
        and spark a wildfire of passion and excitement in your life.
      </>
    ),
  },
  {
    label: "WILL THIS WORK FOR ME?",
    content: (
      <>
        I discovered this system when I had <br className="sm:hidden" /> nothing
        but a laptop and a dog pissing all over my place. I didn't have much
        time, or any knowledge about YouTube at all. But I had the most
        important thing - a burning desire to change my life. I used it as fuel
        to achieve my goals, and well - it paid off.{" "}
        <br className="sm:hidden" /> What I <br className="hidden xl:inline" />{" "}
        wanna say is if your life story is at least in some way similar to mine
        - you <br className="sm:hidden" /> have at least 30 - 60 minutes a day,
        a computer, and the desire - then you can make this work.{" "}
        <span className="font-medium text-[#F40000] underline">
          <HashLink smooth to={`${ROUTES.SALES}#resultspeak`}>
            Check out the results above
          </HashLink>
        </span>{" "}
        and take the jump. Damn it's only three bucks!
      </>
    ),
  },
  {
    label: (
      <>
        HOW DOES YOUR SYSTEM <br className="sm:hidden" /> WORK?
      </>
    ),
    content: (
      <>
        The Faceless Income System works by leveraging YouTube's algorithm for
        your <br className="sm:hidden" /> own financial advantage. Using my
        tools, templates, and formulas, you'll be able to automate the whole
        process, “cheat” the algorithm, get thousands of views on your videos,
        and turn them into profit. Inside <br className="sm:hidden" /> the
        fast-start, you'll discover multiple ways to turn views into profit -
        and it's up to you which one you choose as your primary source of
        income. Each of them works, as I've been using them all for over 5 years
        on my own channels.
      </>
    ),
  },
  {
    label: (
      <>
        WHAT IS INCLUDED IN THE <br className="sm:hidden" /> FAST-START?
      </>
    ),
    content: (
      <>
        Inside the fast-start, you'll discover 5 short but valuable videos that
        will get your new faceless channel off the ground. Along{" "}
        <br className="sm:hidden" /> with all the bonuses, such as the{" "}
        <strong>
          Channel Launch Checklist, 100+ Channel Ideas, and Channel Profit
          Calculator
        </strong>
        , you'll be able to plug my entire system into your{" "}
        <br className="sm:hidden" /> new channel and turn it to a new income
        source for yourself. You're getting all the tools that have helped me
        make over $1 million from YouTube just in the last year.
      </>
    ),
  },
  {
    label: (
      <>
        DO I HAVE TO CREATE VIDEOS <br className="sm:hidden" /> ON MY OWN?
      </>
    ),
    content: (
      <>
        You can if you want to, it's easy, but you're also getting a blueprint
        for automating the whole process using my outsourcing method. Once you
        use my outsourcing method, you'll be able to automate the whole video
        creation process - all the way from generating an idea to the
        realization <br className="sm:hidden" /> - without even moving a finger
        and doing the work yourself.
      </>
    ),
  },
  {
    label: (
      <>
        IS THIS FAST-START COVERED <br className="sm:hidden" /> BY A 100%
        MONEY-BACK GUARANTEE?
      </>
    ),
    content: (
      <>
        Yes, it truly is. As I said earlier, if you feel{" "}
        <br className="sm:hidden" /> like you haven't gotten your money's{" "}
        <br className="sm:hidden" /> worth, just email us at{" "}
        <span className="font-medium text-[#F40000] underline">
          hello@viralprofits.yt
        </span>{" "}
        within 365 days of your purchase, and you will get a full refund!
      </>
    ),
  },
  {
    label: (
      <>
        CAN'T I JUST FIND IT <br className="sm:hidden" /> ANYWHERE ELSE, JAKE?
      </>
    ),
    content: (
      <>
        You can, if you're willing to spend over five years trying to piece
        together free <br className="sm:hidden" /> tutorials on YouTube, figure
        it out on your own, spend half a million dollars on testing what works
        and fail multiple times like I <br className="sm:hidden" /> did. My
        battle-tested, proven-to-work tactics are your shortcut to what's
        working right now. I spent the last 5 years going through every obstacle
        and learning from each setback so you don't have to.
      </>
    ),
  },
  {
    label: (
      <>
        DO I NEED PAST EXPERIENCE <br className="sm:hidden" /> WITH YOUTUBE?
      </>
    ),
    content: (
      <>
        Nope, the entire fast-start is designed to <br className="sm:hidden" />{" "}
        get your new channel going, no matter <br className="sm:hidden" /> your
        experience level. If you've never <br className="sm:hidden" /> made a
        YouTube video, or a YouTube channel… heck if you barely ever open
        YouTube… this is for you. All you need to <br className="sm:hidden" />{" "}
        do is follow the steps I show you inside the fast-start, and you're good
        to go.
      </>
    ),
  },
];

const SalesFAQ = ({
  className,
  allowExpandedAll,
  defaultIndex,
  ...props
}: SalesFAQProps) => {
  const isSalesExpired = useSalesExpired();

  const handleRefocusing: React.MouseEventHandler<HTMLHeadingElement> = (
    event,
  ) => {
    const currentTargeting = event.currentTarget;
    setTimeout(() => {
      if (currentTargeting.getAttribute("data-expanded") !== "true") return;

      currentTargeting.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 150);
  };

  return (
    <Accordion
      {...props}
      allowMultiple={false}
      defaultIndex={
        allowExpandedAll ? faqs.map((_, index) => index) : defaultIndex
      }
      className={cn("text-black", className)}
    >
      {faqs.map((faq) => (
        <>
          <AccordionItem
            className={cn("border-none py-5 first:pt-0 md:py-7.5")}
          >
            {({ isExpanded }) => (
              <>
                <h2
                  data-expanded={isExpanded}
                  className={isSalesExpired ? "scroll-mt-10" : "scroll-mt-20"}
                  onClick={handleRefocusing}
                >
                  <AccordionButton className="flex justify-between !p-0 hover:!bg-transparent">
                    <span className="text-left text-20 font-[900] md:text-32">
                      {faq.label}
                    </span>
                    {isExpanded ? (
                      <Minus className="h-[16px] w-[16px] shrink-0 text-[#484848]" />
                    ) : (
                      <AccordionIcon className="shrink-0 text-left" />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel
                  px-0el
                  className="mt-2 !px-0 text-left text-18 font-normal md:text-26"
                  pb={4}
                >
                  {faq.content}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          <hr className="h-0.5 w-full rounded border-0 bg-black last:hidden" />
        </>
      ))}
    </Accordion>
  );
};

export default SalesFAQ;
