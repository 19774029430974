import { useLayoutEffect } from 'react';

function useSmoothScrollToHash() {
  useLayoutEffect(() => {
    const hash = window.location.hash;

    const scrollToElement = () => {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    };

    const timeoutId = setTimeout(scrollToElement, 500);

    return () => clearTimeout(timeoutId);
  }, [window.location.hash]);

}

export default useSmoothScrollToHash;
