import { useMediaQuery } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import "./index.css";

type TooltipProps = {
  label: React.ReactNode;
  children: React.ReactNode;
  tooltipLabelClassName?: string;
  containerClassName?: string;
  rootMarginBottom?: string;
};

const Tooltip = ({
  label,
  tooltipLabelClassName,
  containerClassName,
  rootMarginBottom = "-20%",
  children,
}: TooltipProps) => {
  const [isShowOnce, setIsShowOnce] = useState(false);
  const [isAlreadyShow, setIsAlreadyShow] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.8,
    rootMargin: `0% 0% ${rootMarginBottom} 0%`,
  });

  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const showToolTipMobile = inView && !isAlreadyShow && isMobile;

  useEffect(() => {
    if (inView && !isShowOnce) {
      setIsShowOnce(true);
    }
  }, [inView]);

  useEffect(() => {
    if (!inView && isShowOnce) {
      setIsAlreadyShow(true);
    }
  }, [inView, isShowOnce]);

  const handleCloseTooltip = () => {
    setIsAlreadyShow(true);
  };

  return (
    <div
      className={`viral-tooltip__container ${containerClassName || ""} ${
        showToolTipMobile ? "active" : ""
      }`}
      ref={ref}
    >
      {children}

      <div
        className={`viral-tooltip md:w-[383px] ${tooltipLabelClassName || ""}`}
      >
        <div
          className="absolute right-[3px] top-[0px] z-[999] rotate-45 text-20 md:hidden"
          onClick={handleCloseTooltip}
        >
          +
        </div>
        {label}
      </div>
    </div>
  );
};

export default Tooltip;
