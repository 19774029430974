import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import { Dict } from "../../types/type";
import { useCheckout } from "../../providers/CheckoutProvider";

type GooglePayButtonProps = {
  amount: number;
};

const GooglePayButton = ({ amount }: GooglePayButtonProps) => {
  const { handleCheckout } = useCheckout();

  // Collect.js
  useEffect(() => {
    configNMICollectJS();
  }, [amount]);

  const configNMICollectJS = () => {
    window.CollectJS.configure({
      variant: "inline",
      // 1. Callback when processing NMI Card/Google Pay
      callback: async (res: Dict) => {
        const { firstName, lastName, phone, country } =
          res.wallet.billingInfo || {};

        const { email } = res.wallet || {};

        console.log("user info", {
          email,
          country,
          firstName,
          lastName,
          phone,
        });

        await handleCheckout({
          amount,
          platform: "nmi",
          payment_token: res.token,
          firstName,
          lastName,
          email,
          phone,
          country,
          last4Digit: res.wallet?.cardDetails,
        });
      },
      fields: {
        googlePay: {
          selector: ".googlePayButton",
          emailRequired: true,
          buttonType: "buy",
          buttonColor: "default",
          buttonLocale: "en",
          billingAddressRequired: true,
          billingAddressParameters: {
            phoneNumberRequired: true,
          },
        },
      },
      currency: "USD",
      country: "US",
      price: amount.toString(), // price must provided to Collect.js as a string when using Google Pay.,
    });
  };

  return <Box className="googlePayButton" />;
};

export default GooglePayButton;
