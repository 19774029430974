export const CloseIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8907 0.400391L6.9405 5.34939L1.9915 0.400391L0.341833 2.05006L5.29083 6.99906L0.341833 11.9481L1.9915 13.5977L6.9405 8.64872L11.8907 13.5977L13.5403 11.9481L8.59133 6.99906L13.5403 2.05006L11.8907 0.400391Z"
        fill="#484848"
      />
    </svg>
  );
};
