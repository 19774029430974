
      // only load on production
      if (import.meta.env.PROD) {
        const head = document.head;
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src =
          "https://t.viralprofits.yt/v1/lst/universal-script?ph=1256d09d6e45f855698d87f00e3248ecd56d77a86f9a53e4debb7331c7520150&tag=!clicked&ref_url=" +
          encodeURI(document.URL);
        head.appendChild(script);
      }
    