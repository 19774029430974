import { ReactNode } from "react";

export const RedButton = ({
  children,
  onClick,
  className,
  disabled,
}: {
  children: ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <button
      onClick={onClick}
      className={`relative md:py-5 flex w-full items-center justify-between gap-[10px] rounded-[15px] bg-[#D92D20] p-[18px] text-center text-16 font-semibold text-white  md:max-w-[715px] md:px-10 md:text-24 ${disabled ? "disabled:cursor-not-allowed disabled:opacity-50" : ""} ${className}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
