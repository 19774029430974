import { useLocation, useNavigate } from "react-router-dom";
import { membershipCourse, ROUTES } from "../../constants";
import { SectionProps } from "./types/MembershipCourses.type";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";
import { useIsMobile } from "../../hooks/useIsMobile";

type MembershipCourseProps = {
  courseContent: SectionProps[];
  courseName: (typeof membershipCourse)[keyof typeof membershipCourse];
  isOpenedMenu: boolean;

};

const MembershipCourse = ({
  courseContent,
  courseName,
  isOpenedMenu
}: MembershipCourseProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();

  const queryParams = new URLSearchParams(location.search);
  const sectionIndex = queryParams.get("sectionIndex");
  const contentIndex = queryParams.get("contentIndex");

  const activeSectionIndex = sectionIndex ? parseInt(sectionIndex, 10) : 0;
  const activeContentIndex = contentIndex ? parseInt(contentIndex, 10) : 0;

  const currentContentDisplay =
    courseContent[activeSectionIndex]?.content[activeContentIndex];

    const isLastContent =
    activeContentIndex ===
      courseContent[activeSectionIndex].content.length - 1 &&
    activeSectionIndex === courseContent.length - 1;

  const handleMoveToNextContent = () => {
    const actualCourseName = courseName.split("=")[1];

    if (
      activeContentIndex ===
      courseContent[activeSectionIndex].content.length - 1
    ) {
      // Move to next section
      navigate(
        `${ROUTES.BLUEPRINT}?course=${actualCourseName}&sectionIndex=${activeSectionIndex + 1}&contentIndex=0`,
      );
    } else {
      // Move to next content
      navigate(
        `${ROUTES.BLUEPRINT}?course=${actualCourseName}&sectionIndex=${activeSectionIndex}&contentIndex=${activeContentIndex + 1}`,
      );
    }
  };


  return (
    <>
<div
        className={`min-h-[calc(100vh - 60px)] flex h-fit flex-col md:min-h-screen md:flex-row ${isMobile ? "bg-white" : "bg-[#F9FAFB]"}`}
      >        {/* Left Panel */}
        {((isOpenedMenu && isMobile) || !isMobile) && (
          <LeftPanel
            courseName={courseName}
            activeSectionIndex={activeSectionIndex}
            activeContentIndex={activeContentIndex}
            courseContent={courseContent}
          />
        )}

        {/* Video Screen */}
        {((!isOpenedMenu && isMobile) || !isMobile) && (
          <RightPanel
            currentContentDisplay={currentContentDisplay}
            handleMoveToNextContent={handleMoveToNextContent}
            isLastContent={isLastContent}
          />
        )}
      </div>
    </>
  );
};

export default MembershipCourse;
