import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import clsx from "clsx";
import ArrowRightIcon from "../ArrowRightIcon";

type RequestNextDayVideoProps = {
  onClick: () => void;
  onClose: () => void;
};

const RequestNextDayVideo = ({
  onClick,
  onClose,
}: RequestNextDayVideoProps) => {
  const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure();

  return (
    <>
      <Box
        className="flex justify-center md:justify-end"
      >
        <button
          color="#fff"
          className="mb-4 rounded-2xl text-white px-[24px] py-[12px] rounded-t-none flex items-center justify-center"
          style={{
            background:
              "linear-gradient(97.66deg, #F79009 -6.61%, #DC6803 114%)",
          }}
          onClick={() => {
            onClick();
            onOpen();
          }}
        >
          <Text
            className="capitalize text-16 md:text-20"
            fontWeight="500"
            mr={{
              base: "2px"
            }}
            mt={{
              base: "2px"
            }}
          >
            Request to unlock next day now
          </Text>
          <ArrowRightIcon />
        </button>
      </Box>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          onCloseModal();
        }}
        size={{
          md: "lg",
        }}
      >
        <ModalOverlay />
        <ModalContent
          className="!max-w-[calc(100%-48px)] md:w-full md:!max-w-[614px] rounded-[20px] p-6 md:p-7.5"
          borderRadius="20px"
        >
          <ModalHeader
            textAlign="center"
            padding={0}
            fontWeight="900"
            className="mb-10 !text-26"
          >
            🎉 Great! You’re getting ahead of others… 🎉
          </ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody className="rounded-[20px] !p-0">
            <div className="mb-10 text-center text-18">
              Our team will review your request and give you access to the{" "}
              <br className="hidden md:inline-flex" /> next day as soon as
              possible. We’ll let you know via email{" "}
              <br className="hidden md:inline-flex" /> when your access is
              ready.
            </div>
            <button
              className={clsx(
                "h-11 w-full rounded-[18px] md:h-9 md:rounded-lg",
                "bg-primary text-16 font-bold text-white md:uppercase",
              )}
              onClick={() => {
                onClose();
                onCloseModal();
              }}
            >
              I’m looking forward 🔥
            </button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RequestNextDayVideo;
