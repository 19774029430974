import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { useCurrentCountryCode } from "../../../hooks";

export const FlagDetection = ({ className }: { className?: string }) => {
  const countryCode = useCurrentCountryCode();

  return (
    <div className={className}>{getUnicodeFlagIcon(countryCode as string)}</div>
  );
};
