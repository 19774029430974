import { cn } from "../../utils/utils";
import CountdownLogic from "../ui/CountdownLogic";

export const CheckoutCountdown = () => {
  return (
    <div className="mt-[33px]">
      <CountdownLogic initialMinutes={10} initialSeconds={0}>
        {({ minutes, seconds, addMoreMinutes }) => {
          return (
            <div
              className={cn(
                "container-1200 flex items-center justify-center gap-3",
                minutes < 3 ? "flex-col md:flex-row" : "",
              )}
            >
              <div className="flex w-fit items-center justify-center gap-[10px] rounded-[17px] bg-black px-3 py-1 font-bold text-white">
                <div className="text-14 font-medium leading-[12px]">
                  We’re holding your spot for
                </div>{" "}
                <div className="flex min-w-[74px] justify-center rounded-[20px] bg-[#D92D20] px-[15px] py-0 text-center">
                  {`${minutes < 10 ? `0${minutes}` : minutes}:${
                    seconds < 10 ? `0${seconds}` : seconds
                  }`}
                </div>
              </div>
              {minutes < 3 ? (
                <div
                  className="w-fit cursor-pointer rounded-[17px] bg-[#FEE4E2] px-[14px] py-2 text-14 font-bold text-[#D92D20]"
                  onClick={() => addMoreMinutes(3)}
                >
                  Need more time?
                </div>
              ) : (
                ""
              )}
            </div>
          );
        }}
      </CountdownLogic>
    </div>
  );
};
