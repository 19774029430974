import CryptoJS from "crypto-js";

export function encodeValue(value: string | number): string {
  const secretKey =
    "b1f5371549125d1cbcd7dec67ba3fd9304febcedca03b09fb14340ae16a717dfcb5f3660968810bfb3a448ca1bb8deb26e5dd2897983bbfe8947371b4864a84b"; // Your encryption key
  const encrypted = CryptoJS.AES.encrypt(
    value.toString(),
    secretKey,
  ).toString();
  return encrypted;
}

export function decodeValue(encryptedValue: string): string {
  const secretKey =
    "b1f5371549125d1cbcd7dec67ba3fd9304febcedca03b09fb14340ae16a717dfcb5f3660968810bfb3a448ca1bb8deb26e5dd2897983bbfe8947371b4864a84b"; // Your decryption key (must match encryption key)
  const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);
  return decrypted;
}
