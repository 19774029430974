import { SectionProps } from "../types/MembershipCourses.type";

export const launchpadCourseContent: SectionProps[] = [
  {
    section: "START HERE",
    content: [
      {
        title: "How to use this (absolutely essential!)",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/59d5d30e-c8a6-434b-b94b-9c8431e724bf",
      },
    ],
  },
  {
    section: "The Script Template Jake's using for EVERY video",
    content: [
      {
        title: "The Script Template Jake's using for EVERY video",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/022bf530-0475-45a2-b735-52f6923acfe3",
      },
    ],
  },
  {
    section: "Virality Formulas",
    content: [
      {
        title: "How to use the Virality Formulas",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/6b8a94e3-050c-4573-ab8b-bd6b31a2e504",
      },
      {
        title: "The hot take",
        htmlSrc: `1. _ will not be [something people commonly believe]

      1. Thumb: It will be _
      2.
      <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/5e719e28758e494b98187f3501dc4a70b03c04cd88fe40e2a18e2fa8b3fbeed4" "/>

2. _ is [opposite of what people commonly believe]
1.
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/8bafceca75ec4829a5584ab86dbba2d922d21c545e6c4f3fad37901ed18a38cb" "/>
2.
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/ab2dda85dc534c4e8e87c292897768a5c81534b8092c43438470e1c2c5ebb220" "/>

 3. _ would/will not be [something people commonly believe]
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/ca35b191712a4f7caa46ab79cc3a2bc28b8f54e271384d9c90948d96c15701c4" "/>

4._ deserves their/it’s _
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/cc3b4638e79b45ec99dbd16571eab5539a4a210f350a4bd9a391d843be543060" "/>

5. Thumb text: Are [subject] [opposite of what people believe]?
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/ea441bc65d294a38bb7c328287ae945629b8f5dea41148eab65302e15d967fbb" "/>

`,
      },
      {
        title: "Unknown interesting story",

        htmlSrc: `1. Remember when [interesting story people don’t know about]

      1. 
      <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/ecc9cdcf409843b5b509db9a056a4b541a32da22a3a8491a8135b49776b00374" "/>

2. Why is EVERYONE [doing this]

 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/1caf8eea0f094249ae4f92a867160d4c6ab0d16bd8d943ebad60366e005da6ca" "/>

 3. How _
    &emsp;1.
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/022b5f6a91fe434884bbde98e3f12f70e03426ae79cd4942acad8e2ceb942ecc" "/>
    &emsp;2.
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/aead66343291464cb49a29c4276ea7a579f41d1dc1794559bce29d7fd659255e" "/>
  &emsp;3.
   <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/a0750d77db024da8a9a675fda8dc9666f8c2c3c29c2740f0bb56d140c479ed97" "/>
 &emsp;4.
    <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/79e3e970d4b840f0b7917678726768483ed742312a194509bc6abf65db3e5dd9" "/>

 4. The _ You’ve Never Heard Of
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/341e8c1eb08c475ca0a28a323db3379290f583420a06435c8080e9f4aba6d940" "/>

5. Something terrible is happening _
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/64a07e19710649dfb26f53c1df2a8fe662cb25dd357f42f6b2bcc0c3174bbde8" "/>

6. _: The World’s [weirdest, other adjective] _
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/ef3c003bcbcd41f5bf00a6c0a7ae2dc63c7134e325c74521bd8fa771a277c511" "/>


7. _: The World’s [weirdest, other adjective] _
<img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/f49e8da6723f41aebe680a2105aa81c110d9056406504b72afc852db06ff7304" "/>

8. Why _
    &emsp;1.
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/c31da9c3291e4433b7eafbb7c9460d02ed23a5830dc04b76b1f9b32b1ba85bfb" "/>
    &emsp;2.
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/ca69ff8968384df19190b31bb66913e4c7f0df8134b24af0b3254f9889a23f9f" "/>
  &emsp;3.
   <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/fddda2182046444c818d062702daae1847d9a4138a5340ca9baa897271f3eefa" "/>
 &emsp;4.
    <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/092290fe27de4d6dac013ac89975d2fb7b5b76845080435c89004f1ca79b5c2e" "/>

9. _: The World’s [weirdest, other adjective] _
<img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/a4627b49ed574861ae6bd82a52e0c0d984e76af742544613a8a26ef576fc107a" "/>

`,
      },
      {
        title: "Cool sounding title",
        htmlSrc: `
       1. [COOL SOUNDING TOPIC]: [DESCRIPTION OF WHAT IT IS]
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/3f4c32f6727949e2890d99a1c36aa668bbd7127174cf477d815a9016f42daa6f" "/>


2. [COOL SOUNDING NAME]: [DESCRIPTION OF WHAT IT IS]
<img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/806976c2edf845419fb89d597c681768eb2c4e9b8de0420286b170f98872248e" "/>
`,
      },
      {
        title: "Something people are already familiar with",
        htmlSrc: `
       1. Why You’re Wrong About _
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/052e004d1cae4606bfd18fca5fdafa0edccdc7dd93eb4e30ae2dc3645eb6e4f3" "/>


2. _ Is Worse Than You Thought
<img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/afabe768694e4b2cb400f5222531656b263724f5c7d14a17804ff2804cd54671" "/>

3. If you think _ is bad, check out ...
<img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/beaccf9e684d481fb8184b64863700be807b27bf6ce24be3bf3c35ea4a14ee36" "/>
`,
      },
      {
        title: "Explaining something everyone is wondering about",
        htmlSrc: `1. Why [something everyone is wondering about but doesn’t understand]

      &emsp;1. 
      <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/f728a0379f7a4f1cb4011fb3225f54e1d51acc305e3d426e92549e388120e8ee" "/>
       &emsp;2. 
      <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/f46028a9e8ce46dba2c16b4958ad40d074270bf532de408793abd234359e87ed" "/>
       &emsp;3. 
      <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/3a38dc3f136948ff9d971c1eaf21ac6225805badbcfb475dad906579392a6de7" "/>
       &emsp;4. 
      <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/54a59dfc819e45eea8799489c6876158738d4743c1de46d490896d496c5fe1a9" "/>
       &emsp;5. 
      <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/ad477c2b563c41578273ef7dea73a5ce19006aedc9e44235ad0f0262510e8dc3" "/>
       &emsp;6. 
      <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/97f6ad0de49b4cc79a6840019add86af292904aeef8549808d2814530f29d586" "/>
       &emsp;7. 
      <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/8f734fc9e4424065a24202d0e0639e333aba7a61c7514616a4ebde7ff0b80cf1" "/>

2. For this example, they allude to Nazi money

 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/6517259fd7a14e06bcab45898898e1911e205eb97ca340d5902ef8d08ccd02b9" "/>

 3. Literally just stating what the thing is
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/7078cc4d1fb34da097d9cdd9260d3c83cf6632bf129247bd932c5f1f3faf9b52" "/>
 
 4. What Makes _
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/d150459185604dcc99166d81b937856dd8d7b3b1e031439c975af2d2070d32c6" "/>


`,
      },
      {
        title: "You’ve been victimized",
        htmlSrc: `
        1. We All Got Tricked into _
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/5f7951078fee427db950cc6458b25b8eb6705f0acd734cc2b94573aff0434fe1" "/>
`,
      },
    ],
  },

  {
    section: "Viral Hook Templates",
    content: [
      {
        title: "Viral Hook Templates",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/352dd7b6-78c3-4cc7-9160-62b7c855e698",
        downloadSrc: [
          "https://docs.google.com/document/d/1QEFrp_G9ZYmESnjlXo1t_qgHAebKiD6IYRNlWRUzt3o/edit#heading=h.wb6kules94m5",
        ],
      },
    ],
  },

  {
    section: "Viral Script Templates",
    content: [
      {
        title: "How to use the Viral Scripts Templates!",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/8f6a03e2-af7c-4818-8971-357c461441e7",
      },
      {
        title: "The Minute-by-Minute Mystery/Specific-Event Script",
        downloadSrc: [
          "https://docs.google.com/document/d/1WaLj5W8Mx34K5DDLFQEXGvUXlLg8QzXFtz9s8HadJdY/edit#heading=h.qz5wywywhz7",
        ],
      },
      {
        title: "The Documentary/Autobiography Style Script",
        downloadSrc: [
          "https://docs.google.com/document/d/1VeTnlnhKFD9ATz1A_evgzgjEiMDP-IciL4_ZcJAta_U/edit#heading=h.hx7jmw38oerd",
        ],
      },
      {
        title: "The Hot-Take/Expose Script",
        downloadSrc: [
          "https://docs.google.com/document/d/1HhvTNsXsuShBVPvmk0olGCcB52D1hP9KsrnsY3MhNCI/edit#heading=h.z31kpp4r827n",
        ],
      },
      {
        title: "The How This Happened Script",
        downloadSrc: [
          "https://docs.google.com/document/d/1aJxA6McPimHwguoP9vpqwXtuRBvDVuKL-b9dp9MZniY/edit#heading=h.4zb8deofs6yz",
        ],
      },
      {
        title: "The Trending Topic/Current Event Script",
        downloadSrc: [
          "https://docs.google.com/document/d/1YtCZUmJOkB6LCZuWx2SMwJtpJzH3uZe8uBrayti0dAM/edit#heading=h.t63x2xy21xl1",
        ],
      },
      {
        title: "The Listicle Style Script",
        downloadSrc: [
          "https://docs.google.com/document/d/1u69wduuPAr0B-ly9pNWr5kb--eRVEs-NOxKKaEI7bbA/edit#heading=h.21wjfp8ccqst",
        ],
      },
    ],
  },

  {
    section: "BONUS: How to Hire a Thumbnail Designer!",
    content: [
      {
        title: "Sites to find affordable viral thumbnail designers",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/3a5c94bf-9efb-421d-9073-c4ec0479bb96",
        htmlSrc: `Sites:

Behance.net: My favorite website to find thumbnail designers

Dribbble.com: Another similar site to Behance with a lot of good designers

Upwork: My second favorite website to find thumbnail designers

Fiverr: You'll be able to find good thumbnail designers on here but they will typically cost more

Price range

The price range you should be paying: $5-25

Avoid "thumbnail design agencies" or other "marketing agencies" that promise you white-glove service for viral thumbnails. They charge way too much and since they have multiple clients, you're not going to get very good results or attention.

Also, avoid thumbnail designers that market themselves as "professional YouTube thumbnail designers". You should NOT pay anything crazy like $100 or more for a thumbnail. Jake has had a friend pay $200-300 for a single mediocre thumbnail! This is a rip-off. These designers know the value of a thumbnail and will try to overcharge you.`,
      },
      {
        title: "Outreach message to send to potential designers",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/9fafea62-9865-4593-b4fc-77d60e081796",
        htmlSrc: `If you find a thumbnail designer you like on one of these sites, send them a message saying that you'd like to pay them for a test thumbnail to see if they're the right fit. 

These platforms do not allow you to ask them to do a free thumbnail. This will get you banned from these platforms which is why we want to pay for a test thumbnail. 

To save money, you can try paying less for a test thumbnail, like $5, and say if you end up hiring them, you'll pay them more, like $20 a thumbnail for example.

Outreach message template (feel free to change it as you need and put it into your own language):

Hi [NAME],

I run a YouTube channel and I’m looking for a thumbnail designer. If you do good with this job, I will have much more consistent work for you.

Below I’ve attached a thumbnail design that I would like you to make. I’d like you to recreate this thumbnail EXACTLY like it is along with the changes I want below. Do not change anything else about the thumbnail besides these changes.

[LINK TO THUMBNAIL OR ATTACH SCREENSHOT OF IT]

Here are the EXACT changes I want made to it:

- [DESCRIBE EXACTLY WHAT YOU WANT CHANGED ABOUT IT, THE MORE DESCRIPTIVE YOU ARE ABOUT IT, THE LESS MISTAKES THEY WILL MAKE]

I need this done by [DEADLINE]

I will pay you [$5-10] USD for this via [PayPal or whatever payment method you can use]

If you do well and I end up hiring you, I will pay you [$15-20] USD per thumbnail.

Let me know if you can get this done!

Thanks,

[YOUR NAME]`,
      },
      {
        title: "Job post template",
        videoSrc:
          "hhttps://iframe.mediadelivery.net/embed/204326/e661699f-16dc-47d2-abfb-44e76a400848",
        htmlSrc: `Alternatively, you can also publish a job post on these sites

Job post title: 

Long Term Thumbnail Designer for [your channel topic] YouTube channel (remote, consistent work)

Job post description:

We are looking for a skilled thumbnail designer for our YouTube channel.

If hired, there will be consistent work and this will be a long-term position.

Below we've attached a thumbnail design that you would be making for us.

[LINK TO THUMBNAIL OR ATTACH SCREENSHOT OF IT]

If you’re confident you can make thumbnails that look EXACTLY like this, then this is the job for you. 

If you do NOT think you can create thumbnails that look EXACTLY like this, do NOT apply.

To apply, send us a link to 2-3 thumbnail designs you have made (make sure the link is viewable). Do NOT send more than 2-3 examples. 

If it's good, we will pay you to create a test thumbnail for us to test your skills.

If you think you can do this, apply right now before it's too late.

Send this message to good applicants:

Once you post the job post and you start getting applicants in, review the applicants' submissions, pick the best ones, and send them this message:

Hello!

I liked your application. I will pay you [$5-10] USD for this via [PayPal or whatever payment method you can use] to make a test thumbnail.

If you do well and I end up hiring you, I will pay you [$15-20] USD per thumbnail. Below I’ve attached a thumbnail design that I would like you to make. I’d like you to recreate this thumbnail EXACTLY like it is along with the changes I want below. Do not change anything else about the thumbnail besides these changes.

[LINK TO THUMBNAIL OR ATTACH SCREENSHOT OF IT]

Here are the EXACT changes I want made to it:

- [DESCRIBE EXACTLY WHAT YOU WANT CHANGED ABOUT IT, THE MORE DESCRIPTIVE YOU ARE ABOUT IT, THE LESS MISTAKES THEY WILL MAKE]

I need this done by [DEADLINE]

Let me know if you can get this done!

Thanks,

[YOUR NAME]`,
      },
      {
        title: "Watch Jake go through REAL applicants!",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/a3ab7f1a-d945-4739-929d-ad4426a7b585",
      },
    ],
  },

  {
    section: "BONUS: How to Hire a Script Writer!",
    content: [
      {
        title: "Sites to find affordable viral script writers",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/1004d19d-34c6-4061-bbd3-a48c4fc98841",
        htmlSrc: `Sites:

LinkedIn: Make a job post

Flexjobs

Working Nomads

Remote.co

Best Writing

Upwork: You're going to get lower quality applicants here but it's still worth a try

Important Notes

It's important to note that if you're making videos in English, you will need native English-speaking writers. 

If your videos are for a US-based audience or a Western English-speaking audience in general, you will need a writer from an English-speaking country like the US, Canada, UK, Australia, etc.

That is why writers will be the most expensive position you hire for.

That is why if you're on a tight budget, I recommend outsourcing this last. Either write it yourself or with the help of AI.

Price range

If you're ready to hire a writer, here is what you should be paying.

For a good English writer to write a ~10-minute faceless video, expect to pay anywhere from around $200-500 per script, or $0.15-0.20 a word.

Whether you choose to pay per script or per word is up to you. I would recommend per script so you know exactly what to expect and you have no surprises.

If you need them to write a longer video in the future, you can always negotiate then.

Your price will of course vary depending on if you're hiring from the US, or a more affordable country like Canada (which I like for writers). It will also vary depending on how long you want your videos to be, how hard or easy the videos are to research. 

So I would recommend starting low. If you're not getting enough applicants or the applicant test submissions are too low quality, then bump up the price and try again.`,
      },
      {
        title: "Outreach message to send to potential writers",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/d734a2fe-6881-49b7-ada2-da3e11751aa4",
        htmlSrc: `If you find a writer you like on one of these sites, send them a message saying that you'd like to pay them for a test hook to see if they're the right fit. 

These platforms do not allow you to ask them to write a free test hook. This will get you banned from these platforms which is why we want to pay for a test script.

Outreach message template (feel free to change it as you need and put it into your own language):

Hi [NAME],

I run a YouTube channel and I’m looking for a writer. I looked at your profile and like your writing!

This would be a stable, part-time, remote writing position.

It’s okay if you have never written a YouTube video before. We will provide the training you need.

Here’s an example of the EXACT writing style we are looking for you to write: [LINK TO YOUTUBE VIDEO YOU WANT A WRITER TO MODEL THE STYLE OF (NOT FROM A DIRECT COMPETITOR)]

We are looking for the same [describe the writing style of the video you linked to in 1-2 sentences (funny, light, informative, etc), along with 1-2 sentences of any changes you would want to the writing style]

If you’re interested let me know and I’d love to pay you [$20-40] USD for this via [PayPal or whatever payment method you can use] to write a test script for the hook of a video (the first 1-2 minutes of a video).

If you do well and I end up hiring you, I will pay you [$200-500 USD per script or $0.15-0.20 per word].

Thanks,

[YOUR NAME]

If they reply that they're interested in writing the test hook, send them this message:

Great!

Here is what you'll write for the [$20-40] USD test hook (the first 1-2 minutes of a video).

Again, if you do well and I end up hiring you, I will pay you [$200-500 USD per script or $0.15-0.20 per word]. 

First, watch this video on some general guidlines when it comes to writing YouTube videos: https://drive.google.com/file/d/1U6tL8k6KFq2vFTSDUyrhdssGBJ5Ds_le/view

Below I’ve attached a YouTube video that I would like you to model:

[LINK TO YOUTUBE VIDEO WITH THE HOOK YOU WANT THEM TO MODEL]

I'd like you to write your test hook in this EXACT SAME WRITING STYLE but for this topic: [link to the main inspiration for your video]

Here are the EXACT changes I want made to it:

- [THIS PART IS OPTIONAL, DESCRIBE ANYTHING WHAT YOU WANT CHANGED ABOUT IT, THE MORE DESCRIPTIVE YOU ARE ABOUT IT, THE LESS MISTAKES THEY WILL MAKE]

I need this done by [DEADLINE]

Let me know if you can get this done!

Thanks,

[YOUR NAME]`,
      },
      {
        title: "Job post template",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/d33a8149-d0ff-4537-8415-2f0e9bd73728",
        htmlSrc: `Alternatively, you can also publish a job post on these sites

Job post title: 

Long Term WRITER For [your channel topic] YouTube Channel (Remote)

Job post description:

Stable, part-time, remote writing position. 

We are looking for a writer for our YouTube channel about [your channel topic]. 

It’s okay if you have never written a YouTube video before. We will provide the training you need.

Here’s an example of the EXACT writing style we are looking for you to write: [LINK TO YOUTUBE VIDEO YOU WANT A WRITER TO MODEL THE STYLE OF (NOT FROM A DIRECT COMPETITOR)] 

We are looking for the same [describe the writing style of the video you linked to in 1-2 sentences (funny, light, informative, etc)] 

If you’re confident you can write in this EXACT SAME STYLE without changing anything, then apply for this ASAP.

If you do not think you can write in this EXACT style, do NOT apply.

Here are our other requirements. Everything mentioned is here for a reason and is required. Do NOT apply if you don’t meet all these requirements:

Soft skills:

- Fluent in English (English is your first language)

- EXTREME ATTENTION TO DETAILS

- Quick at responding

- Be receptive to constructive feedback

- Be able to learn new things quickly

- Be able to use The Google Suite: Gmail, Drive, Docs, Sheets, Calendar, etc You’ll be researching the video, outlining a summary, and writing the script.

[insert any other relevant information]

To apply, send us a link to 2-3 of the best things you've written (make sure the link is viewable). Do NOT send more than 2-3 examples. 

If this job interests you, apply ASAP.

Send this message to good applicants:

Once you post the job post and you start getting applicants in, review the applicants' submissions, pick the best ones, and send them this message:

Hello!

I liked your application. I will pay you [$20-40] USD for this via [PayPal or whatever payment method you can use] to write a test script for the hook of a video (the first 1-2 minutes of a video).

If you do well and I end up hiring you, I will pay you [$200-500 USD per script or $0.15-0.20 per word]. 

First, watch this video on some general guidlines when it comes to writing YouTube videos: https://drive.google.com/file/d/1U6tL8k6KFq2vFTSDUyrhdssGBJ5Ds_le/view

Below I’ve attached a YouTube video that I would like you to model:

[LINK TO YOUTUBE VIDEO WITH THE HOOK YOU WANT THEM TO MODEL]

I'd like you to write your test hook in this EXACT SAME WRITING STYLE but for this topic: [link to the main inspiration for your video]

Here are the EXACT changes I want made to it:

- [THIS PART IS OPTIONAL, DESCRIBE ANYTHING WHAT YOU WANT CHANGED ABOUT IT, THE MORE DESCRIPTIVE YOU ARE ABOUT IT, THE LESS MISTAKES THEY WILL MAKE]

I need this done by [DEADLINE]

Let me know if you can get this done!

Thanks,

[YOUR NAME]`,
      },
      {
        title: "Watch Jake go through REAL applicants!",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/2252b6d3-619a-4d3c-93c9-3e10ac995633",
        downloadSrc: [
          "https://docs.google.com/document/d/1EI3mn6LpolJ86wUntcax00gRQq46WYYXFS-InaxXclg/edit",
          "https://docs.google.com/document/d/1vyHMQysVUdqcTcW-1vVkoZftTdq8s_-MpNGTPa4R0nM/edit",
          "https://docs.google.com/document/d/1sdJargjyNSVzfQXWW2iMsmqZFb1USXs_P-epCvSYJ1s/edit",
        ],
      },
    ],
  },
];
