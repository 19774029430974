import FastStartLinkSection from "../components/FastStartLinkSection";
import {
  ThereAreNoProgramLikeThis,
  YoutubeCreatorAroundTheWorld,
} from "../components/homepage";
import JoinTheProgram from "../components/homepage/JoinTheProgram";
import LatestVideo from "../components/homepage/LatestVideo";
import MeetInstructor from "../components/homepage/MeetInstructor/MeetInstructor";
import WhatWeDoIsDifferent from "../components/homepage/WhatWeDoIsDifferent";
import WherePassionMeetsProfits from "../components/homepage/WherePassionMeetsProfits/WherePassionMeetsProfits";
import WinningStudents from "../components/homepage/WinningStudents";
import Footer from "../components/shared/Footer";

const HomePage = () => {
  return (
    <>
      <FastStartLinkSection />
      <WherePassionMeetsProfits />
      <MeetInstructor />
      <WhatWeDoIsDifferent />
      <YoutubeCreatorAroundTheWorld />
      <WinningStudents title="1,500+ winning students and counting…" className="mt-[90px] overflow-hidden" />
      <ThereAreNoProgramLikeThis className="mb-[90px]" />
      <JoinTheProgram />
      <LatestVideo />
      <Footer />
    </>
  );
};

export default HomePage;
