import StorytellingParagraph from "../../ui/StoryTelling/StoryTellingParagraph";
import viralityAll from "../../../assets/images/downsell/the-virality-formula-crop.png";
import viralTriggersTemplateCrop from "../../../assets/images/downsell/the-viral-triggers-template-crop.png";
import viralHooks from "../../../assets/images/downsell/viral-hooks-crop.png";

export const StoryTellingSection = () => {
  return (
    <div className="gap-15 px-6 pb-0 pt-12.5 md:pb-[33px] md:pt-20">
      <div className="mx-auto max-w-[529px]">
        <StorytellingParagraph title="You Just Clicked The “No Thanks” Button…">
          <p>Which means you just said no to the '7-Figure Launchpad'...</p>
          <p>Which means you said no to skipping the guesswork…</p>
          <p>Which means you're going to have to figure it out by yourself…</p>
          <p>
            You're going to have to figure out how to connect your channel idea
            with the virality pattern…
          </p>
          <p>
            You're going to have to figure out how to turn new subscribers into
            loyal watchers…
          </p>
          <p>You're going to have to create your own scripts…</p>
          <p>You're going to have to figure out how to attract sponsors…</p>
          <p>
            You're going to have to figure out how to negotiate deals with
            sponsors…
          </p>
          <p>
            You're going to have to figure out which sponsors you can trust…
          </p>
          <p>
            Eventually, you're going to have to tie it all together by yourself.
          </p>
          <p>But we both know there's a chance that might not happen…</p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-12.5 max-w-[577px] md:mt-15">
        <StorytellingParagraph title="Because Maybe You Didn't Want To Say No…">
          <p>Maybe you didn't have a choice…</p>
          <p>Sometimes, things get in the way of saying yes…</p>
          <p className="font-bold italic">
            And I suspect this time, the price of the '7-Figure Launchpad' got
            in the way…
          </p>
          <p className="font-bold italic">
            or maybe, you're not even sure if this will help you in some way…
          </p>
          <p>And there's nothing wrong with that…</p>
          <p>
            But I’m here to ensure that a few dollars won't stand between you
            and your success...
          </p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-12.5 max-w-[631px] md:mt-15">
        <StorytellingParagraph title="So Here’s What I Did For You...">
          <p>I’m going to take the issue of price away,</p>
          <p>
            <mark>And I’m going to let you pay half now and half later,</mark>
          </p>
          <p>
            So you can start using the '7-Figure Launchpad' right from the
            beginning of the Fast-Start.
          </p>
          <p className="font-bold">So here's how it works:</p>
          <p>Instead of the original price tag…</p>
          <p>I'll break that down into 2 payments of $97, one month apart.</p>
          <p>
            The '7-Figure Launchpad' is designed to help you launch your new
            channel faster than it would take you if you had to build everything
            from scratch because most of the work has been done for you…
          </p>
          <p>
            And I'm going to give it to you right now, for half of the original
            price.
          </p>
          <p>
            So you can start using the launchpad as soon as possible and pay the
            second half later after you launch your new faceless channel.
          </p>
          <p>And remember… I'm going to give you my entire template library…</p>
          <p>The same templates I've been using for over 5 years now…</p>
          <p>
            The same templates I spent something over $1 million for just
            “testing”...
          </p>
          <p>
            The same templates that have made me more than a million dollars in
            the last year.
          </p>
          <p>So for you, that means that…</p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-12.5 max-w-[803px] md:mt-15">
        <StorytellingParagraph
          title={
            <span className="capitalize">
              In the next 5 days, you could have your own faceless channel
              generating new videos and thousands of views and be on your way to
              turning them into profit...
            </span>
          }
        >
          <p>
            All you have to do is get instant access to the '7-Figure Launchpad'
            and copy everything.
          </p>
          <p>Then just fill in the blanks, and you're good to go.</p>
          <p>From a single idea to profit in just a few weeks.</p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-12.5 max-w-[832px] md:mt-15">
        <StorytellingParagraph
          title={
            <span className="capitalize">
              The 7-figure launchpad includes everything you need to launch your
              new profitable faceless channel as fast as possible...
            </span>
          }
        >
          <p>
            Because I'm going to give you everything you need to launch a
            7-Figure Faceless channel.
          </p>
          <p>
            It will allow you to instantly implement everything you're going to
            discover in the Fast-Start...
          </p>
          <p>
            And turn it into a tangible faceless channel that generates views
            and turns them into big fat monthly paychecks.
          </p>
          <p>
            You don't even have to know how to script a video or how to write a
            hook…
          </p>
          <p>Because…</p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-12.5 max-w-[736px] md:mt-15">
        <StorytellingParagraph title="You're Going To Get Access To The Entire Formula…">
          <div className="mx-auto">
            <img
              className="mb-2.5 h-[209.86px] w-[336px] md:h-[304px] md:w-[487px]"
              src={viralityAll}
              alt="the-virality-all"
            />
          </div>
          <p>
            You're getting The Virality Formula - This is the exact formula that
            every single viral video on YouTube follows.
          </p>
          <p>
            It will allow you to get your first video viral as soon as humanly
            possible the very first time you try it, thanks to the viral
            psychology you'll discover inside - but don't worry…
          </p>
          <p>And while it might sound really complicated…</p>
          <p>It's not…</p>
          <p>
            In fact, all you have to do is fill in the blanks{" "}
            <span className="italic">(and I'll help you to do that)</span>, and
            you're on your way to going viral with absolute certainty.
          </p>
          <p>But that's not all…</p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-12.5 max-w-[736px] md:mt-10">
        <StorytellingParagraph title="Because You're Also Going To Get Access To Viral Triggers…">
          <div className="mx-auto">
            <img
              className="mb-2.5 h-[183px] w-[293px] md:h-[304px] md:w-[487px]"
              src={viralTriggersTemplateCrop}
              alt="the-virality-all"
            />
          </div>

          <p>
            This is my ultimate blueprint for creating high-return-on-investment
            videos.
          </p>
          <p>
            If you want a viral video, you'll have to say certain phrases in
            that video…
          </p>
          <p>Or have someone say those things for you.</p>
          <p>
            So using the Viral Triggers, you'll be able to create viral videos
            on the spot, faster than anyone else, for pennies on the dollar
            because these phrases have already been written for you.
          </p>
          <p>
            This means you're gonna be able to take whatever video topic you
            wanna cover, fill in the info into these script templates, and boom
            - you have a very solid script that you know has been proven to go
            viral before, with barely any effort.
          </p>
          <p>
            And you can use these templates on every single one of your videos.
          </p>
          <p> No matter what your channel is about.</p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-12.5 max-w-[736px] md:mt-15">
        <StorytellingParagraph title="You're Also Getting My Ultimate Package For Attention Robbery - I Call It My “Viral Hooks”">
          <div className="mx-auto">
            <img
              className="mb-2.5 h-[236px] w-[316px] md:h-[290px] md:w-[406px]"
              src={viralHooks}
              alt="the viral hooks"
            />
          </div>
          <p>
            Have you ever noticed how Mr. Beast and other big players on YouTube
            always start their videos in the exact same way?
          </p>
          <p>
            The reason is simple: the hook is the most important part of the
            video.
          </p>
          <p>
            You see, it doesn't matter how many people watch your video if
            everyone just clicks off of it right away.
          </p>
          <p>
            That's why the first 60 seconds of your video are by far the most
            important.
          </p>
          <p>In those first 60 seconds, people either get bored and leave…</p>
          <p className="italic">
            This tells the YouTube algorithm to stop recommending your video…
          </p>
          <p>
            Or you can steal that person's attention with the hook, thereby
            making them watch the entire video.
          </p>
          <p>
            The more people stick around and watch your video, the more the
            YouTube algorithm will push it to more viewers.
          </p>
          <p>
            And <i>that</i> is why I'm giving you my Viral Hooks.
          </p>
          <p>
            Normally, it requires creativity, but with these templates, all you
            have to do is fill in the blanks since all the thinking behind it
            has already been done for you.
          </p>
          <p>So there you have it…</p>
          <p>That's everything you're gonna get in the '7-Figure Launchpad'.</p>
          <p>
            And that's everything you need to accelerate your journey to making
            $5,000-$10,000 / month from YouTube…
          </p>
          <p>Without having to figure out everything yourself.</p>
          <p>
            Without having to go through trials and errors for months or years.
          </p>
          <p>Without giving up on yourself again.</p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-12.5 max-w-[900px] md:mt-15">
        <StorytellingParagraph
          title={
            <>
              You Now Have A Second Chance To Make{" "}
              <span className="md:italic">The Most Important Decision</span>{" "}
              You’re Going To Make On Your Entire YouTube Journey…
            </>
          }
        >
          <p>Because this single decision is going to decide one thing…</p>
          <p>If you're going to figure out everything yourself…</p>
          <p>go through trial and error….</p>
          <p>
            until you finally start going viral consistently and making money
            from YouTube…
          </p>
          <p className="font-bold">
            Or take everything we've done, tested, and proven and use it for
            yourself…
          </p>
          <p>So you can skip the learning and get straight to the earning.</p>
          <p>
            That's why I decided to still give you the '7-Figure Launchpad'...
          </p>
          <p>
            And instead of you paying a few hundred bucks upfront... like you
            would with the '7-Figure Launchpad'...
          </p>
          <p>You're only paying a fraction of that.</p>
          <p>And again, this goes without saying, but I'll say it anyway…</p>
          <p>
            This is backed by our 14-day no-questions-asked money-back
            guarantee…
          </p>
          <p>If you're not happy or don't feel this is for you…</p>
          <p>Just let us know, and we will fully refund everything…</p>
          <p>
            All you have to do is hit the upgrade button below and the '7-Figure
            Launchpad' will be waiting for you in the members area.
          </p>
          <p>See you inside,</p>
          <p>– Jake Tran</p>
        </StorytellingParagraph>
      </div>
    </div>
  );
};
