import { HashLink } from "react-router-hash-link";
import { ROUTES } from "../../../constants";
import StorytellingParagraph from "../../ui/StoryTelling/StoryTellingParagraph";
import { FirstYoutubePaycheckBtn } from "../../UpSell";

export const Guarantee = () => {
  return (
    <>
      <div className="mx-auto mt-15 overflow-hidden px-6 pb-15 md:mt-20 md:max-w-[800px] md:overflow-visible">
        <StorytellingParagraph
          titleProps={{
            ribbon: true,
            className: "mb-10 md:mb-7.5 max-w-[309px] md:max-w-[800px]",
          }}
          title={
            <p className="max-w-[309px] capitalize md:max-w-[814px]">
              Backed By Our 14-Day No Questions Asked Money-Back Guarantee
            </p>
          }
          className="mb-10"
        >
          <p>And again, this goes without saying, but I’ll say it anyway…</p>
          <p>
            This is backed by our 14-day no-questions-asked money-back
            guarantee…
          </p>
          <p>If you’re not happy or don’t feel this is for you…</p>
          <p>Just let us know, and we will fully refund everything…</p>
          <p>
            All you have to do is hit the upgrade button below and the '7-Figure
            Launchpad' will be waiting for you in the members area.
          </p>
        </StorytellingParagraph>
        
        
        <HashLink smooth to={`${ROUTES.DOWNSELL}#regular-price`}>
          <FirstYoutubePaycheckBtn />
        </HashLink>
      </div>
    </>
  );
};
