import StorytellingParagraph from "../../ui/StoryTelling/StoryTellingParagraph";

export const SecondStoryTellingSection = () => {
  return (
    <div className="px-6 md:px-0">
      <div className="mx-auto mt-15 max-w-[570px]">
        <StorytellingParagraph
          title={
            <span className="capitalize">
              That creating a video is only half the battle
            </span>
          }
        >
          <p>The other half of it is actually making money off of it.</p>
          <p>
            And the only way to do that is to make sure every video you put out
            gets views.
          </p>
          <p>Or, to put it another way - goes viral.</p>
          <p>
            That can only happen only if you do the work and implement
            everything as I say.
          </p>
          <p>
            Because when your video flops, you won’t get any views, which means
            you won’t get paid from YouTube, sponsors won’t want to pay you, and
            it’s not fun.
          </p>
          <p>But here's the kicker...</p>
          <p>It has nothing to do with the “systems” not working...</p>
          <p>And it has everything to do with people not doing the work.</p>
          <p>
            But maybe you're the 1% who can do the work... who wants to do the
            work...
          </p>
          <p>Or maybe you're part of the 99% who don't.</p>
          <p>
            However, the truth is that it's perfectly fine if you don’t want to
            do the work...
          </p>
        </StorytellingParagraph>
      </div>
      <div className="mx-auto mt-15 max-w-[652px]">
        <StorytellingParagraph
          title={
            <span className="capitalize">
              As creating a profitable faceless channel takes time...
            </span>
          }
        >
          <p>Coming up with your channel idea takes time...</p>
          <p>Creating videos takes time...</p>
          <p>Hiring freelancers to do all the work for you takes time...</p>
          <p>Coming up with new video ideas takes time...</p>
          <p>It all takes time.</p>
          <p>And regardless of whatever the reason is...</p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-15 max-w-[652px]">
        <StorytellingParagraph
          title={
            <span className="capitalize">
              If you don't have a YouTube channel that gets views...
            </span>
          }
        >
          <p>
            You don't have a profitable faceless channel; you just have a hobby.
          </p>
          <p>
            And today, because you’ve made it here - I'm not going to let that
            happen to you.
          </p>
          <p>I'll save you those months of "trying to figure it out"</p>
          <p>And I'm going to help you implement my virality formula.</p>
          <p>
            So that's why I'm here to give you something that will get you the
            results even faster.
          </p>
          <p>And that's the only way to really ensure your success.</p>
          <p>
            Because it's the ONLY way we ensure that you actually do the work
            that needs to be done.
          </p>
          <p>
            But you should know that this is not for everyone, and it might not
            be for you...
          </p>
          <p>
            If you're ok with doing all the work yourself that's required to get
            to making $5,000 - $10,000 from YouTube... You can skip this video
            and go directly to the next step.
          </p>
          <p>But on the other hand, if you want a shortcut...</p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-15">
        <StorytellingParagraph
          title={
            <p className="mx-auto max-w-[445px] capitalize">
              Then I decided to do something for you...
            </p>
          }
        >
          <p>
            I'll be giving you my virality formula that I call the{" "}
            <b>“7-Figure Launchpad”.</b>
          </p>
          <p>
            The reason it's called the '7-Figure Launchpad' is that it has made
            me over a million dollars!
          </p>
          <p>It's the exact same system that I use on each of my channels...</p>
          <p>The exact same system that my current students are using...</p>
          <p>
            And it's the exact same system that secretly sets the success of
            every single video I post.
          </p>
          <p>But... Why would I give this away?</p>
          <p>Well because as I said earlier...</p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-15 max-w-[675px]">
        <StorytellingParagraph
          title={
            <span className="capitalize">
              My North Star is not how much money I make. It's different...
            </span>
          }
        >
          <p>My North Star is how many successful channels I help create.</p>
          <p>
            This means that for the first time ever, I'm going to reveal the
            secrets I've been using for the past five years...
          </p>
          <p>The secrets that I’ve kept close to my chest...</p>
          <p>
            Just so I can ensure{" "}
            <b>
              <span className="border-b border-b-black">your</span>
            </b>{" "}
            success.
          </p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-15 max-w-[678px]">
        <StorytellingParagraph
          title={
            <span className="capitalize">
              So Here's Everything That You're Getting With The 7-Figure
              Launchpad...
            </span>
          }
        >
          <img
            src="/images/upsell1/viral-all.png"
            alt="viral-all.png"
            className="mx-auto h-[183px] w-[293px] md:h-[304px] md:w-[487px]"
          />
          <p>
            You get The Virality Formula - This is the exact formula that every
            single viral video on YouTube follows.
          </p>
          <p>
            It will allow you to get your first video viral as soon as humanly
            possible the very first time you try it, thanks to the viral
            psychology you'll discover inside - but don't worry...
          </p>
          <p>And while it might sound really complicated...</p>
          <p>It’s not...</p>
          <p>
            In fact, all you have to do is fill in the blanks (and I'll help you
            to do that), and you're on your way to going viral with absolute
            certainty.
          </p>
          <p>But that’s not all...</p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-15 max-w-[755px]">
        <StorytellingParagraph
          title={
            <span className="capitalize">
              Because You're Also Going To Get Access To Viral Triggers
              Template...
            </span>
          }
        >
          <img
            src="/images/upsell1/the-viral-triggers-template.png"
            alt="the-viral-triggers-template.png"
            className="mx-auto h-[183px] w-[293px] md:mb-0 md:h-[304px] md:w-[487px]"
          />
          <p>
            This is my ultimate blueprint for creating high-return-on-investment
            videos.
          </p>
          <p>
            If you want a viral video, you’ll have to say certain phrases in
            that video...
          </p>
          <p>Or have someone say those things for you.</p>
          <p>
            So using the Viral Triggers, you’ll be able to create viral videos
            on the spot, faster than anyone else, for pennies on the dollar
            because these phrases have already been written for you.
          </p>
          <p>
            This means you’re gonna be able to take whatever video topic you
            wanna cover, fill in the blanks in these script templates, and boom
            - you have a very solid script that you know has been proven to go
            viral before, with barely any effort.
          </p>
          <p>
            And you can use these templates on every single one of your videos.
          </p>
          <p>No matter what your channel is about.</p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-15 max-w-[678px]">
        <StorytellingParagraph
          title={
            <span className="capitalize">
              You're Also Getting My Ultimate Package For Attention Robbery - I
              Call It My “Viral Hooks”
            </span>
          }
        >
          <img
            src="/images/upsell1/viral-hooks.png"
            alt="the-virality-formula.png"
            className="mx-auto mb-[15px] h-[236px] w-[316px] md:mb-4 md:h-[290px] md:w-[406px]"
          />
          <p>
            Have you ever noticed how Mr. Beast and other big players on YouTube
            always start their videos in the exact same way?
          </p>
          <p>
            The reason is simple: the hook is the most important part of the
            video.
          </p>
          <p>
            You see, it doesn’t matter how many people watch your video if
            everyone just clicks off of it right away.
          </p>
          <p>
            <i>
              This tells the YouTube algorithm to stop recommending your
              video...
            </i>
          </p>
          <p>
            That’s why the first 60 seconds of your video are by far the most
            important.
          </p>
          <p>
            On the other hand, if you can <b>steal</b> that person's attention
            with the hook,
          </p>
          <p>They will stick around and watch your entire video,</p>
          <p>
            And the more of your video they watch, the more the YouTube
            algorithm will push it to more viewers, getting you the viral views
            and money you’ve always dreamed of.
          </p>
          <p>
            And <i>that</i> is why I’m giving you my Viral Hooks.
          </p>
          <p>
            Normally, making a great hook requires creativity, but with these
            templates, all you have to do is fill in the blanks since all the
            thinking behind it has already been done for you.
          </p>
          <p>So there you have it...</p>
          <p>That’s everything you’re gonna get in the '7-Figure Launchpad'</p>
          <p>Like I said earlier...</p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-15 max-w-[678px]">
        <StorytellingParagraph
          title={
            <span className="capitalize">
              <i>This</i> is the most important decision you're going to make on
              your entire journey...
            </span>
          }
        >
          <p>Because this single decision is going to decide one thing:</p>
          <p>Whether you spend months trying to do everything on your own...</p>
          <p>Or if you skip the learning and get straight to earning.</p>
          <p>
            Which would allow you to finally start living your life on your own
            terms even faster.
          </p>
          <p>You’ll have the choice to wake up when you want...</p>
          <p>
            You’ll have the choice to take time off and spend it with your
            family whenever you want...
          </p>
          <p>
            You’ll have the choice travel around the world and go to the places
            you’ve always wanted...
          </p>
          <p>
            You’ll have the choice to do whatever you want whenever you want...
          </p>
          <p>
            And you’ll be on your way to achieving that freedom with the
            '7-Figure Launchpad'.
          </p>
          <p>So now let me ask you...</p>
          <p>What is that freedom worth to you?</p>
          <p>What is your time worth to you?</p>
          <p>
            What is the ability to take care of yourself & your loved ones and
            give them their dream life worth to you?
          </p>
          <p>I can tell you what it’s like to me and what I paid for it...</p>
          <p>
            I spent over 5 years of trial and error and nearly half a million
            dollars to discover the secrets inside the '7-Figure Launchpad'...
          </p>
          <p>
            I did all of the hard work and figured everything out, so you don’t
            have to...
          </p>
          <p>
            And because my #1 goal is to create as many success stories as
            possible...
          </p>
          <p>You won't be paying even 1% of that.</p>
          <p>Even though I could easily ask for at least $5,000...</p>
          <p>
            Because that’s just one sponsorship or affiliate deal using the
            '7-Figure Launchpad'...
          </p>
          <p>
            And you won’t even be paying half of that, even though that's a
            reasonable deal.
          </p>
          <p>
            But we're not here to make deals - we're here to create success
            stories...
          </p>
          <p>
            This is why you’ll get the entire '7-Figure Launchpad' for as low as
            ONLY $197.
          </p>
          <p>That’s it.</p>
          <p>And the real kicker?</p>
          <p>
            You’re completely covered with a 100% no-BS money-back guarantee...
          </p>
          <p>Try out the '7-Figure Launchpad'...</p>
          <p>And if you feel like these secrets have no use for you...</p>
          <p>Or if you're not happy with anything for any reason at all...</p>
          <p>Or if you'd rather try to do the work all by yourself...</p>
          <p>
            Just shoot me an email in the first 14 days of getting your
            '7-Figure Launchpad'...
          </p>
          <p>And we'll give you every penny back.</p>
          <p>
            And we’ll let you keep all the views the '7-Figure Launchpad' helps
            you get.
          </p>
          <p>We won't ask you why.</p>
          <p>We won't ask you a single question.</p>
          <p>You'll just get every penny back.</p>
          <p>So, there’s a button below.</p>
          <p>Look at that button and realize what it means for you...</p>
          <p>Your time...</p>
          <p>Your freedom...</p>
          <p>Your life...</p>
          <p>So hit the button now, and I'll see you on the inside.</p>
          <p>Inside, where months of work get cut down to weeks...</p>
          <p>
            If you’re truly serious about finally turning your dreams into
            reality...
          </p>
          <p>Then this is not even a decision for you.</p>
          <p>You already know exactly what to do.</p>
          <p>You know you need to hit that button below.</p>
          <p>Hit the button now and lock in your success.</p>
          <p>Hit the button now and make the hard work easy.</p>
          <p>
            Hit the button now and try it out for 14 days before you even
            decide.
          </p>
          <p>For whatever reason. And for every reason.</p>
          <p>Hit the button now.</p>
          <p>– Jake Tran</p>
        </StorytellingParagraph>
      </div>
    </div>
  );
};
