import { ComponentProps, ComponentPropsWithoutRef, ReactNode } from "react";
import { cn } from "../../../utils/utils";
import useSmoothScrollToHash from "../../../hooks/useSmoothScrollToHash";

export interface StorytellingParagraphProps
  extends Omit<ComponentPropsWithoutRef<"div">, "title"> {
  beforeTitle?: ReactNode;
  title?: ReactNode;
  titleProps?: ComponentPropsWithoutRef<"h2"> & { ribbon?: boolean };
  html?: string;
  extensions?: ReactNode;
  paragraphAfterExtensions?: ReactNode;
  paragraphClassName?: string;

  capitalizeTitle?: boolean;
}

const StorytellingParagraph = ({
  className,
  beforeTitle,
  title,
  titleProps,
  children,
  html,
  extensions,
  paragraphAfterExtensions,
  paragraphClassName,
  capitalizeTitle = false,
  ...props
}: StorytellingParagraphProps) => {
  const hasChild = html || children || null;

  const paragraphProps = html
    ? ({ dangerouslySetInnerHTML: { __html: html } } as ComponentProps<"div">)
    : { children: children };

  const paragraphAfterProps =
    typeof paragraphAfterExtensions === "string"
      ? ({
          dangerouslySetInnerHTML: { __html: paragraphAfterExtensions },
        } as ComponentProps<"div">)
      : { children: paragraphAfterExtensions };
   
      useSmoothScrollToHash()

  return (
    <div {...props} className={cn("text-black", className)}>
      {beforeTitle}
      {title ? (
        <h2
          {...titleProps}
          className={cn(
            "text-center",
            "mx-auto text-center font-black text-black md:max-w-[314px]",
            "text-26 md:max-w-full md:text-44",
            capitalizeTitle ? "capitalize" : null,
            titleProps?.ribbon &&
              "relative before:absolute before:-left-[2px] before:top-[68%] before:h-[200px] before:w-[125px] before:-translate-x-1/2 before:-translate-y-1/2 md:before:rotate-[10deg] before:scale-50 before:content-leftRibbon lg:before:-left-[20px] lg:before:top-1/2",
            titleProps?.ribbon &&
              "relative after:absolute after:-right-[2px] after:top-[68%] after:h-[200px] after:w-[125px] after:-translate-y-1/2 after:translate-x-1/2 md:after:rotate-[-10deg] after:scale-50 after:content-rightRibbon lg:after:-right-[20px] lg:after:top-1/2",
            hasChild ? "mb-7.5" : "",
            titleProps?.className,
          )}
        >
          {title}
        </h2>
      ) : null}

      <div
        {...paragraphProps}
        className={cn(
          "mx-auto flex flex-col gap-[15px] text-18 md:max-w-[528px] md:gap-5 md:text-26",
          paragraphClassName,
        )}
      />

      {extensions}

      <div
        {...paragraphAfterProps}
        className={cn(
          "mx-auto flex flex-col gap-[15px] text-18 md:max-w-[528px] md:gap-5 md:text-26",
          paragraphClassName,
        )}
      ></div>
    </div>
  );
};

export default StorytellingParagraph;
