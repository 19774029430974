const CloseWarning = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-[10px] bg-black py-[15px] text-center text-14 font-medium uppercase text-white">
      <div className="max-w-[345px] uppercase md:max-w-[600px]">
        IMPORTANT: Your order has not been completed yet
      </div>
      <div className="max-w-[345px] uppercase md:max-w-[600px]">
        Skipping this step will result in your account being unconfirmed
      </div>
    </div>
  );
};

export default CloseWarning;
