import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { FastStartProductType } from "../../pages/membership";

type FastStartProductProps = {
  product: FastStartProductType;
  borderBottom: string;
  badgeColor: string;
  buttonClassName?: string;
};

type ProductBadgeProps = {
  product: FastStartProductType;
  badgeColor: string;
};

const Badge = ({ product, badgeColor }: ProductBadgeProps) => {
  return (
    <Flex
      flex={{
        base: "0 0 119px",
        lg: "0 0 131px",
      }}
      mr={{
        base: "-15px",
        lg: "-30px",
      }}
      justifyContent="flex-end"
      alignItems="center"
      height="35px"
      pr={"14px"}
      minWidth={product.badge === "Not Purchased" ? "166px" : "131px"}
      fontWeight="700"
      background={badgeColor}
      color="#fff"
      clipPath="polygon(0 0, 100% 0, 100% 100%, 0 97%, 11% 53%);"
      className="!text-14 md:!text-18"
    >
      {product.badge}
    </Flex>
  );
};

const FastStartProduct = ({
  product,
  borderBottom,
  badgeColor,
}: FastStartProductProps) => {
  const [isMobile] = useMediaQuery("(max-width: 912px)");

  return (
    <Flex
      key={product.title}
      position="relative"
      gap="30px"
      py={{
        base: "15px",
        lg: "25px",
      }}
      px={{
        base: "15px",
        lg: "30px",
      }}
      borderBottom={borderBottom}
      flexDirection={isMobile ? "column" : "row"}
    >
      <Flex
        alignItems="center"
        flex={{
          base: "0 0 79px",
          lg: "0 0 132px",
        }}
      >
        <Image
          src={product.imageUrl}
          width={{
            base: "79px",
            lg: "132px",
          }}
          height={{
            base: "77px",
            lg: "128px",
          }}
          mr={{
            base: "auto",
            lg: "0",
          }}
          alt={product.title}
        />
        {isMobile && <Badge product={product} badgeColor={badgeColor} />}
      </Flex>

      <Flex direction="column">
        <Flex>
          <Box>
            <Heading mb="8px" fontWeight="700" className="!text-18 md:!text-22">
              {product.title}
            </Heading>
            <Text
              fontSize="20px"
              lineHeight="25.3px"
              mb="20px"
              className="!text-16 lg:!text-20"
            >
              {product.description}
            </Text>
          </Box>

          {!isMobile && <Badge product={product} badgeColor={badgeColor} />}
        </Flex>

        <Box
          minHeight={{
            base: "50px",
            md: product.secondButton ? "126px" : "50px",
            xl: "50px",
          }}
        >
          <Flex
            flexWrap="nowrap"
            gap={{ base: "16px", md: "7px" }}
            flexDirection={{ base: "column", xl: "row" }}
          >
            {product.button}
            {product.secondButton}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default FastStartProduct;
