import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useState } from "react";
import AvatarIcon from "../AvatarIcon";
import LogoutIcon from "../LogoutIcon";
import { useAuth } from "../../providers/AuthProvider";

const NavbarProfile = () => {
  const { user, logout } = useAuth();

  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isHoveredAvatar, setIsHoveredAvatar] = useState(false);

  return (
    <>
      {isMobile ? (
        <Box position="absolute" right="10px" cursor="pointer" ml="5px">
          <Menu offset={[-10, -2]}>
            {({ isOpen }) => (
              <>
                <MenuButton as="div">
                  <Flex
                    position="relative"
                    height="40px"
                    alignItems="center"
                    // p="8px 12px"
                    borderRadius="12px"
                    cursor="pointer"
                    background={isHoveredAvatar ? "#f2f4f7" : "transparent"}
                    borderWidth="0"
                  >
                    <AvatarIcon width="24" height="24" />
                    {!isMobile && (
                      <Box
                        ml="8px"
                        fontSize="16px"
                        fontWeight="450"
                        lineHeight="20.24px"
                        color="#9b9b9b"
                      >
                        Welcome,{" "}
                        <Box as="span" color="#000" fontWeight="500">
                          {user?.firstName}
                        </Box>
                      </Box>
                    )}
                  </Flex>
                </MenuButton>
                <MenuList
                  p="0"
                  minW="0"
                  w={"110px"}
                  borderWidth="0"
                  display={isOpen ? "block" : "none"}
                >
                  <MenuItem
                    height="34px"
                    alignItems="center"
                    p="8px 29px 8px 12px"
                    background="#fff"
                    border="1px solid #eaecf0"
                    borderRadius="7px"
                    onClick={logout}
                  >
                    <LogoutIcon />{" "}
                    <Text
                      ml="5px"
                      fontSize="14px"
                      lineHeight="17.31px"
                      fontWeight="500"
                    >
                      Logout
                    </Text>
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        </Box>
      ) : (
        <Flex
          position="absolute"
          right="20px"
          height="60px"
          alignItems="center"
          onMouseOver={() => setIsHoveredAvatar(true)}
          onMouseOut={() => setIsHoveredAvatar(false)}
        >
          <Flex
            position="relative"
            height="40px"
            alignItems="center"
            p="8px 12px"
            borderRadius="12px"
            cursor="pointer"
            background={isHoveredAvatar ? "#f2f4f7" : "transparent"}
          >
            <AvatarIcon />
            {!isMobile && (
              <Box
                ml="8px"
                fontSize="16px"
                fontWeight="450"
                lineHeight="20.24px"
                color="#9b9b9b"
              >
                Welcome,{" "}
                <Box as="span" color="#000" fontWeight="500">
                  {user?.firstName}
                </Box>
              </Box>
            )}

            {isHoveredAvatar && (
              <Flex
                position="absolute"
                top="calc(100% + 6px)"
                right="0"
                alignItems="center"
                p="8px 29px 8px 12px"
                background="#fff"
                border="1px solid #eaecf0"
                borderRadius="7px"
                onClick={logout}
              >
                <LogoutIcon />{" "}
                <Text
                  ml="5px"
                  fontSize="14px"
                  lineHeight="17.31px"
                  fontWeight="500"
                >
                  Logout
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default NavbarProfile;
