import { GreenDots } from "../../../assets/icons";

export const OpenLetter = () => {
  return (
    <>
      <div className="mx-auto mt-12.5 flex max-w-[528px] flex-col gap-[15px] px-6 text-18 font-normal md:mt-20 md:gap-5 md:p-[unset] md:text-26">
        <p>Hey, Jake Tran here...</p>
        <p>
          On behalf of myself and my team, I’d like to congratulate you on
          making one of the{" "}
          <span className="border-b-[2px] border-b-black text-18 font-bold md:text-26">
            MOST IMPORTANT
          </span>{" "}
          decisions of your entire money-making journey...
        </p>

        <p>
          <i>and even though you may not realize it yet...</i>
        </p>
        <p>
          just by signing up, you’ve just set off a series of events in motion
          that will change everything for you.
        </p>
      </div>
      <div className="mx-auto mt-[15px] flex max-w-[528px] flex-col gap-[20px] px-6 text-18 font-normal md:mt-5 md:gap-[25px] md:p-[unset] md:text-26">
        <p>Just look at what happened right now:</p>
        <div className="flex gap-[11px] md:gap-[15px]">
          <div className="text-[26px]">
            <GreenDots />
          </div>
          <p>
            You were worrying about whether you'd be able to provide for your
            family, and now you have a proven system that’s going to allow you
            to do that.
          </p>
        </div>
        <div className="flex gap-[11px] md:gap-[15px]">
          <div className="text-[26px]">
            <GreenDots />
          </div>
          <p>
            You went from being dependent on your boss and your 9-5 to finally
            stepping up and getting 1 step closer to becoming your own boss.
          </p>
        </div>
        <p>And most importantly...</p>
      </div>
    </>
  );
};
