import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { RedButton } from "../components/ui";
import { ROUTES } from "../constants";
import { useDelayNavigation } from "../hooks/useDelayNavigation";

const TextWithPrefixRedDot = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div className="flex gap-[15px] text-16 font-normal md:text-22 md:mb-[25px] mb-[18px] items-start">
        <div className="max-h-[30px] min-h-[30px] min-w-[30px] max-w-[30px] rounded-full border-[5px] border-[#F9A7A0] bg-[#D92D20]"></div>
        <Text
          className="md:text-22 text-16 mt-[3px]"
          fontWeight="450"
          style={{
            maxWidth: "493px",
          }}
        >
          {children}
        </Text>
      </div>
    </>
  );
};

const DiscoveryBookedPage = () => {
  const navigate = useDelayNavigation();
  const [isChecked, setChecked] = React.useState(false);

  const handleNavigateToMemberShipPage = () => {
    navigate(ROUTES.MEMBERSHIP);
  };

  return (
    <>
      <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
        <Box
          width={{
            base: "90%",
            md: "max-content",
          }}
          height={{
            base: "80%",
            md: "max-content"
          }}
          margin="auto"
        >
          <Text
            align={"center"}
            fontWeight="700"
            lineHeight={{
              base: "auto",
              xs: "20px"
            }}
            style={{
              maxWidth: "690px",
            }}
            className="md:mb-[15px] mb-[17px] md:text-36 text-20"
          >
            Congratulations!
          </Text>
          <Text
            className="md:mb-[40px] mb-[30px] md:text-48 text-32"
            align={"center"}
            fontWeight="900"
            lineHeight={{
              base: "112%",
              md: "auto"
            }}
            style={{
              maxWidth: "690px",
            }}
          >
            Your call is scheduled!
          </Text>
          <Box className="flex-1">
            <TextWithPrefixRedDot>
              Please don’t be driving in our call{" "}
              <strong>(SAFETY FIRST)</strong>
            </TextWithPrefixRedDot>
            <TextWithPrefixRedDot>
              Join in a computer/laptop If possible
            </TextWithPrefixRedDot>

            <TextWithPrefixRedDot>
              Try not to be walking around, please be seated!
            </TextWithPrefixRedDot>
            <TextWithPrefixRedDot>
              Be in a quiet environment
            </TextWithPrefixRedDot>
            <TextWithPrefixRedDot>
              Be on time - or let us know if you’re running late
            </TextWithPrefixRedDot>
            <TextWithPrefixRedDot>
              If you don’t show up, we won’t be able to reschedule since there’s
              a waitlist with dozens of people waiting
            </TextWithPrefixRedDot>
          </Box>

          <Box
            onClick={() => setChecked(!isChecked)}
            className="flex items-center gap-[15px] md:mt-[48px] mt-[35px]"
          >
            <div className="w-[30px] inline-flex items-center justify-center">
              <label className="relative flex cursor-pointer items-center">
                <input
                  checked={isChecked}

                  type="checkbox"
                  className="peer h-5 w-5 cursor-pointer appearance-none rounded border border-slate-300 shadow transition-all checked:border-slate-800 checked:bg-slate-800 hover:shadow-md"
                  id="check"
                />
                <span className="pointer-events-none absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-white opacity-0 peer-checked:opacity-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3.5 w-3.5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </label>
            </div>
            <Text
              fontWeight="450"
              className="md:text-20 text-14"
              style={{
                maxWidth: "690px",
              }}
            >
              I understand and agree to join on time
            </Text>
          </Box>

          <RedButton
            className="mt-[15px] flex items-center !justify-center md:mt-[25px] disabled:bg-[#E7E7E7] disabled:text-[#A8A8A8] disabled:opacity-100"
            disabled={!isChecked}
            onClick={handleNavigateToMemberShipPage}
          >
            <span className="whitespace-normal md:w-full md:whitespace-nowrap">
              Continue
            </span>
          </RedButton>
        </Box>
      </Flex>
      <Box
        className="absolute left-1/2 top-1/2 -z-10 -translate-x-1/2 -translate-y-1/2 transform"
        sx={{
          width: "100vw",
          height: "50vh",
          background:
            "linear-gradient(90deg, #4F2CA1 0%, #7C72D2 18.05%, #A86C9E 38.5%, #DB8759 54.29%, #A85C56 71.17%, #743376 87.19%, #400574 100%)",
          opacity: 0.25,
          filter: "blur(200px)",
        }}
      />
    </>
  );
};

export default DiscoveryBookedPage;
