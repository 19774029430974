import { Image } from "@chakra-ui/react";
import { ComponentPropsWithoutRef, useState } from "react";
import { cn } from "../../../utils/utils";
import ModalVideoPlayer from "../ModalVideoPlayer";
import "./style.css";

export interface ThereAreNoProgramLikeThisProps
  extends ComponentPropsWithoutRef<"div"> { }

export const ThereAreNoProgramLikeThis = ({
  className,
  ...props
}: ThereAreNoProgramLikeThisProps) => {
  const [wistiaId, setWistiaId] = useState<string | null>(null);

  const onVideoShow = (wistiaId: string) => {
    setWistiaId(wistiaId);
  }

  const onVideoHide = () => {
    setWistiaId(null);
  }

  return (
    <div {...props} className={cn("px-6", className)}>
      <div className="bg-img ml-auto mr-auto mt-[77px] flex max-w-[1250px] flex-col items-center justify-center rounded-[25px] py-[50px] md:mt-[107px] md:py-[85px]">
        <Image
          src="/images/home/five-star.png"
          className="h-[34px] w-[194px]"
        />
        <div className="mt-[35px] flex gap-3 md:mt-[37px]">
          <div className="label inline-block max-w-[302px] text-center text-32 font-black md:max-w-fit md:text-50">
            <span className="mt-3">There's no other program like this!</span>
          </div>
        </div>

        <div
          id="WATCH_A_REVIEW_VIDEO"
          className="mt-[35px] w-fit cursor-pointer rounded-[13px] bg-[#FEE4E2] px-[42px] py-4 md:mt-[37px]"
          onClick={() => onVideoShow("lbkpg6lf7x")}
        >
          <span className="text-18 font-bold text-[#D92D20]">
            Watch a review
          </span>
        </div>
      </div>

      {/* Video Player in Modal */}
      <ModalVideoPlayer wistiaId={wistiaId} onClose={onVideoHide} />
    </div>
  );
};
