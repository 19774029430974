import { ComponentPropsWithoutRef, useEffect, useState } from "react";
import { useStore } from "../../libs/store";
import { cn } from "../../utils/utils";
import Cookies from "js-cookie";
import { decodeValue, encodeValue } from "../../libs/common";

export interface TimerProps extends ComponentPropsWithoutRef<"div"> {}

const Timer = ({ className, ...props }: TimerProps) => {
  const { setSalesExpire } = useStore();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  // Function to calculate and set the remaining time
  const getTime = (startTime: number) => {
    const finalTime = new Date(startTime + 1000 * 60 * 30); // 30 minutes later
    const timeRemaining = finalTime.getTime() - Date.now();

    if (timeRemaining <= 0) {
      setMinutes(0);
      setSeconds(0);
      setSalesExpire(true);
      Cookies.set("_seefg", encodeValue("1"), {
        expires: 1, // Expire immediately after timer runs out
        secure: true,
        sameSite: "Strict",
      });
      return;
    }

    const min = Math.floor((timeRemaining / 1000 / 60) % 60);
    const sec = Math.floor((timeRemaining / 1000) % 60);
    setMinutes(min);
    setSeconds(sec);
  };

  useEffect(() => {
    // Get or initialize the startTime from Cookies instead of localStorage
    let startTime = Cookies.get("_stabc")
      ? parseInt(decodeValue(Cookies.get("_stabc")!))
      : 0;

    // Check if salesExpire cookie is already set (indicating an expired session)
    const salesExpire = Cookies.get("_seefg")
      ? decodeValue(Cookies.get("_seefg")!)
      : "";

    // If the sales has expired, stop the timer
    if (salesExpire === "1") return;

    // If no valid startTime, set it to the current time
    if (!startTime || isNaN(startTime)) {
      startTime = Date.now();
      Cookies.set("_stabc", encodeValue(startTime.toString()), {
        expires: 1 / 48, // 30 minutes
        secure: true,
        sameSite: "Strict",
      });
      Cookies.set("_seefg", encodeValue("0"), {
        expires: 1 / 48, // 30 minutes
        secure: true,
        sameSite: "Strict",
      });
      console.log("Setting new startTime and salesExpire cookie.");
    } else {
      console.log("Using existing startTime:", startTime);
    }

    // Initialize the timer immediately
    getTime(startTime);

    const interval = setInterval(() => getTime(startTime), 1000); // Update every second

    // Cleanup interval when component unmounts
    return () => clearInterval(interval);
  }, [setSalesExpire]);

  return (
    <>
      {minutes + seconds > 0 && (
        <div
          {...props}
          className={cn(
            "fixed top-0 z-50 flex h-12 w-screen items-center justify-center bg-black py-[27px] text-white md:py-[29px]",
            className,
          )}
        >
          <div className="flex justify-center">
            <button className="flex h-[30px] items-center justify-center rounded-[17px] bg-[#D92D20] px-3">
              <p className="text-14 font-medium">Your results will expire in</p>
              <div className="my-0.5 ml-3 rounded-[19px] bg-black px-3">
                <div className="-mt-0.5 flex h-[22px] items-center justify-center text-14 font-bold">
                  <div className="timer" role="timer">
                    <div className="col-4">
                      <div className="box">
                        <span id="minute">
                          {minutes < 10 ? "0" + minutes : minutes}:
                        </span>
                        <span id="second">
                          {seconds < 10 ? "0" + seconds : seconds}
                        </span>
                        <span className="text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Timer;
