import StorytellingParagraph from "../../ui/StoryTelling/StoryTellingParagraph";

export const Guarantee = () => {
  return (
    <>
      <div className="mx-auto mt-15 max-w-[800px] px-6 pb-[100px] md:mt-20 md:px-0">
        <StorytellingParagraph
          titleProps={{
            ribbon: true,
            className: "w-fit flex justify-center items-center text-center",
          }}
          title={
            <p className="max-w-[309px] text-26 capitalize md:max-w-[500px] md:text-40 lg:max-w-[814px] lg:text-44">
              Backed By Our 14-Day No Questions Asked Money-Back Guarantee
            </p>
          }
        >
          <p>
            You’re completely covered with a 100% no-BS money-back guarantee.{" "}
          </p>
          <p>
            Try out the 7-Figure Launchpad, and if you feel like these secrets
            have no use for you, or if you're not happy with anything for any
            reason at all, or if you'd rather try to do the work all by
            yourself… whatever - just shoot me an email in the first 14 days of
            getting your '7-Figure Launchpad', and we'll give you every penny
            back.
          </p>
          <p>
            Also, we’ll let you keep all the views & money the '7-Figure
            Launchpad' helps you get.
          </p>
          <p>
            We won't ask you why and we won't ask you a single question. You'll
            just get every penny back.
          </p>
        </StorytellingParagraph>
      </div>
    </>
  );
};
