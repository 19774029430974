import { FirstYoutubePaycheckBtn } from "../FirstYoutubePaycheckBtn";
import { ReactNode } from "react";
import StorytellingParagraph from "../../ui/StoryTelling/StoryTellingParagraph";
import { cn } from "../../../utils/utils";

import viralFormulaFit from "../../../assets/images/seven-figure-launchpad/viral-formula-fit.png";
import viralFormulaCrop from "../../../assets/images/seven-figure-launchpad/viral-formula-crop.png";
import viralTriggersTemplateFit from "../../../assets/images/seven-figure-launchpad/viral-triggers-template-fit.png";
import viralTriggersTemplateCrop from "../../../assets/images/seven-figure-launchpad/viral-triggers-template-crop.png";
import viralHooksFit from "../../../assets/images/seven-figure-launchpad/viral-hooks-fit.png";
import viralHooksCrop from "../../../assets/images/seven-figure-launchpad/viral-hooks-crop.png";
import viralAll from "../../../assets/images/seven-figure-launchpad/viral-all-crop.png";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { ROUTES } from "../../../constants";

const ImageWithTextLayout = ({
  image,
  text,
  imageClass,
}: {
  image: ReactNode;
  text: ReactNode;
  imageClass?: string;
}) => {
  return (
    <div className="flex flex-col gap-5 px-6 md:flex-row md:gap-[11px] md:px-0">
      <div className={cn("flex w-full items-start justify-center", imageClass)}>
        {image}
      </div>
      <div className="flex-1">{text}</div>
    </div>
  );
};

const TextWithPrefixRedDot = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <div className="mt-[8px] flex gap-2 text-18 font-normal md:mt-[15px] md:gap-3 md:text-26">
        <div className="mt-[3px] h-5 w-5 rounded-full border-[4px] border-[#F9A7A0] bg-[#D92D20] md:mt-1 md:h-[22px] md:w-[22px]"></div>
        <div className="flex-1 text-16 md:text-22">{children}</div>
      </div>
    </>
  );
};

type SevenFigureLaunchpadProps = {
  subHeading?: string;
  startingText: ReactNode;
};

export const SevenFigureLaunchpad = ({
  subHeading,
  startingText,
}: SevenFigureLaunchpadProps) => {
  const isMobile = useIsMobile();
  const { pathname } = useLocation();

  const isDownSellPage = pathname.includes("doto1");

  return (
    <>
      <div className="mx-auto mt-12.5 max-w-[678px] px-6 md:mt-15 md:px-0">
        <StorytellingParagraph
          id="seven-figure-launchpad"
          titleProps={{ className: "mb-[15px] " }}
          title={<span className="capitalize">7-Figure Launchpad</span>}
        >
          {subHeading && <p className="text-center">{subHeading}</p>}
          <img
            src={viralAll}
            alt="the-virality-formula.png"
            className={`mx-auto mt-[24px] ${isDownSellPage ? "h-[213px] w-[341px] md:h-[326.25px] md:w-[585px]" : "h-[183px] w-[293px] md:h-[290px] md:w-[520px]"}`}
          />
          <p className="mt-[15px] text-center font-bold">
            ONLY A ONE TIME INVESTMENT
          </p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-[15px] max-w-[751px] px-6 md:mt-[20px] md:px-0">
        <p className="text-center text-18 md:text-26">
          Starting as low as {startingText}
        </p>
        <HashLink
          smooth
          to={`${location.pathname === ROUTES.DOWNSELL ? ROUTES.DOWNSELL : ROUTES.UPSELL1}#virality-formula`}
        >
          <FirstYoutubePaycheckBtn className="mt-[20px] flex items-center justify-center md:mt-[30px]" />
        </HashLink>
        <p className="mt-[25px] text-center text-18 font-normal capitalize md:text-16">
          Backed By Our 14-Day No Questions Asked Money Back Guarantee
        </p>
        <p className="mt-[15px] text-center text-18 font-normal md:text-16">
          If You’re Not Happy For Any Reason, Give It a Try On a Single Video
          And If You Still Feel Like It Has No Use For You - We Will Refund
          Every Penny Right Back To You
        </p>
      </div>

      <div className="mx-auto mt-15 flex max-w-[776px] flex-col gap-15 md:mt-20 md:gap-20">
        <ImageWithTextLayout
          imageClass="h-[170px] w-[341px] md:h-[288px] md:w-[318px]"
          image={
            <img
              src={isMobile ? viralFormulaCrop : viralFormulaFit}
              alt="single-virality-formula"
              className="h-[170px] w-[341px] md:h-[288px] md:w-[318px]"
            />
          }
          text={
            <>
              <div
                id="virality-formula"
                className="mb-3 text-20 font-semibold uppercase md:mb-5 md:text-28"
              >
                The Virality Formula
              </div>
              <TextWithPrefixRedDot>
                The exact predictable formula that every single viral video on
                YouTube follows.
              </TextWithPrefixRedDot>
              <TextWithPrefixRedDot>
                It will allow you to get your video viral as soon as humanly
                possible the very first time you try it thanks to the viral
                triggers you'll discover inside.
              </TextWithPrefixRedDot>

              <TextWithPrefixRedDot>
                All you have to do is fill in the blanks (and I'll help you to
                do that) and you're good to go - as long as you hit these viral
                triggers, you're on your way of getting viral with absolute
                certainty.
              </TextWithPrefixRedDot>
            </>
          }
        />
        <ImageWithTextLayout
          imageClass="h-[170px] w-[341px] md:h-[288px] md:w-[318px]"
          image={
            <img
              src={
                isMobile ? viralTriggersTemplateCrop : viralTriggersTemplateFit
              }
              alt="the-viral-script-templates"
              className="h-[170px] w-[341px] md:h-[288px] md:w-[318px]"
            />
          }
          text={
            <>
              <div className="mb-3 text-20 font-semibold uppercase md:mb-5 md:text-28">
                The Viral Triggers Template
              </div>
              <TextWithPrefixRedDot>
                The ultimate blueprint on creating low-cost but high-return
                videos.
              </TextWithPrefixRedDot>
              <TextWithPrefixRedDot>
                This removes all the friction from creating your videos and
                dealing with scripts or catchy edits.
              </TextWithPrefixRedDot>
              <TextWithPrefixRedDot>
                You'll be able to create viral videos on the spot, faster than
                anyone else, along with being able to create videos for less
                pennies on the dollar.
              </TextWithPrefixRedDot>
              <TextWithPrefixRedDot>
                You’re gonna get fill-in-the-blank script templates based on
                proven videos that have already gone viral.
              </TextWithPrefixRedDot>
            </>
          }
        />

        <ImageWithTextLayout
          imageClass="h-[170px] w-[341px] md:h-[288px] md:w-[318px]"
          image={
            <img
              src={isMobile ? viralHooksCrop : viralHooksFit}
              alt="the-viral-hooks"
              className="h-[170px] w-[341px] md:h-[288px] md:w-[318px]"
            />
          }
          text={
            <>
              <div className="mb-3 text-20 font-semibold uppercase md:mb-5 md:text-28">
                The Viral Hooks
              </div>
              <TextWithPrefixRedDot>
                This is my ultimate package for attention robbery.
              </TextWithPrefixRedDot>
              <TextWithPrefixRedDot>
                Step-by-step guide on how to steal people's attention and make
                them watch the entire video which force the YouTube algorithm to
                push your video out to more viewers.
              </TextWithPrefixRedDot>
              <TextWithPrefixRedDot>
                You're getting the entire formula along with battle-tested hook
                templates that have been proven to suck people in and get them
                to watch your entire video.
              </TextWithPrefixRedDot>
              <TextWithPrefixRedDot>
                So all you have to do is copy & paste my battle-tested
                templates.
              </TextWithPrefixRedDot>
            </>
          }
        />
      </div>
    </>
  );
};
