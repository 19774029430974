import {
  CloseWarning,
  IntroSection,
  OpenLetter,
  TestimonialSection,
  FirstStoryTellingSection,
  WhyTheSecondOptionMoreExpensive,
  SecondStoryTellingSection,
  SevenFigureLaunchpad,
  Offers,
} from "../components/UpSell";
import { Guarantee } from "../components/UpSell/Guarantee";
import Footer from "../components/shared/Footer";

const UpSellPage = () => {
  return (
    <>
      <CloseWarning />
      <IntroSection />
      <OpenLetter />
      <FirstStoryTellingSection />
      <TestimonialSection />
      <SecondStoryTellingSection />
      <SevenFigureLaunchpad
        subHeading="Make your videos viral and start profiting in just 3-4 weeks"
        startingText={
          <span className="font-semibold text-[#D92D20]">$197</span>
        }
      />
      <Offers />
      <WhyTheSecondOptionMoreExpensive />
      <Guarantee />
      <Footer />
    </>
  );
};

export default UpSellPage;
