import { ComponentPropsWithoutRef } from "react";
import { cn } from "../../utils/utils";

export interface StoryTellingProps
  extends ComponentPropsWithoutRef<"section"> {}

const StoryTelling = ({ className, ...props }: StoryTellingProps) => {
  return (
    <section
      {...props}
      className={cn(
        "flex flex-col gap-12.5 bg-white px-6 md:gap-15 lg:px-0",
        className,
      )}
    />
  );
};

export default StoryTelling;
