import { SelectComponent } from "./Select";
import data from "./CountryCodeData.json";
import { useMemo, useState } from "react";
import { Input } from "../Input";
import { useCheckout } from "../../../providers/CheckoutProvider";
import { Controller } from "react-hook-form";
import InputErrorMessage from "../../InputErrorMessage";
import { useCurrentCountryCode } from "../../../hooks";
import { DropdownIcon } from "../../../assets/icons";

export const CountryRegionSelect = ({ disabled }: { disabled?: boolean }) => {
  const countryCode = useCurrentCountryCode();

  const countryName = useMemo(() => {
    return data.find((country) => country.countryShortCode === countryCode)
      ?.countryName;
  }, [countryCode]);

  const [selected, setSelected] = useState<
    { label: string; value: string } | undefined
  >(
    countryName && countryCode
      ? { label: countryName, value: countryCode }
      : { label: "United States", value: "US" },
  );

  const { control, errors } = useCheckout();

  return (
    <>
      <Controller
        name="country"
        disabled={disabled}
        control={control}
        rules={{ required: "Country is required" }}
        render={({ field }) => (
          <SelectComponent
            isDisabled={disabled}
            onChange={(e) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              field.onChange((e as any).value);
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              setSelected(e as any);
            }}
            options={data.map(
              (item: { countryName: string; countryShortCode: string }) => ({
                value: item.countryShortCode,
                label: item.countryName,
              }),
            )}
            styles={{
              control: (base) => ({
                ...base,
                height: 42,
                minHeight: 42,
                border: "1px solid #E7E7E7",
                cursor: "pointer",
                borderRadius: "8px",
                borderEndStartRadius: selected?.value === "US" ? "0px" : "8px",
                borderEndEndRadius: selected?.value === "US" ? "0px" : "8px",
                ":hover": {
                  border: "1px solid #E7E7E7",
                },
                paddingLeft: 6,
              }),
            }}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => (
                <div className="mr-3 text-18 md:text-24">
                  <DropdownIcon />
                </div>
              ),
            }}
            placeholder={selected?.label || "United States"}
          />
        )}
      />

      {selected?.value === "US" && (
        <>
          <Controller
            name="zip"
            disabled={disabled}
            control={control}
            rules={{
              minLength: {
                value: 5,
                message: "ZIP code must be at least 5 characters",
              },
              maxLength: {
                value: 9,
                message: "ZIP code must be at most 9 characters",
              },
              required: "ZIP code is required",
            }}
            render={({ field }) => (
              <Input
                {...field}
                maxLength={9}
                type="number"
                placeholder="ZIP code"
                className="w-full rounded-t-none border-t-0"
              />
            )}
          />

          {errors.zip?.type && (
            <InputErrorMessage message={errors.zip.message as string} />
          )}
        </>
      )}
    </>
  );
};
