import { useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { FullScreenModal } from "../../ui";
import { useCheckout } from "../../../providers/CheckoutProvider";
import { useEffect } from "react";
import CloseIcon from "../../CloseIcon";
import SpinningModal from "../Loading";

export const PaymentDeclineModal = ({
  showMobileOnly,
  openCallback,
  closeCallback,
}: {
  showMobileOnly?: boolean;
  openCallback?: () => void;
  closeCallback?: () => void;
}) => {
  const [isSmallerMd] = useMediaQuery("(max-width: 768px)");
  const { paymentError, setPaymentError, isProcessing } = useCheckout();
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: false,
  });

  useEffect(() => {
    if (paymentError) {
      onOpen();
      openCallback?.();
    }
  }, [onOpen, paymentError, openCallback]);

  return (
    <>
      <SpinningModal open={isProcessing} minClosingTime={800} />
      <FullScreenModal
        className="bg-[#0000008A]"
        open={showMobileOnly ? isSmallerMd && isOpen : isOpen}
      >
        <div className="p-3">
          <div className="m-auto flex max-w-[720px] flex-col gap-5 rounded-lg bg-white p-5 text-center">
            {!isSmallerMd && (
              <div
                className="flex cursor-pointer justify-end"
                onClick={() => {
                  setPaymentError("");
                  onClose();
                  closeCallback?.();
                }}
              >
                <CloseIcon />
              </div>
            )}
            <div className="text-center text-26 font-black md:text-40">
              Transaction not completed
            </div>
            <div className="text-pretty px-[20px]">
              <div className="text-16 font-bold-450 md:text-20">
                Payment declined:{" "}
                <span className="underline">
                  {paymentError || "Something went wrong"}
                </span>
                . Please try again or contact our{" "}
                <span
                  className="cursor-pointer text-[#D92D20]"
                  id="support-here"
                >
                  support here
                </span>
              </div>
            </div>
            <button
              className="rounded-lg bg-[#D92D20] py-[18px] text-16 font-bold text-white md:text-24"
              onClick={() => {
                setPaymentError("");
                onClose();
                closeCallback?.();
              }}
            >
              Try again
            </button>
          </div>
        </div>
      </FullScreenModal>
    </>
  );
};
