import { useEffect, useRef, useState } from "react";

export const useIntersectionElement = () => {
  const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const intersectionObserver = new IntersectionObserver((entries) => {
      setEntry(entries[0]);
    });

    intersectionObserver.observe(element as unknown as HTMLElement);

    return () => {
      intersectionObserver.unobserve(element as unknown as HTMLElement);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return [ref, entry] as const;
};
