// src/analytics.ts

export const initGA = (trackingID: string) => {
    if (typeof window !== 'undefined') {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingID}`;
      script.async = true;
      document.head.appendChild(script);
  
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag(...args: any[]) { window.dataLayer.push(args); }
        gtag('js', new Date());
        gtag('config', trackingID);
      };
  
      script.onerror = () => {
        console.error('Failed to load Google Analytics script.');
      };
    }
  };
  