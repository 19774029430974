import { DoubleArrowRight } from "../../../assets/icons";
import { RedButton } from "../../ui";

export const FirstYoutubePaycheckBtn = ({
  className,
  disabled,
  onClick = () => {},
}: {
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}) => {
  return (
    <RedButton className={className} onClick={onClick} disabled={disabled}>
      <span className="w-[251px] whitespace-normal text-left md:w-full md:whitespace-nowrap">
        I want my first YouTube paycheck as soon as possible!
      </span>
      <span>
        <DoubleArrowRight />
      </span>
    </RedButton>
  );
};
