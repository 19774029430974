import { cn } from "../../utils/utils";

type NextIconProps = {
  variant?: "black" | "white";
  width?: string;
  height?: string;
  className?: string
};

const NextIcon = ({
  variant = "white",
  width = "24",
  height = "24",
  className
}: NextIconProps) => {
  if (variant === "white") {
    return (
      <svg
        className={cn("2xl:w-[35px] 2xl:h-[35px] w-[24px] h-[24px]", className)}
        viewBox={`0 0 ${width} ${height}`}
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.5265 5.12344C5.2265 5.28281 5.04837 5.55937 5.02494 5.90156C4.98744 6.42656 4.87025 6.29062 7.84212 9.26719L10.5702 12L7.84212 14.7328C4.87025 17.7094 4.98744 17.5734 5.02494 18.0984C5.06244 18.6141 5.46087 18.9844 5.99056 18.9844C6.1265 18.9844 6.31869 18.9516 6.41244 18.9047C6.60931 18.8203 12.5718 12.9047 12.8109 12.5625C12.9796 12.3187 13.0312 11.9391 12.9374 11.6437C12.8906 11.4937 12.2718 10.8516 9.75462 8.32969C7.664 6.23437 6.57181 5.17031 6.44056 5.10937C6.17337 4.97812 5.79369 4.98281 5.5265 5.12344Z"
          fill="white"
        />
        <path
          d="M11.6484 5.15156C11.1609 5.37656 10.9312 5.9625 11.1327 6.45938C11.1843 6.58125 12.0327 7.45781 13.889 9.31406L16.5702 12L13.8468 14.7281C10.8702 17.7094 10.9874 17.5734 11.0249 18.0984C11.0624 18.6141 11.4609 18.9844 11.9905 18.9844C12.1265 18.9844 12.3187 18.9516 12.4124 18.9047C12.5249 18.8578 13.7109 17.7094 15.6702 15.7547C18.0327 13.3969 18.778 12.6234 18.8671 12.45C19.003 12.1781 19.0171 11.8922 18.914 11.6344C18.8109 11.3953 12.7124 5.27813 12.4452 5.15156C12.2062 5.03906 11.8874 5.03906 11.6484 5.15156Z"
          fill="white"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.296 7.71004L14.621 12L10.296 16.29L11.704 17.71L17.461 12L11.704 6.29004L10.296 7.71004Z"
        fill="#293845"
      />
      <path
        d="M6.70399 6.29004L5.29599 7.71004L9.62099 12L5.29599 16.29L6.70399 17.71L12.461 12L6.70399 6.29004Z"
        fill="#293845"
      />
    </svg>
  );
};

export default NextIcon;
