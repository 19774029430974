export const GreenDots = ({ className }: { className?: string }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="13" cy="13" r="13" fill="#D1FADF" />
      <circle cx="13" cy="13" r="7.8" fill="#027A48" />
    </svg>
  );
};
