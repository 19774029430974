import NotRightFit from "../components/NotRightFit";
import Footer from "../components/shared/Footer";

const NotFitPage = () => {
  return (
    <>
      <NotRightFit />
      <Footer />
    </>
  );
};

export default NotFitPage;
