export function getDayName(daysFromToday: number) {
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const today = new Date();
  const targetDate = new Date(today);

  targetDate.setDate(today.getDate() + daysFromToday);

  const dayIndex = targetDate.getDay();

  if (daysFromToday === 1) {
    return "Tomorrow";
  } else {
    return dayNames[dayIndex];
  }
}
