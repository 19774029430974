import {
  ComponentPropsWithoutRef,
  // useEffect, useState
} from "react";
// import { useInView } from "react-intersection-observer";
import { cn } from "../../utils/utils";

export interface CountUpAnimationProps extends ComponentPropsWithoutRef<"div"> {
  symbol?: string;
  number?: string;
  duration?: number;
}

export const CountUpAnimation = ({
  symbol = "",
  number = "",
  // duration = 2000,
  className,
  ...props
}: CountUpAnimationProps) => {
  // const countTo = parseInt(number, 10);
  // const [count, setCount] = useState(0);
  // const easeOutQuad = (t: number) => t * (2 - t);
  // const frameDuration = 1000 / 60;
  // const { ref: myRef, inView: myElementIsVisible } = useInView();

  // useEffect(() => {
  //   let frame = 0;
  //   const totalFrames = Math.round(duration / frameDuration);
  //   const counter = setInterval(() => {
  //     frame++;
  //     const progress = easeOutQuad(frame / totalFrames);
  //     setCount(countTo * progress);

  //     if (frame === totalFrames) {
  //       clearInterval(counter);
  //     }
  //   }, frameDuration);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [myElementIsVisible]);

  // const countLocaleString = Math.floor(count).toLocaleString();
  // const countSplitting = countLocaleString.split("");

  return (
    <div
      {...props}
      className={cn("relative", className)}
      // ref={myRef}
    >
      <span className="inline-flex gap-1 align-middle text-24 font-bold tracking-[0.35rem] text-white md:gap-[3px]">
        {symbol ? symbol : null}
        {new Intl.NumberFormat("en-US").format(parseInt(number, 10))}
        {/* {(countSplitting.length <= 1 ? Array(10).fill(0) : countSplitting).map(
          (v) => (
            <span className="flex w-4 items-center justify-center">{v}</span>
          ),
        )} */}
      </span>
    </div>
  );
};
