const ThreeDotsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 8.33301C9.08334 8.33301 8.33334 9.08301 8.33334 9.99967C8.33334 10.9163 9.08334 11.6663 10 11.6663C10.9167 11.6663 11.6667 10.9163 11.6667 9.99967C11.6667 9.08301 10.9167 8.33301 10 8.33301ZM15 8.33301C14.0833 8.33301 13.3333 9.08301 13.3333 9.99967C13.3333 10.9163 14.0833 11.6663 15 11.6663C15.9167 11.6663 16.6667 10.9163 16.6667 9.99967C16.6667 9.08301 15.9167 8.33301 15 8.33301ZM5 8.33301C4.08334 8.33301 3.33334 9.08301 3.33334 9.99967C3.33334 10.9163 4.08334 11.6663 5 11.6663C5.91667 11.6663 6.66667 10.9163 6.66667 9.99967C6.66667 9.08301 5.91667 8.33301 5 8.33301Z"
        fill="#484848"
      />
    </svg>
  );
};

export default ThreeDotsIcon;
