import { ComponentPropsWithoutRef } from "react";

export interface BxShieldProps extends ComponentPropsWithoutRef<"svg"> {}

const BxShield = (props: BxShieldProps) => {
  return (
    <svg
      width="24"
      height="24"
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.438 5.08898C21.137 4.92998 20.774 4.93698 20.479 5.10398C19.795 5.49298 19.124 5.68098 18.426 5.68098C16.391 5.68098 14.474 4.05198 12.704 2.29098C12.329 1.91798 11.641 1.91798 11.266 2.29098C9.59198 3.95898 7.59798 5.66298 5.50998 5.66298C4.81998 5.66298 4.15898 5.47898 3.49198 5.10198C3.19398 4.93598 2.83398 4.93098 2.53198 5.08998C2.22998 5.24898 2.03098 5.55098 2.00398 5.89098C1.99298 6.01998 1.05998 18.763 11.687 21.932C11.78 21.96 11.876 21.974 11.973 21.974H12C12.097 21.974 12.192 21.96 12.285 21.933C22.942 18.763 21.98 6.01698 21.969 5.88898C21.94 5.54898 21.74 5.24798 21.438 5.08898ZM11.986 19.931C5.00698 17.676 4.05198 10.519 3.97198 7.45398C4.47698 7.59398 4.99098 7.66298 5.50898 7.66298C8.00098 7.66298 10.159 6.09598 11.985 4.37998C13.878 6.16798 15.968 7.68098 18.427 7.68098C18.957 7.68098 19.484 7.60698 20.002 7.46098C19.928 10.526 18.981 17.678 11.986 19.931Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BxShield;
