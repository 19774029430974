import { SectionProps } from "../types/MembershipCourses.type";

export const endlessIdeaCourseContent: SectionProps[] = [
  {
    section: "Your winning video idea",
    content: [
      {
        title: "START HERE",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/0ca7ff04-8c3b-4914-8f97-5fd2b526e51f",
      },
      {
        title: "The wrong way (AVOID this mistake)",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/d1a373b8-1ec4-4d0c-8087-afcf0c977e49",
      },
      {
        title: "The Endless Video Ideas Hack Part 1",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/48628fab-1618-4978-bc88-13e1d98f00df",
      },
      {
        title: "Why you don’t need to be an expert",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/f2b79d1c-b70b-4fa3-a489-64b5e7d98d7d",
      },
      {
        title: "This is your ethical duty",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/c660c6b4-dbaf-47dc-84a8-360e78e1b356",
      },
      {
        title: "Repackage. Don’t copy.",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/7dae784c-d1dc-47b0-b27c-d8ba4ab27623",
      },
      {
        title: "The Endless Video Ideas Hack Part 2",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/dd52af12-843b-4e40-944d-e431d81c1678",
        downloadSrc: [
          "https://docs.google.com/document/d/1AUI_ggBGEcmjW2mmO-P7mFWyVnnPiO6AOpn7pDQ0DKY/copy",
        ],
      },
      {
        title: "How to improve your video idea",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/396333fb-6f70-4a61-8ddd-3f959b0484ed",
      },
      {
        title: "Social Hacking: The EASIEST Way To Go Viral",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/8b4b1d51-7397-4c95-955b-44ce756fd900",
      },
    ],
  },

  {
    section: "Decoding the perfect title & thumbnail",
    content: [
      {
        title: "How I 3x my views with ONE change",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/5ccbaa1b-6a26-4f21-93d5-e4c7b5922155",
      },
      {
        title: "What about clickbait?",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/5ce6cd3e-87c0-44e9-862b-3e9f6a195fca",
      },
      {
        title: "This is what makes people click",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/d7cfb745-2955-4aa8-934b-984d9c06a824",
      },
      {
        title: "Thumbnail mastery training session",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/be1f4f86-5b3c-4d81-990a-8bc3a2954cfe",
      },
      {
        title: "How to find your perfect thumbnail idea in 15 mins",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/57d37b3d-7003-44a2-9703-56409ce249cb",
        downloadSrc: [
          "https://docs.google.com/document/d/1J6n0CGD5eJ3hcIcFcJabO2E67J2i_jYqxRIDj25VrOw/copy",
        ],
      },
      {
        title: "Yes, ACTUALLY JUST STEAL A THUMBNAIL!",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/1ad6cc55-e815-4188-b04c-2a45f11b7402",
        htmlSrc: `Remember, as Pablo Picasso said, “good artists borrow, great artists steal.” 

Please do not try to re-invent the wheel. You will fail.

Choose a proven viral thumbnail, and pay for a thumbnail designer to steal that design. 

As long as it's not a direct competitor, it's okay.

Every big channel does this.`,
      },
    ],
  },

  {
    section: "Virality Formulas",
    content: [
      {
        title: "How to use the Virality Formulas",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/6b8a94e3-050c-4573-ab8b-bd6b31a2e504",
      },
      {
        title: "The hot take",
        htmlSrc: `1. _ will not be [something people commonly believe]

      1. Thumb: It will be _
      2.
      <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/5e719e28758e494b98187f3501dc4a70b03c04cd88fe40e2a18e2fa8b3fbeed4" "/>

2. _ is [opposite of what people commonly believe]
1.
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/8bafceca75ec4829a5584ab86dbba2d922d21c545e6c4f3fad37901ed18a38cb" "/>
2.
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/ab2dda85dc534c4e8e87c292897768a5c81534b8092c43438470e1c2c5ebb220" "/>

 3. _ would/will not be [something people commonly believe]
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/ca35b191712a4f7caa46ab79cc3a2bc28b8f54e271384d9c90948d96c15701c4" "/>

4._ deserves their/it’s _
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/cc3b4638e79b45ec99dbd16571eab5539a4a210f350a4bd9a391d843be543060" "/>

5. Thumb text: Are [subject] [opposite of what people believe]?
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/ea441bc65d294a38bb7c328287ae945629b8f5dea41148eab65302e15d967fbb" "/>

`,
      },
      {
        title: "Unknown interesting story",

        htmlSrc: `1. Remember when [interesting story people don’t know about]

      1. 
      <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/ecc9cdcf409843b5b509db9a056a4b541a32da22a3a8491a8135b49776b00374" "/>

2. Why is EVERYONE [doing this]

 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/1caf8eea0f094249ae4f92a867160d4c6ab0d16bd8d943ebad60366e005da6ca" "/>

 3. How _
    &emsp;1.
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/022b5f6a91fe434884bbde98e3f12f70e03426ae79cd4942acad8e2ceb942ecc" "/>
    &emsp;2.
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/aead66343291464cb49a29c4276ea7a579f41d1dc1794559bce29d7fd659255e" "/>
  &emsp;3.
   <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/a0750d77db024da8a9a675fda8dc9666f8c2c3c29c2740f0bb56d140c479ed97" "/>
 &emsp;4.
    <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/79e3e970d4b840f0b7917678726768483ed742312a194509bc6abf65db3e5dd9" "/>

 4. The _ You’ve Never Heard Of
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/341e8c1eb08c475ca0a28a323db3379290f583420a06435c8080e9f4aba6d940" "/>

5. Something terrible is happening _
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/64a07e19710649dfb26f53c1df2a8fe662cb25dd357f42f6b2bcc0c3174bbde8" "/>

6. _: The World’s [weirdest, other adjective] _
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/ef3c003bcbcd41f5bf00a6c0a7ae2dc63c7134e325c74521bd8fa771a277c511" "/>


7. _: The World’s [weirdest, other adjective] _
<img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/f49e8da6723f41aebe680a2105aa81c110d9056406504b72afc852db06ff7304" "/>

8. Why _
    &emsp;1.
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/c31da9c3291e4433b7eafbb7c9460d02ed23a5830dc04b76b1f9b32b1ba85bfb" "/>
    &emsp;2.
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/ca69ff8968384df19190b31bb66913e4c7f0df8134b24af0b3254f9889a23f9f" "/>
  &emsp;3.
   <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/fddda2182046444c818d062702daae1847d9a4138a5340ca9baa897271f3eefa" "/>
 &emsp;4.
    <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/092290fe27de4d6dac013ac89975d2fb7b5b76845080435c89004f1ca79b5c2e" "/>

9. _: The World’s [weirdest, other adjective] _
<img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/a4627b49ed574861ae6bd82a52e0c0d984e76af742544613a8a26ef576fc107a" "/>

`,
      },
      {
        title: "Cool sounding title",
        htmlSrc: `
       1. [COOL SOUNDING TOPIC]: [DESCRIPTION OF WHAT IT IS]
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/3f4c32f6727949e2890d99a1c36aa668bbd7127174cf477d815a9016f42daa6f" "/>


2. [COOL SOUNDING NAME]: [DESCRIPTION OF WHAT IT IS]
<img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/806976c2edf845419fb89d597c681768eb2c4e9b8de0420286b170f98872248e" "/>
`,
      },
      {
        title: "Something people are already familiar with",
        htmlSrc: `
       1. Why You’re Wrong About _
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/052e004d1cae4606bfd18fca5fdafa0edccdc7dd93eb4e30ae2dc3645eb6e4f3" "/>


2. _ Is Worse Than You Thought
<img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/afabe768694e4b2cb400f5222531656b263724f5c7d14a17804ff2804cd54671" "/>

3. If you think _ is bad, check out ...
<img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/beaccf9e684d481fb8184b64863700be807b27bf6ce24be3bf3c35ea4a14ee36" "/>
`,
      },
      {
        title: "Explaining something everyone is wondering about",
        htmlSrc: `1. Why [something everyone is wondering about but doesn’t understand]

      &emsp;1. 
      <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/f728a0379f7a4f1cb4011fb3225f54e1d51acc305e3d426e92549e388120e8ee" "/>
       &emsp;2. 
      <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/f46028a9e8ce46dba2c16b4958ad40d074270bf532de408793abd234359e87ed" "/>
       &emsp;3. 
      <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/3a38dc3f136948ff9d971c1eaf21ac6225805badbcfb475dad906579392a6de7" "/>
       &emsp;4. 
      <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/54a59dfc819e45eea8799489c6876158738d4743c1de46d490896d496c5fe1a9" "/>
       &emsp;5. 
      <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/ad477c2b563c41578273ef7dea73a5ce19006aedc9e44235ad0f0262510e8dc3" "/>
       &emsp;6. 
      <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/97f6ad0de49b4cc79a6840019add86af292904aeef8549808d2814530f29d586" "/>
       &emsp;7. 
      <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/8f734fc9e4424065a24202d0e0639e333aba7a61c7514616a4ebde7ff0b80cf1" "/>

2. For this example, they allude to Nazi money

 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/6517259fd7a14e06bcab45898898e1911e205eb97ca340d5902ef8d08ccd02b9" "/>

 3. Literally just stating what the thing is
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/7078cc4d1fb34da097d9cdd9260d3c83cf6632bf129247bd932c5f1f3faf9b52" "/>
 
 4. What Makes _
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/d150459185604dcc99166d81b937856dd8d7b3b1e031439c975af2d2070d32c6" "/>


`,
      },
      {
        title: "You’ve been victimized",
        htmlSrc: `
        1. We All Got Tricked into _
 <img class="max-h-[500px] cursor-zoom-in rounded-md" src="https://assets.skool.com/f/b1e74bf2241841d1bce28fea1170d95e/5f7951078fee427db950cc6458b25b8eb6705f0acd734cc2b94573aff0434fe1" "/>
`,
      },
      {
        title: "🎉 REQUEST IDEA REVIEW HERE",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/26d60a2e-72eb-46eb-aa5b-06dc57288d92",
        htmlSrc: `If you haven't found your channel idea yet, go back to STEP 1 and make sure to confirm your channel idea with on a hotseat first. This is the foundation before you find your winning video idea.

Click HERE for the Endless Video Ideas Worksheet to find your first viral video idea!

Click HERE for the Viral Title Thumbnail Training Exercise Worksheet!

Click HERE Finding Your Viral Title Thumbnail For Your First Video Worksheet

Here's YouTube's monetization checklist so no matter what video idea you pick, you know exactly what you can and cannot to stay monetized. Make sure your video ideas are compliant to monetize.

Watch this video

Click HERE for the PDF

Here is the spreadsheet of words that will get your video demonetized if you include them in your video's title so they won't get demonetized.

Watch this video

Click HERE for the spreadsheet

Attend the Channel & Video Idea Coaching call on Mondays (View the Skool calendar here)

Ask for live review via chat once on call. Have all resources ready.

Do not start working on your thumbnail design before you get green light on your idea.

Your classmate André Blais put together notes for Week 2: Viral Video Ideas and allowed us to share them. Click HERE for the PDF. 

YouTube’s Official Guidelines on Fair Use`,
        downloadSrc: [
          "https://files.skool.com/f/c17750772f2945adbaceffd28d4f9a2d/006b44a09c5646758b2c27b039d0907937b9fce0c8914daa868bed8dd269d460?Expires=1718550090&Signature=GVbmtB3yDFIoLtjWh377a76LsZsKRUCv2pKyvjWVj5L7gPPSeJNlKTzaJIcLPEWyz7qKwzAxL~rl4ryWnU7lngdFt49PZEztR8WudPp2TnCVFnTqFHc7D3JWo4fW~hkzv~gkdIgc5OqwfUvZP9bpshdH-SkrCtYkGmDn1FMql1tkRzWu0dY-XVKEKlyaZ-7WuCnsyK-kIsJHnn89iTrZEXW0wYADxcz3hnxt5-UZ-RdfLblIziL~IFULtkzZ~eiZv82oqYwAjnTPaYThqt34xSCIV8sI2ulUJ4nAVOOQa7HitKq5FsCba6RgLiGLfsb~OD-jUZoSYPrmjLKLDTYlbA__&Key-Pair-Id=K1UMNJVTUVQ48Y",
        ],
      },
    ],
  },

  {
    section: "How to make your thumbnail FAST",
    content: [
      {
        title: "Getting your thumbnail made",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/02b0c775-72bc-4439-905e-a7cd68c67d61",
      },
      {
        title: "How to Hire a Thumbnail Designer!",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/02b0c775-72bc-4439-905e-a7cd68c67d61",
      },
      {
        title: "Sites to find affordable viral thumbnail designers",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/3a5c94bf-9efb-421d-9073-c4ec0479bb96",
        htmlSrc: `Sites:

Behance.net: My favorite website to find thumbnail designers

Dribbble.com: Another similar site to Behance with a lot of good designers

Upwork: My second favorite website to find thumbnail designers

Fiverr: You'll be able to find good thumbnail designers on here but they will typically cost more

Price range

The price range you should be paying: $5-25

Avoid "thumbnail design agencies" or other "marketing agencies" that promise you white-glove service for viral thumbnails. They charge way too much and since they have multiple clients, you're not going to get very good results or attention.

Also, avoid thumbnail designers that market themselves as "professional YouTube thumbnail designers". You should NOT pay anything crazy like $100 or more for a thumbnail. Jake has had a friend pay $200-300 for a single mediocre thumbnail! This is a rip-off. These designers know the value of a thumbnail and will try to overcharge you.`,
      },
      {
        title: "Outreach message to send to potential designers",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/9fafea62-9865-4593-b4fc-77d60e081796",
        htmlSrc: `If you find a thumbnail designer you like on one of these sites, send them a message saying that you'd like to pay them for a test thumbnail to see if they're the right fit. 

These platforms do not allow you to ask them to do a free thumbnail. This will get you banned from these platforms which is why we want to pay for a test thumbnail. 

To save money, you can try paying less for a test thumbnail, like $5, and say if you end up hiring them, you'll pay them more, like $20 a thumbnail for example.

Outreach message template (feel free to change it as you need and put it into your own language):

Hi [NAME],

I run a YouTube channel and I’m looking for a thumbnail designer. If you do good with this job, I will have much more consistent work for you.

Below I’ve attached a thumbnail design that I would like you to make. I’d like you to recreate this thumbnail EXACTLY like it is along with the changes I want below. Do not change anything else about the thumbnail besides these changes.

[LINK TO THUMBNAIL OR ATTACH SCREENSHOT OF IT]

Here are the EXACT changes I want made to it:

- [DESCRIBE EXACTLY WHAT YOU WANT CHANGED ABOUT IT, THE MORE DESCRIPTIVE YOU ARE ABOUT IT, THE LESS MISTAKES THEY WILL MAKE]

I need this done by [DEADLINE]

I will pay you [$5-10] USD for this via [PayPal or whatever payment method you can use]

If you do well and I end up hiring you, I will pay you [$15-20] USD per thumbnail.

Let me know if you can get this done!

Thanks,

[YOUR NAME]`,
      },
      {
        title: "Job post template",
        videoSrc:
          "hhttps://iframe.mediadelivery.net/embed/204326/e661699f-16dc-47d2-abfb-44e76a400848",
        htmlSrc: `Alternatively, you can also publish a job post on these sites

Job post title: 

Long Term Thumbnail Designer for [your channel topic] YouTube channel (remote, consistent work)

Job post description:

We are looking for a skilled thumbnail designer for our YouTube channel.

If hired, there will be consistent work and this will be a long-term position.

Below we've attached a thumbnail design that you would be making for us.

[LINK TO THUMBNAIL OR ATTACH SCREENSHOT OF IT]

If you’re confident you can make thumbnails that look EXACTLY like this, then this is the job for you. 

If you do NOT think you can create thumbnails that look EXACTLY like this, do NOT apply.

To apply, send us a link to 2-3 thumbnail designs you have made (make sure the link is viewable). Do NOT send more than 2-3 examples. 

If it's good, we will pay you to create a test thumbnail for us to test your skills.

If you think you can do this, apply right now before it's too late.

Send this message to good applicants:

Once you post the job post and you start getting applicants in, review the applicants' submissions, pick the best ones, and send them this message:

Hello!

I liked your application. I will pay you [$5-10] USD for this via [PayPal or whatever payment method you can use] to make a test thumbnail.

If you do well and I end up hiring you, I will pay you [$15-20] USD per thumbnail. Below I’ve attached a thumbnail design that I would like you to make. I’d like you to recreate this thumbnail EXACTLY like it is along with the changes I want below. Do not change anything else about the thumbnail besides these changes.

[LINK TO THUMBNAIL OR ATTACH SCREENSHOT OF IT]

Here are the EXACT changes I want made to it:

- [DESCRIBE EXACTLY WHAT YOU WANT CHANGED ABOUT IT, THE MORE DESCRIPTIVE YOU ARE ABOUT IT, THE LESS MISTAKES THEY WILL MAKE]

I need this done by [DEADLINE]

Let me know if you can get this done!

Thanks,

[YOUR NAME]`,
      },
      {
        title: "Watch Jake go through REAL applicants!",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/a3ab7f1a-d945-4739-929d-ad4426a7b585",
      },
    ],
  },

  {
    section: "Viral Thumbnail Templates",
    content: [
      {
        title: "How to use the Virality Formulas",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/6b8a94e3-050c-4573-ab8b-bd6b31a2e504",
      },
    ],
  },

  {
    section: "Reviewing your thumbnail",
    content: [
      {
        title: "How to review your thumbnail",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/93ca104a-467b-4cd7-90b0-e60d65aa458b",
        downloadSrc: ["https://clickpilot.app/"],
      },
      {
        title: "Does your thumbnail pass this test?",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/2e6d8e30-9f0b-4cc2-ab31-50f08892ec7c",
      },
      {
        title: "🎉 REQUEST THUMBNAIL REVIEW HERE",
        videoSrc:
          "hhttps://iframe.mediadelivery.net/embed/204326/d9fd87a2-536c-4bfd-aec7-c65e703d04a7",
        htmlSrc: `
        <div contenteditable="false" translate="no" class="ProseMirror skool-editor2"><ol><li><p>If you haven't found and got feedback on your video idea, <a target="_blank" rel="noopener noreferrer" class="link link" href="https://www.skool.com/faceless-income-accelerator-3767/classroom/7250cd69?md=e54a502d74a44fc1becd6ad01ac512cc">go back</a> and request idea review first to make sure your idea has potential.</p></li><li><p>Upload your thumbnail(s) with the title(s) to <a target="_blank" rel="noopener noreferrer" class="link link" href="https://clickpilot.app/">ClickPilot</a> and take a screenshot of it and have that ready to send into the chat of the Zoom call. </p><ol><li><p>It's important to have both the thumbnail with the title you plan to use with it because they work together.</p></li><li><p>How to take a screenshot on <a target="_blank" rel="noopener noreferrer" class="link link" href="https://support.microsoft.com/en-us/windows/use-snipping-tool-to-capture-screenshots-00246869-1843-655f-f220-97299b865f6b#:~:text=Press%20Windows%20logo%20key%20%2B%20Shift,R%20for%20a%20video%20snip.">Windows</a> and <a target="_blank" rel="noopener noreferrer" class="link link" href="https://support.apple.com/en-us/102646">Mac</a></p></li></ol></li><li><p>Also upload your thumbnail(s) to Google Drive folder and generate a viewable link for it in case the coach needs the full size image file as well</p></li><li><p>Attend the Viral Thumbnail Mastermind call on Fridays (View the Skool calendar <a target="_blank" rel="noopener noreferrer" class="link link" href="https://www.skool.com/faceless-income-accelerator-3767/calendar?cv=list">here</a>)</p></li><li><p>Ask for live review via chat once on call. Have all resources ready.</p></li></ol></div>`,
      },
    ],
  },

  {
    section: "Improving your thumbnail/title",
    content: [
      {
        title: "When you're stuck on a thumbnail/title",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/5dca2af5-ed97-46bc-a986-d9576b0e7e68",
      },
      {
        title: "Watch me come up with a thumbnail/title LIVE! 1",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/488dd7b4-2f40-4af2-8914-8c435c433108",
      },
      {
        title: "Watch me come up with a thumbnail/title LIVE! 2",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/3f564903-9b89-4540-bf2f-4c4da0a52935",
      },
      {
        title: "Your video idea is just as important",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/f6612b7b-f204-4bfe-b1e2-7a9fc7fd720f",
      },
    ],
  },

  {
    section: "Extras",
    content: [
      {
        title: "VIP Q&A",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/c15bf71b-de08-4bc2-96a5-ced7426c3f8b",
      },
      {
        title: "Video idea strategy: mixing mass market with niche",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/641f78df-c562-449d-91fb-66776882d2bd",
      },
      {
        title: "The Most UNDER-LOOKED Thing by Beginners",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/f7c26003-c3c7-41b0-bea6-1abb247209a7",
      },
    ],
  },
];
