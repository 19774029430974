const DownloadIcon = () => {
    return (
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.25 12.7497H12.75V14.1663H4.25V12.7497ZM7.79167 2.83301V8.91476L4.75079 5.87388L3.74921 6.87547L8.5 11.6263L13.2508 6.87547L12.2492 5.87388L9.20833 8.91476V2.83301H7.79167Z"
          fill="#293056"
        />
      </svg>
    );
  };
  
  export default DownloadIcon;