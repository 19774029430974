// LoaderModal.js
import { useEffect, useState } from "react";
import { FullScreenModal } from "../../ui";
import ThreeDotsLoading from "../../ThreeDotsLoading";

const SpinningModal = ({
  open,
  minClosingTime,
}: {
  open: boolean;
  minClosingTime?: number;
}) => {
  const [visible, setVisible] = useState(open);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timer: any;
    if (!open) {
      timer = setTimeout(() => {
        setVisible(false);
      }, minClosingTime || 100);
    } else {
      setVisible(true);
    }

    return () => clearTimeout(timer);
  }, [minClosingTime, open]);

  return visible ? (
    <FullScreenModal open={visible}>
      <div className="mt-4 flex items-center justify-center">
        <ThreeDotsLoading />
      </div>
    </FullScreenModal>
  ) : (
    ""
  );
};

export default SpinningModal;
