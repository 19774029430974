import { ComponentPropsWithoutRef } from "react";
import FlashOnIcon from "../../ui/Icons/FlashOnIcon";
import BxMoneyIcon from "../../ui/Icons/BxMoneyIcon";
import BxShield from "../../ui/Icons/BxShield";
import { cn } from "../../../utils/utils";

const services = [
  {
    label: "Instant Access",
    icon: <FlashOnIcon className="h-6 w-6 text-black" />,
  },
  {
    label: "365-Day Money-Back Guarantee",
    icon: <BxMoneyIcon className="h-6 w-6 text-black" />,
  },
  {
    label: "Secure Payment",
    icon: <BxShield className="h-6 w-6 text-black" />,
  },
];

export interface GetInstantServicesProps
  extends ComponentPropsWithoutRef<"div"> {}

const GetInstantServices = ({
  className,
  ...props
}: GetInstantServicesProps) => {
  return (
    <div
      {...props}
      className={cn(
        "flex flex-col items-center justify-center gap-[10px] md:flex-row md:gap-7.5",
        className,
      )}
    >
      {services.map((service) => {
        return (
          <div
            key={service.label}
            className="flex items-center text-14 gap-[10px]"
          >
            {service.icon}
            {service.label}
          </div>
        );
      })}
    </div>
  );
};

export default GetInstantServices;
