import React from 'react';
import useUrlParams from '../../hooks/useUrlParams';

interface UrlParamsPersistenceProps {
    children: React.ReactNode;
    ignoreRoutes?: string[];
  }

const UrlParamsPersistence: React.FC<UrlParamsPersistenceProps> = ({ children, ignoreRoutes = [] }) => {
  useUrlParams(ignoreRoutes);
  return <>{children}</>;
};

export default UrlParamsPersistence;