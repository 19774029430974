// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PhoneIcon = ({ className }: any) => {
  return (
    <svg
      width={"1em"}
      height={"1em"}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.7798 13.7118L13.0535 10.3238C12.6878 9.99109 12.1231 10.0085 11.7784 10.3633L9.58485 12.6192C9.05685 12.5183 7.99535 12.1874 6.90268 11.0975C5.81001 10.0039 5.4791 8.93967 5.38102 8.41534L7.6351 6.22084C7.99077 5.87617 8.00727 5.31242 7.67452 4.94576L4.28743 1.22042C3.95468 0.852839 3.3891 0.818005 3.01235 1.14067L1.02318 2.84659C0.864598 3.00609 0.770181 3.21692 0.757348 3.44151C0.743598 3.67067 0.481431 9.09917 4.69076 13.3103C8.36293 16.9816 12.9628 17.2502 14.2296 17.2502C14.4148 17.2502 14.5284 17.2447 14.5587 17.2428C14.7833 17.23 14.9941 17.1356 15.1527 16.9761L16.8577 14.986C17.1822 14.6093 17.1464 14.0446 16.7798 13.7118Z"
        fill="white"
      />
    </svg>
  );
};
