import "./progress-bar.css";
import { useState, useEffect } from "react";

const ProgressBar = ({
  stages,
  infinite = false,
}: {
  stages: { duration: number; end: number }[];
  infinite?: boolean;
}) => {
  const [currentStage, setCurrentStage] = useState(0);

  useEffect(() => {
    if (currentStage < stages.length) {
      const timer = setTimeout(() => {
        setCurrentStage(currentStage + 1);
      }, stages[currentStage].duration);
      return () => clearTimeout(timer);
    }

    if (infinite) {
      // Reset to the first stage without transition effect
      setCurrentStage(0);
    }
  }, [currentStage, stages, infinite]);

  const progressPercentage =
    currentStage < stages.length ? stages[currentStage].end : 100;

  return (
    <div className="progress-bar">
      <div
        className={`progress-bar-fill  ${
          currentStage === 0 ? "no-transition" : ""
        }`}
        style={{
          width: `${progressPercentage}%`,
          transition: `width ${stages[currentStage]?.duration || 0}ms linear`,
        }}
      />
    </div>
  );
};

export default ProgressBar;
