export const AMEXIcon = () => {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0152 1.90043H18.7044L16.9738 6.91118L15.1679 1.90043H11.7819V9.70254L8.32052 1.90043H5.53658L2.07523 9.87191H4.48299L5.16019 8.27771H8.77206L9.44926 9.87191H13.964V4.32977L15.9956 9.87191H17.8767L19.9082 4.32977V9.87191H22.0152V11.0866H19.2311L16.9738 13.592L14.6412 11.0866H5.08504V19.0581H14.566L16.8984 16.4769L19.2311 19.0581H22.0152V20.0448H18.7044L16.8984 18.0711L15.0925 20.0448H3.88116V10.9347H0.269287L4.78401 0.685547H9.14823L10.7283 4.1779V0.685547H16.1461L17.0489 3.34278L17.952 0.685547H22.0152V1.90043ZM22.0152 12.8326L19.9836 15.0343L22.0152 17.2359V19.0581L18.3282 15.0343L22.0152 11.0866V12.8326ZM11.7819 19.0581V17.1601H7.26715V15.9455H11.7065V14.1234H7.26715V12.9087H11.7819V11.0866L15.544 15.0343L11.7819 19.0581ZM5.83739 6.53142L6.89098 3.95022L7.94435 6.53142H5.83739Z"
        fill="#006FCF"
      />
    </svg>
  );
};
