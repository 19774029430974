import DownloadIcon from "../../../assets/icons/DownloadIcon";
import { Link } from "react-router-dom";

type DownloadCardProps = {
  num: number;
  url: string;
};

const DownloadCard = ({ num, url }: DownloadCardProps) => {
  return (
    <div>
      <button className="h-[33px] w-full min-w-[185px] rounded-[8px] bg-[#EAECF5] px-[11px] py-[8px] text-[#293056] md:min-w-[187px]">
      <Link
          to={url}
          className="flex gap-x-1.5 text-14 font-medium"
          target="blank"
        >
         <div>
            <DownloadIcon />
          </div>
          <div>Material Download #{num}</div>
        </Link>
      </button>
    </div>
  );
};

export default DownloadCard;
