import {
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { useFullScreenModalStore } from "../../libs/store";

/**
 *
 * This component is a full screen loader that can be used to show a loading spinner to simulate 6s delay when navigating between needed pages
 * Requirement: https://www.loom.com/share/be539fead4b24a02b65bb9a315d273be
 */
const FullScreenLoader = () => {
  const isOpen = useFullScreenModalStore((state) => state.isOpen);

  return (
    <Modal size="full" isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        <Flex w="100vw" h="100vh" justifyContent="center" alignItems="center">
          <Spinner size="xl" />
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default FullScreenLoader;
