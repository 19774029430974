import { ComponentPropsWithoutRef } from "react";
import { cn } from "../../../utils/utils";

export interface StoryTellingGroupProps
  extends ComponentPropsWithoutRef<"section"> {}

const StoryTellingGroup = ({ className, ...props }: StoryTellingGroupProps) => {
  return (
    <section
      {...props}
      className={cn("flex flex-col gap-7.5 md:gap-10", className)}
    />
  );
};

export default StoryTellingGroup;
