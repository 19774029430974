import { Image } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import logoViralTop from "../../../assets/images/logo_viral_top.png";
import { avatars, ROUTES } from "../../../constants";
import GetInstantParticipated from "../../SalesPageComponents/parts/GetInstantParticipated";
import { PreloadImage } from "../../ui/Image/PreLoadImage";
import { ArrowToRight } from "../../../assets/icons";
import { useAuth } from "../../../providers/AuthProvider";
import "./styles.css";

const WherePassionMeetsProfits = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleOnClickLogin = () => !!user ?  navigate(ROUTES.MEMBERSHIP) :  navigate(ROUTES.LOGIN)

  return (
    <div className="relative md:min-h-screen 2xl:min-h-fit">
      <div className="ml-auto mr-auto flex w-full items-center justify-between px-5 py-4 lg:max-w-[1100px] 2xl:max-w-[1280px]">
        <Link
          to="/"
          className="w-fit font-Benzin_bold text-sm font-normal md:text-base"
        >
          <Image
            w="100%"
            maxW="118px"
            h="100%"
            maxH="20"
            src={logoViralTop}
            objectFit="contain"
          />
        </Link>
        <div
          className="flex w-fit cursor-pointer gap-1 rounded-lg bg-white px-[10px] py-1 text-xl shadow-btnBlackBackground md:px-3 md:py-2"
          onClick={handleOnClickLogin}
        >
          <span className="hidden text-sm font-bold md:inline">
            Log in as a member
          </span>
          <ArrowToRight />
        </div>
      </div>

      <div className="ml-auto mr-auto flex flex-col items-center justify-center border-t-[1px] px-6 pb-10">
        <div className="mt-[15px] max-w-[548px] text-center text-40 font-black md:mt-2.5 md:text-78">
          Where Passion Meets Profits
        </div>
        <div className="mt-3 max-w-[341px] text-center text-20 font-bold-450 md:mt-4.5 md:max-w-[516px] md:text-26">
          We help people with zero video & business experience start faceless
          YouTube channels
        </div>

        <div className="mt-[25px] md:mt-7.5">
          {avatars.map((avatar, index) => {
            return <PreloadImage imgUrl={avatar.src} key={index} />;
          })}
          <GetInstantParticipated
            items={avatars}
            className="justify-center"
            hideCountUser
            variant="large"
          />
          <div className="mt-[15px] text-center text-16 font-bold md:mt-4.5 md:text-18">
            1500+ Students From 43 Countries
          </div>
        </div>

        <div className="mt-[30px] flex max-w-[515px] flex-col items-center justify-center rounded-2.5xl border-[5px] border-[#FFF2F6] bg-white px-5 py-4 shadow-quizBoxShadow md:px-[25px]">
          <div className="max-w-[455px] text-center text-18">
            Take the FREE 1-minute quiz to find out how much you could earn from
            YouTube!
          </div>
          <a
            href="/quiz"
            className="mt-5 w-full max-w-[280px] cursor-pointer rounded-[13px] bg-[#D92D20] py-[11px] text-center text-18 font-bold text-white"
          >
            Start the Quiz
          </a>
        </div>
      </div>

      {/* Background gradient */}
      <div className="red-radial-gradient absolute bottom-0 left-0 -z-10 h-full w-full"></div>
    </div>
  );
};

export default WherePassionMeetsProfits;
