import IntlTelInput from "intl-tel-input/react";
import "intl-tel-input/styles";
import { cn } from "../../../../utils/utils";
import { useEffect, useRef } from "react";
import "./country-input.css";

export interface CountryInputProps {
  name?: string;
  countryCode?: string;
  className?: string;
  containerClassName?: string;
  onChange?: (value: string) => void;
  showArrow?: boolean;
}

export const CountryInput = ({
  countryCode,
  name,
  className,
  containerClassName,
  onChange,
  showArrow = false,
}: // ...field
CountryInputProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = useRef<any | null>(null);
  
  useEffect(()=> {
    if (inputRef.current) {
      (inputRef.current.getInput() as HTMLInputElement).type = "button";
      (inputRef.current.getInput() as HTMLInputElement).classList.remove("iti__tel-input");
      (inputRef.current.getInput() as HTMLInputElement).classList.add("iti__tel-button");
      (inputRef.current.getInput() as HTMLInputElement).style.setProperty("width", "100%", "important");
      (inputRef.current.getInput() as HTMLInputElement).style.setProperty("height", "100%", "important");
    }
  }, [])
  
  return (
    <div className={cn("select-country-code relative", containerClassName)}>
      <IntlTelInput
        ref={inputRef}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChangeCountry={(country) => onChange?.(country)}
        inputProps={{
          name,
          className,
          tabIndex: -1, // disable input action by tabIndex=-1
        }}
        initOptions={{
          strictMode: true,
          initialCountry: countryCode || "us",
          showFlags: true,
          useFullscreenPopup: false,
          separateDialCode: true,
        }}
      />

      {showArrow ? (
        <svg
          className="absolute right-1.5 top-1/2 -translate-y-1/2 transform"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.862 6.19531L7.99998 9.05731L5.13798 6.19531L4.19531 7.13798L7.99998 10.9426L11.8046 7.13798L10.862 6.19531Z"
            fill="#484848"
          />
        </svg>
      ) : null}
    </div>
  );
};
