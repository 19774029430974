import { Widget } from '@typeform/embed-react';

const SeeIfYouQualifyPage = () => {
  return (
    <div className="h-screen w-screen">
      <Widget id={'Bk3aUbKU'} fullScreen={true} />
    </div>
  );
};

export default SeeIfYouQualifyPage;
