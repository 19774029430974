/* eslint-disable @typescript-eslint/no-explicit-any */
import { Text } from "@chakra-ui/react";
import { Control, useFormContext, useFormState } from "react-hook-form";

export interface ErrorMessageProps {
  name: string;
  control?: Control<any, any>;
}

const ErrorMessage = ({ name, control, ...props }: ErrorMessageProps) => {
  const ctx = useFormContext();
  const { errors } = useFormState({ control: control || ctx.control });

  const fieldError = errors[name];

  if (!fieldError) return null;

  return (
    <Text {...props} color="red.500" fontSize="14px" mt="3px">
      {fieldError?.message?.toString()}
    </Text>
  );
};

export default ErrorMessage;
