import { Box, Image, useMediaQuery } from "@chakra-ui/react";
import successStoryImgMobile from "../../assets/images/success-story-mobile.png";
import successStoryImg from "../../assets/images/success-story.png";
import WinningStudents from "../homepage/WinningStudents";
import FastStartHeadLine from "./FastStartHeadline";

const SuccessStory = () => {
  const [isMobile] = useMediaQuery("(max-width: 912px)");
  return (
    <Box pb="100px">
      <FastStartHeadLine
        text="Are you our next success story?"
        textAlign="center"
      />

      <WinningStudents className="overflow-hidden" />

      <Box maxWidth="1300px" mx="auto">
        {isMobile ? (
          <Image
            src={successStoryImgMobile}
            alt="Success Story Image"
            margin="-25px 0"
            className="w-full"
          />
        ) : (
          <Image
            className="w-full"
            src={successStoryImg}
            alt="Success Story Image"
          />
        )}
      </Box>
    </Box>
  );
};

export default SuccessStory;
