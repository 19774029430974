import { Image } from "@chakra-ui/react";
import { ReactNode } from "react";
import { cn } from "../../../utils/utils";

const SadIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
    >
      <path
        d="M15 2.5C8.1075 2.5 2.5 8.1075 2.5 15C2.5 21.8925 8.1075 27.5 15 27.5C21.8925 27.5 27.5 21.8925 27.5 15C27.5 8.1075 21.8925 2.5 15 2.5ZM15 25C9.48625 25 5 20.5138 5 15C5 9.48625 9.48625 5 15 5C20.5138 5 25 9.48625 25 15C25 20.5138 20.5138 25 15 25Z"
        fill="#D92D20"
      />
      <path
        d="M10.625 15C11.6605 15 12.5 14.1605 12.5 13.125C12.5 12.0895 11.6605 11.25 10.625 11.25C9.58947 11.25 8.75 12.0895 8.75 13.125C8.75 14.1605 9.58947 15 10.625 15Z"
        fill="#D92D20"
      />
      <path
        d="M19.3663 14.9825C20.397 14.9825 21.2325 14.147 21.2325 13.1163C21.2325 12.0855 20.397 11.25 19.3663 11.25C18.3355 11.25 17.5 12.0855 17.5 13.1163C17.5 14.147 18.3355 14.9825 19.3663 14.9825Z"
        fill="#D92D20"
      />
      <path
        d="M15 17.5C11.25 17.5 10 21.25 10 21.25H20C20 21.25 18.75 17.5 15 17.5Z"
        fill="#D92D20"
      />
    </svg>
  );
};

const SmileIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 22.5C20 22.5 21.25 17.5 21.25 17.5H8.75C8.75 17.5 10 22.5 15 22.5Z"
        fill="#039855"
      />
      <path
        d="M15 2.5C8.1075 2.5 2.5 8.1075 2.5 15C2.5 21.8925 8.1075 27.5 15 27.5C21.8925 27.5 27.5 21.8925 27.5 15C27.5 8.1075 21.8925 2.5 15 2.5ZM15 25C9.48625 25 5 20.5138 5 15C5 9.48625 9.48625 5 15 5C20.5138 5 25 9.48625 25 15C25 20.5138 20.5138 25 15 25Z"
        fill="#039855"
      />
      <path
        d="M16.25 15L18.75 15.015C18.765 14.4375 18.9925 13.75 20 13.75C21.0075 13.75 21.235 14.4375 21.25 15H23.75C23.75 13.4925 22.7512 11.25 20 11.25C17.2487 11.25 16.25 13.4925 16.25 15ZM10 13.75C11.0075 13.75 11.235 14.4375 11.25 15H13.75C13.75 13.4925 12.7512 11.25 10 11.25C7.24875 11.25 6.25 13.4925 6.25 15L8.75 15.015C8.765 14.4375 8.9925 13.75 10 13.75Z"
        fill="#039855"
      />
    </svg>
  );
};

const TextWithXLabel = ({ children }: { children: ReactNode }) => {
  return (
      <div className="flex items-center w-[320px] gap-[5px] rounded-[43px] bg-white px-[14px] py-2.5 capitalize min-[1440px]:w-[420px] min-[1440px]:px-5 min-[1440px]:py-[15px] min-[1440px]:text-16 min-[1440px]:leading-[19.78px] font-medium text-14">      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
          fill="#D92D20"
        />
      </svg>
      {children}
    </div>
  );
};

const TextWithMarkedLabel = ({ children }: { children: ReactNode }) => {
  return (
      <div className="flex w-[320px] items-center gap-[5px] rounded-[43px] bg-[#039855] px-[14px] py-2.5 text-14 font-medium capitalize text-white min-[1440px]:w-[420px] min-[1440px]:px-5 min-[1440px]:py-[15px] min-[1440px]:text-16 min-[1440px]:leading-[19.78px]">      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.99997 15.586L6.70697 12.293L5.29297 13.707L9.99997 18.414L19.707 8.70697L18.293 7.29297L9.99997 15.586Z"
          fill="white"
        />
      </svg>
      {children}
    </div>
  );
};

const WhatWeDoIsDifferent = () => {
  return (
    <div className="mt-15 flex flex-col items-center justify-center min-[1440px]:mt-[90px]">
      <div className="text-24 font-black min-[1440px]:text-44">
        What we do is different…
      </div>
      <div className="flex flex-col gap-0 min-[1440px]:flex-row min-[1440px]:gap-[25px]">
        <div>
          <div className="mt-5 text-center text-24 font-black min-[1440px]:mt-[25px] min-[1440px]:text-32">
            Traditional YouTube Channel
          </div>
          <div
            className="mt-2.5 text-center text-16 opacity-[0.45] min-[1440px]:text-20"
            style={{ color: "rgba(0, 0, 0, 0.45)" }}
          >
            What most people do:
          </div>
          <div className="relative mt-5 h-[548px] w-[345px] rounded-[25px] bg-[#FEF3F2] px-3 py-5 min-[1440px]:mt-[25px] min-[1440px]:h-[690px] min-[1440px]:w-[480px] min-[1440px]:gap-5 min-[1440px]:px-[30px] min-[1440px]:py-[34px]">
            <div className="absolute left-1/2 z-10 flex -translate-x-1/2 flex-col gap-[15px] min-[1440px]:gap-5">
              <TextWithXLabel>Research an Idea for hours</TextWithXLabel>
              <TextWithXLabel>write all video scripts yourself</TextWithXLabel>
              <TextWithXLabel>Buy expensive camera equipment</TextWithXLabel>
              <TextWithXLabel>Learn how to talk on camera</TextWithXLabel>
              <TextWithXLabel>Talk On Camera For Hours</TextWithXLabel>
              <TextWithXLabel>edit your own videos</TextWithXLabel>
              <TextWithXLabel>Learn Fancy Animations</TextWithXLabel>
            </div>

            <div className="absolute left-1/2 min-[1440px]:top-10 top-8.5 -translate-x-1/2">
              <Image
                src="/images/home/arrow-down.png"
                className="h-[400px] min-[1440px]:h-[520px]"
              />
            </div>

            <div className="absolute bottom-[-10px] left-1/2 flex w-[264px] -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center min-[1440px]:bottom-0">
              <SadIcon />
              <div className="text-center text-16 capitalize leading-[19.78px] font-medium mt-[10px]">
                Keep failing for months and burn out with no success
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="mt-5 text-center text-24 font-black min-[1440px]:mt-[25px] min-[1440px]:text-32">
            Faceless YouTube Channel
          </div>
          <div
            className="mt-2.5 text-center text-16 opacity-[0.45] min-[1440px]:text-20"
            style={{ color: "rgba(0, 0, 0, 0.45)" }}
          >
            What we do:
          </div>

          <div className="relative mt-5 h-[320px] w-[345px] rounded-[25px] bg-[#ECFDF3] px-3 py-5 min-[1440px]:mt-[25px] min-[1440px]:h-[384px] min-[1440px]:w-[480px] min-[1440px]:px-[30px] min-[1440px]:py-[34px]">
            <div className="absolute z-10 flex flex-col gap-[15px]">
              <TextWithMarkedLabel>
                generate video ideas from existing content
              </TextWithMarkedLabel>
              <TextWithMarkedLabel>
                get freelancers to create & edit videos for you
              </TextWithMarkedLabel>
              <TextWithMarkedLabel>post ready-to-go videos</TextWithMarkedLabel>
            </div>
            <div className="absolute left-1/2 top-10 -translate-x-1/2">
              <Image
                src="/images/home/arrow-down-short.png"
                className="h-[180px] min-[1440px]:h-[215px]"
              />
            </div>
            <div className="absolute bottom-[-25px] left-1/2 flex w-[264px] -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center min-[1440px]:bottom-0">
              <SmileIcon />
              <div className="text-center capitalize text-16 leading-[19.78px] font-medium mt-[10px]">
                Succeed and get paid working just 20 - 40 minutes a day
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDoIsDifferent;
