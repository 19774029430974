import axios from "../libs/axios";
import { WatchedVideo } from "../types/type";

export const unlockNextVideo = () => {
  return axios.post("/user/unlock");
};

export const updateVideoProgress = (
  data: Pick<WatchedVideo, "percent" | "progress" | "videoIndex">,
) => {
  return axios.put("/user/watched-video", data);
};
