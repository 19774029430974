import {
  ComponentPropsWithoutRef,
  CSSProperties,
  useCallback,
  useEffect,
  useState
} from "react";
import Student1Image from "../../../assets/images/student-1.png";
import Student10Image from "../../../assets/images/student-10.png";
import Student11Image from "../../../assets/images/student-11.png";
import Student2Image from "../../../assets/images/student-2.png";
import Student3Image from "../../../assets/images/student-3.png";
import Student4Image from "../../../assets/images/student-4.png";
import Student5Image from "../../../assets/images/student-5.png";
import Student6Image from "../../../assets/images/student-6.png";
import Student7Image from "../../../assets/images/student-7.png";
import Student8Image from "../../../assets/images/student-8.png";
import Student9Image from "../../../assets/images/student-9.png";
import { cn } from "../../../utils/utils";
import ModalVideoPlayer from "../ModalVideoPlayer";

const students = [
  {
    name: "Reinaldo",
    src: Student1Image,
    wistiaId: "lbkpg6lf7x",
  },
  {
    name: "Kristepher",
    src: Student2Image,
    wistiaId: "7sx6pw2bpq",
  },
  {
    name: "Zion",
    src: Student3Image,
    wistiaId: "ev8c5uq5z7",
  },
  {
    name: "Ed Ash",
    src: Student4Image,
    wistiaId: "6g6zydfeoj",
  },
  {
    name: "Anna",
    src: Student5Image,
    wistiaId: "4kae1eqdm3",
  },
  {
    name: "Will",
    src: Student6Image,
    wistiaId: "9a789x6rng",
  },
];

const students2 = [
  {
    name: "Julio",
    src: Student7Image,
    wistiaId: "32kx8pojc2",
  },
  {
    name: "Michael",
    src: Student8Image,
    wistiaId: "uzyc5xp4k8",
  },
  {
    name: "Steve",
    src: Student9Image,
    wistiaId: "fiekcyf4i8",
  },
  {
    name: "David",
    src: Student10Image,
    wistiaId: "8zvv4673z9",
  },
  {
    name: "Tamara",
    src: Student11Image,
    wistiaId: "un7ie7nmrp",
  },
];

export interface WinningStudentsProps extends ComponentPropsWithoutRef<"div"> {
  title?: string;
}

const WinningStudents = ({
  className,
  title,
  ...props
}: WinningStudentsProps) => {
  const [wistiaId, setWistiaId] = useState<string | null>(null);

  const onVideoShow = (wistiaId: string) => {
    setWistiaId(wistiaId);
  };

  const onVideoHide = () => {
    setWistiaId(null);
  };

  const [studentsToShow, setStudentsToShow] =
    useState<typeof students>(students);
  const [students2ToShow, setStudents2ToShow] =
    useState<typeof students2>(students2);

  const updateStudentLists = useCallback(() => {
    const width = window.innerWidth;
    const cardWidth = 240;
    const numberOfCards = Math.ceil(width / cardWidth);

    const newStudentsList = [];
    for (let i = 0; i < numberOfCards; i++) {
      newStudentsList.push(...students);
    }
    setStudentsToShow(newStudentsList);

    const newStudents2List = [];
    for (let i = 0; i < numberOfCards; i++) {
      newStudents2List.push(...students2);
    }
    setStudents2ToShow(newStudents2List);
  }, []);

  useEffect(() => {
    updateStudentLists();
    window.addEventListener("resize", updateStudentLists);

    return () => {
      window.removeEventListener("resize", updateStudentLists);
    };
  }, [updateStudentLists]);

  return (
    <div {...props} className={cn("text-center", className)}>
      {title && (
        <h2 className="mx-auto mb-10 max-w-[833px] text-24 font-black leading-[30.36px] text-black md:text-44 md:leading-[55.66px]">
          {title}
        </h2>
      )}

      <div className="relative w-max items-center overflow-hidden pb-10">
        <div
          id={"ANIMATE_LEFT_SLIDE_INFINITE"}
          style={{ "--gap": "0.75rem", "--duration": "175s" } as CSSProperties}
          className={cn(
            wistiaId && "pause",
            "flex animate-slide-left-infinite justify-start gap-[calc(var(--gap)*2)]",
          )}
        >
          {studentsToShow.map((student, index) => (
            <StudentCard
              onClick={() => onVideoShow(student.wistiaId)}
              key={index}
              className="h-[144px] w-[240px] shrink-0 md:h-[244px] md:w-[408px]"
              src={student.src}
              name={student.name}
            />
          ))}
        </div>
      </div>

      <div className="relative w-max items-center overflow-hidden pb-10">
        <div
          id="ANIMATE_RIGHT_SLIDE_INFINITE"
          style={
            {
              "--gap": "0.75rem",
              "--duration": "175s",
              animationDirection: "reverse",
            } as CSSProperties
          }
          className={cn(
            wistiaId && "pause",
            "flex animate-slide-left-infinite justify-end gap-[calc(var(--gap)*2)]",
          )}
        >
          {students2ToShow.map((student, index) => (
            <StudentCard
              onClick={() => onVideoShow(student.wistiaId)}
              key={index}
              className="h-[144px] w-[240px] shrink-0 md:h-[244px] md:w-[408px]"
              src={student.src}
              name={student.name}
            />
          ))}
        </div>
      </div>

      {/* Video Player in Modal */}
      <ModalVideoPlayer wistiaId={wistiaId} onClose={onVideoHide} />
    </div>
  );
};

export interface StudentCardProps extends ComponentPropsWithoutRef<"div"> {
  src: string;
  name: string;
}

export const StudentCard = ({
  className,
  src,
  name,
  ...props
}: StudentCardProps) => {
  return (
    <div {...props} className={cn("relative cursor-pointer", className)}>
      <img src={src} alt="" className="h-full w-full" />

      <button className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform h-[34px] w-[54px] bg-[#0000008C] flex items-center justify-center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 19L17 12L9 5V19Z" fill="white"/>
        </svg>
      </button>

      <h2
        className={cn(
          "absolute -bottom-[17px] left-1/2 -translate-x-1/2 transform",
          "flex h-[34px] w-[89px] items-center justify-center",
          "rounded-[10px] bg-[#FEE4E2] text-center text-[14px] font-bold leading-[17.71px] text-black",
        )}
      >
        {name}
      </h2>
    </div>
  );
};

export default WinningStudents;
