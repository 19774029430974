import { Image } from "@chakra-ui/react";

export const YoutubeCreatorAroundTheWorld = () => {
  return (
    <div className="hidden md:flex  justify-center items-center flex-col">
      <div className="mt-[90px] text-44 font-black max-w-[784px] text-center">
        Did you know that more than 390,000 people from all over the world have
        already done it?
      </div>
        <div className="relative mt-[40px] max-w-[784px] mt-10 rounded-[25px] p-[40px]">
        <Image 
          src="/images/home/youtube-creator-ecosystem-bg.png" 
          alt="Opt In" 
          className="absolute inset-0 h-full w-full object-cover z-0 rounded-[25px]"
        />
        <Image 
          src="/images/home/youtube-creator-ecosystem.png" 
          className="relative z-10 object-contain m-auto rounded-[16px]" 
          alt="YouTube Creator Ecosystem"
          style={{boxShadow: "0px 4px 42px 0px #0000001A"}}
        />
      </div>
    </div>
  );
};
