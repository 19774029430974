import { useEffect, useRef } from "react";

type BunnyVideoPlayerProps = {
  className?: string;
  src: string;
  width?: number | string;
  height?: number | string;
  onReady?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  onTimeUpdate?: (data: TimeUpdate) => void;
};

export type TimeUpdate = {
  seconds: number;
  duration: number;
};

const BunnyVideoPlayer = ({
  className,
  src,
  width,
  height,
  onReady,
  onPlay,
  onPause,
  onTimeUpdate,
}: BunnyVideoPlayerProps) => {
  const playerRef = useRef(null);

  useEffect(() => {
    const player = new window.playerjs.Player(playerRef.current);

    console.dir(window.playerjs);

    player?.on("ready", onReady);
    player?.on("play", onPlay);
    player?.on("pause", onPause);
    player?.on("timeupdate", onTimeUpdate);
  }, [src]);

  return (
    <div className={`${className} overflow-hidden`}>
      <iframe
        ref={playerRef}
        src={src}
        width={width}
        height={height}
        frameBorder="0"
      />
    </div>
  );
};

export default BunnyVideoPlayer;
