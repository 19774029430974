import { cva, VariantProps } from "class-variance-authority";
import { cn } from "../../utils/utils";
import { getDayName } from "../../utils/date";

// eslint-disable-next-line react-refresh/only-export-components
export const getUnlockTitleByIndex = (
  index: number,
): VariantProps<typeof unlockStyles> & { title: string } => {
  return {
    variant: "black",
    title: "Unlocks " + getDayName(index),
  };
};

const unlockStyles = cva(
  ["rounded-2xl px-3 font-medium !text-12 w-[130px] py-1"],
  {
    variants: {
      variant: {
        yellow: "bg-[#F79009C2] text-white",
        red: "bg-[#F7090DA8] text-white",
        purple: "bg-[#7409F7BF] text-white",
        black: "bg-[#00000099] text-white",
        white: "bg-[#FFFFFFB8] text-black",
      },
    },
    defaultVariants: {
      variant: "yellow",
    },
  },
);

export const UnlockTitle = (
  props: VariantProps<typeof unlockStyles> & { title: string },
) => {
  const { variant } = props;
  return <div className={cn(unlockStyles({ variant }), `${props.title === 'Unlocks Wednesday' ? 'pl-[0.625rem]' : ''}`)}>{props.title}</div>;
};
