import { Text } from "@chakra-ui/react";
import NextIcon from "../NextIcon";

type CTAButtonProps = {
  onClick?: () => void;
};

const CTAButton = ({ onClick }: CTAButtonProps) => {
  return (
    <div className="flex items-center justify-center md:px-0">
      <button
        className="flex w-full items-center justify-center rounded-2xl bg-[#D92D20] py-[19.5px] text-white hover:bg-red-700 md:px-[13.5px] md:py-[18px]"
        onClick={onClick}
      >
        <Text
          className="text-16 md:text-26"
          fontWeight="600"
          lineHeight={{
            md: "27.83px"
          }}
          mr={{
            base: "2px",
            md: "8px",
          }}
        >
          See if you qualify for 1:1 help from Jake
        </Text>{" "}
        <NextIcon variant="white" />
      </button>
    </div>
  );
};

export default CTAButton;
