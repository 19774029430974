import { useEffect } from "react";
import { router } from "./router";
import { RouterProvider } from "react-router-dom";
import { initGA } from "./analytics";

const App = () => {
  useEffect(() => {
    const trackingID = import.meta.env.VITE_GA_MEASUREMENT_ID;
    if (trackingID) {
      initGA(trackingID);
    } else {
      console.error('Google Analytics tracking ID or Measurement ID is missing.');
    }
  }, []);
  
  return <RouterProvider router={router} />;
};

export default App;
