// FullScreenModal.tsx
import { ReactNode, useEffect } from "react";
import { cn } from "../../../utils/utils";

export const FullScreenModal = ({
  children,
  open,
  className,
}: {
  open?: boolean;
  children: ReactNode;
  className?: string;
}) => {
  useEffect(() => {
    document.body.style.overflow = open ? "hidden" : "auto";
    
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);

  if (!open) return null;

  return (
    <>
    <div
        className={cn(
          "fixed z-[999999999] flex h-[100vh] w-full items-center justify-center bg-white bg-opacity-50 backdrop-blur-md",
          className,
        )}
      >
        {children}
      </div>
    </>
  );
};
