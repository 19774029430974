import "./style.css";
import { Image, Link } from "@chakra-ui/react";

const LatestVideo = () => {
  return (
    <>
      <div className="mb-20 px-6 md:mb-[100px]">
        <div className="mt-15 text-center text-24 font-black md:mt-[90px] md:text-44">
          Latest videos
        </div>
        <div className="latest-video mt-5 flex flex-col items-center justify-center gap-[15px] md:mt-[30px] md:gap-5">
          <div className="flex flex-col gap-[15px] md:gap-5 min-[1440px]:flex-row">
            <Link
              target="_blank"
              href="https://youtu.be/8_39g_8H6ZY?si=Y6s2pVnpVZLFXUEN"
            >
              <Image
                src="/images/home/latest-video-3.png"
                className="h-[198px] w-[345px] md:h-[299px] md:w-[520px]"
              />
            </Link>

            <Link
              href="https://youtu.be/SC_emWkG6m0?si=92cGSNn5L2iSfyUR"
              target="_blank"
            >
              <Image
                src="/images/home/latest-video-2.png"
                className="h-[198px] w-[345px] md:h-[299px] md:w-[520px]"
              />
            </Link>
          </div>
          <div>
            <Link
              href="https://youtu.be/yh6Aeh8eb40?si=S89bVvbUA2sENPY1"
              target="_blank"
            >
              <Image
                src="/images/home/latest-video-1.png"
                className="h-[198px] w-[345px] md:h-[299px] md:w-[520px]"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default LatestVideo;
