import { useNavigate } from "react-router-dom";
import StorytellingParagraph from "../ui/StoryTelling/StoryTellingParagraph";
import { ROUTES } from "../../constants";
import { setSessionStorage } from "../../utils/utils";

const NotRightFit = () => {
  const navigate = useNavigate();

  const acceptToDedicateTime = () => {
    setSessionStorage("vp-isNotFit", null);
    navigate(ROUTES.SALES);
  };

  const callToActionButtons = [
    {
      text: "I can dedicate 3 - 5 hours a week",
      onClick: acceptToDedicateTime,
    },
    {
      text: "Maybe I can, I will give it a shot",
      onClick: acceptToDedicateTime,
    },
    {
      text: "Sorry, I can’t do it",
      onClick: () =>
        (window.location.href = "https://www.youtube.com/@jaketran"),
    },
  ];

  return (
    <div className="bg-[#FFFBFA] px-6 py-20 md:py-25">
      <div className="mx-auto max-w-[685px]">
        <StorytellingParagraph
          title={
            <h2 className="text-32 leading-[35.84px] md:text-48 md:leading-[60.72px]">
              Sorry! YouTube might not be for you right now…
            </h2>
          }
        >
          <p>
            Now, I won't beat around the bush here and promise you any unreal
            results like{" "}
            <strong>
              “you can do it even if you have only 2 minutes per day!”
            </strong>{" "}
            so I'm gonna be totally honest with you:
          </p>
          <p>
            To get your channel up and running and ultimately allow it to make
            you a profit…
          </p>
          <p> We suggest setting aside at least 3 - 5 hours each week.</p>
          <p>
            Most people work 8 hours daily, so allowing yourself to set aside a
            few hours for something that will allow you to do what you really
            want and love is not a big deal at all, is it?
          </p>
          <p>This small but powerful commitment leads to great results.</p>
          <p>
            However, if you truly can not allow yourself to set at least 45
            minutes aside each day, then this opportunity might not be the best
            match for you.
          </p>
        </StorytellingParagraph>

        <div className="mt-7.5 flex flex-col items-center gap-4 md:mt-10">
          {callToActionButtons.map((callToAction) => (
            <button
              type="button"
              aria-label={callToAction.text}
              className="h-[56px] w-full rounded-[15px] border border-primary bg-white py-3 text-16 font-bold md:h-[44px] md:w-[331px] md:font-medium"
              onClick={callToAction.onClick}
            >
              {callToAction.text}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NotRightFit;
