import { useEffect, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";

export type VideoProgress = {
  seconds: number;
  duration: number;
  percent: number;
};

type WistiaPlayerProps = {
  id?: string;
  initialValue?: VideoProgress;
  activeVideoIndex?: number;
  onWistiaUpdate?: (value: VideoProgress) => void;
  setCloseModal?: () => void
};

function WistiaPlayer({
  id,
  activeVideoIndex,
  initialValue,
  onWistiaUpdate,
  setCloseModal
}: WistiaPlayerProps) {
  const [videoProgress, setVideoProgress] = useState<VideoProgress>(
    initialValue ?? { seconds: 0, percent: 0, duration: 1 },
  );

  const videoRef = useClickOutside<HTMLDivElement>(()=> setCloseModal?.());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDurationUpdate = (video: any) => {
    const { duration } = video.data.media;
    setVideoProgress((x) => Object({ ...x, duration }));
  };

  const handlePercentUpdate = (percent: number) => {
    setVideoProgress((x) => Object({ ...x, percent }));
  };

  const handleSecondUpdate = (seconds: number) => {
    setVideoProgress((x) => Object({ ...x, seconds }));
  };

  useEffect(() => {
    onWistiaUpdate?.(videoProgress);
  }, [videoProgress]);

  useEffect(() => {
    // Expose functions globally
    window.videoProgress = videoProgress;
    window.setVideoProgress = setVideoProgress;

    // Wistia embed code
    const script1 = document.createElement("script");
    script1.src = `https://fast.wistia.com/embed/medias/${id}.jsonp`;
    script1.async = true;

    const script2 = document.createElement("script");
    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;

    const customScript = document.createElement("script");
    customScript.innerHTML = `
            window._wq = window._wq || [];
            _wq.push({ id: "${id}", onReady: function(video) {
                console.log("I got a handle to the video!", video);
                (${handleDurationUpdate})(video);
                video.bind('percentwatchedchanged', ${handlePercentUpdate});
                video.bind('secondchange', ${handleSecondUpdate});
                video.bind('seek', ${handleUpdateVideoProgress});
                video.bind('play', ${handleUpdateVideoProgress});
                video.bind('pause', ${handleUpdateVideoProgress});
                video.bind('end', ${handleUpdateVideoProgress});
            }});
        `;

    const div = document.createElement("div");
    div.innerHTML = `
            <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                    <div class="wistia_embed wistia_async_${id} seo=false videoFoam=true" playerColor=#d92d20 style="height:100%;position:relative;width:100%">
                        <div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                            <img src="https://fast.wistia.com/embed/medias/${id}/swatch" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
                        </div>
                    </div>
                </div>
            </div>`;

    const container = document.getElementById(wrapper) as HTMLElement;
    container.appendChild(script1);
    container.appendChild(script2);
    container.appendChild(customScript);
    container.appendChild(div);

    return () => {
      // Clean up global handlers and remove appended elements
      delete window.videoProgress;
      delete window.setVideoProgress;

      // Cleanup code
      container.innerHTML = "";

      onWistiaUpdate?.(videoProgress);
    };
  }, [id]);

  const handleUpdateVideoProgress = () => {
    if (activeVideoIndex) {
      onWistiaUpdate?.(videoProgress);
    }
  };

  const wrapper = `wistia-player-${id}`;
  return <div ref={videoRef} id={`${wrapper}`}></div>;
}

export default WistiaPlayer;
