import { useFullScreenModalStore } from "../libs/store";
import { NavigateOptions, useNavigate } from "react-router-dom";

export const useDelayNavigation = () => {
  const setIsOpen = useFullScreenModalStore((state) => state.setIsOpen);
  const navigateFromReactRouter = useNavigate();

  const navigate = (path: string, options?: NavigateOptions) => {
    setIsOpen(true);

    setTimeout(() => {
      setIsOpen(false);
      navigateFromReactRouter(path, options);
    }, 6000);
  };

  return navigate;
};
