import { ComponentPropsWithoutRef } from "react";
import { cn } from "../../utils/utils";

export interface MainStoryProps extends ComponentPropsWithoutRef<"section"> {}

const MainStory = ({ className, ...props }: MainStoryProps) => {
  return (
    <section
      {...props}
      className={cn("pb-25 pt-12.5 md:pb-12.5 md:pt-20", className)}
    />
  );
};

export default MainStory;
