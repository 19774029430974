export const DropdownIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.2197 6.9707L8.99998 10.1905L5.78023 6.9707L4.71973 8.0312L8.99998 12.3115L13.2802 8.0312L12.2197 6.9707Z"
        fill="#484848"
      />
    </svg>
  );
};
