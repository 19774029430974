const ArrowLeft18 = () => {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.25005 8.24998H13.1895L9.2198 4.28023L10.2803 3.21973L16.0605 8.99998L10.2803 14.7802L9.2198 13.7197L13.1895 9.74998H2.25005V8.24998Z"
          fill="#484848"
        />
      </svg>
    );
  };
  
  export default ArrowLeft18;