import { Box, Flex, Image, Text } from "@chakra-ui/react"

import background from "../assets/images/must-watch/background.png"
import WistiaPlayer from "../components/WistiaPlayer"
const MustWatchPage = () => {
    return (
        <>
            <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
                <Box
                    width={{
                        base: "90%",
                        md: "max-content",
                    }}
                    margin="0 auto"
                >
                    <Text
                        className="mb-[25px]"
                        align={"center"}
                        fontWeight="900"
                        style={{
                            maxWidth: "690px",
                            fontSize: "clamp(32px, 5vw, 48px)",
                            marginBottom: "clamp(20px, 5vw, 25px)",
                            lineHeight: "clamp(35.84px, 5vw, 60.72px)",
                        }}
                    >
                        Please watch this important video below in full:
                    </Text>
                    <Text
                        className="mb-[40px]"
                        align={"center"}
                        fontWeight="700"
                        style={{
                            maxWidth: "690px",
                            fontSize: "clamp(20px, 5vw, 24px)",
                            marginBottom: "clamp(30px, 5vw, 40px)",
                            lineHeight: "clamp(20px, 5vw, 28px)",
                        }}
                    >
                        (MUST WATCH IN FULL OR YOUR CALL WILL BE CANCELED)
                    </Text>
                    <WistiaPlayer id={"6bs2wt7r8c"} />
                </Box>
                <Image
                    src={background}
                    alt="Opt In"
                    position="absolute"
                    bottom="0"
                    left="0"
                    zIndex="-1"
                    w="100%"
                    h="100%"
                />
            </Flex>
        </>
    )
}

export default MustWatchPage