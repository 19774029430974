import { Step, StepSeparator } from "@chakra-ui/react";
import { cn } from "../../utils/utils";

export const NumberStep = ({
  index,
  isActive,
  title,
}: {
  index: number;
  isActive?: boolean;
  title: string;
}) => {
  return (
    <Step key={index} className="!gap-0">
      <div className="relative">
        <div
          className={cn(
            "flex h-[30px] w-[30px] items-center justify-center rounded-full text-center text-sm font-medium text-white",
            isActive ? "bg-[#D92D20]" : "bg-[#C9C9C9]",
          )}
        >
          {++index}
        </div>

        <div
          className={cn(
            `absolute -bottom-[13px] left-[50%] w-fit -translate-x-2/4 translate-y-full text-nowrap text-center text-xs md:text-base ${
              isActive ? "font-bold text-black" : "font-normal text-[#888888]"
            }`,
          )}
        >
          {title}
        </div>
      </div>
      <StepSeparator className="!ml-0" />
    </Step>
  );
};
