import { Flex, Spinner } from "@chakra-ui/react";
import { Navigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../constants";
import { useAuth } from "../../providers/AuthProvider";

type PrivateRouteProps = {
  element: JSX.Element;
};

const PrivateRoute = ({ element }: PrivateRouteProps) => {
  const { user, isLoading } = useAuth();

  const { state } = useLocation();

  if (isLoading) {
    return (
      <Flex
        height="100vh"
        width="100vw"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner size="xl" />
      </Flex>
    );
  }

  return element;
  return user ? element : <Navigate to={ROUTES.LOGIN} state={state} replace />;
};

export default PrivateRoute;
