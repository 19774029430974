import { createBrowserRouter } from "react-router-dom";
import RootPage from "./pages/root";

export const router = createBrowserRouter([
  /**
   * Do not define routes here, do it inside RootPage
   */
  {
    path: "*",
    element: <RootPage />,
  },
]);
