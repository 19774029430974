import {
  ChangeEvent,
  Children,
  ComponentProps,
  ComponentPropsWithoutRef,
  ReactElement,
  cloneElement,
  useRef,
  useState,
} from "react";
import { Control, useFormContext, useWatch } from "react-hook-form";
import EmailDomainSuggestion from ".";
import { cn } from "../../utils/utils";

export interface EmailDomainSuggestionEasierProps
  extends ComponentPropsWithoutRef<"div"> {
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
}

const EmailDomainSuggestionEasier = ({
  className,
  children,
  control,
  fieldName,
  ...props
}: EmailDomainSuggestionEasierProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timerRef = useRef<any>(null);
  const [showEmailSuggestions, setShowEmailSuggestions] = useState(false);

  const { setValue } = useFormContext();

  const value = useWatch({ control: control, name: fieldName });

  const handleOpenSuggestions = (event: unknown) => {
    const _event = event as ChangeEvent<HTMLInputElement>;


    const currentValue = _event.target.value || value
    clearTimeout(timerRef.current);
    setShowEmailSuggestions(!!currentValue && !currentValue?.includes("@"));
  };

  const handleCloseSuggestions = () => {
    timerRef.current = setTimeout(() => {
      setShowEmailSuggestions(false);
    }, 300);
  };

  return (
    <>
      {cloneElement(
        Children.only(children) as ReactElement,
        {
          onFocus: handleOpenSuggestions,
          onInput: handleOpenSuggestions,
          onBlur: handleCloseSuggestions,
        } as ComponentProps<"input">,
      )}
      <div {...props} className={cn("relative", className)}>
        {showEmailSuggestions && (
          <EmailDomainSuggestion
            email={value}
            onEmailSuggestionClick={(email) => {
              setValue(fieldName, email);
              setShowEmailSuggestions(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default EmailDomainSuggestionEasier;
