import { ComponentPropsWithoutRef } from "react";

export interface BxMoneyIconProps extends ComponentPropsWithoutRef<"svg"> {}

const BxMoneyIcon = (props: BxMoneyIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 4H3C2.447 4 2 4.447 2 5V19C2 19.553 2.447 20 3 20H21C21.553 20 22 19.553 22 19V5C22 4.447 21.553 4 21 4ZM20 15C18.343 15 17 16.343 17 18H7C7 16.343 5.657 15 4 15V9C5.657 9 7 7.657 7 6H17C17 7.657 18.343 9 20 9V15Z"
        fill="currentColor"
      />
      <path
        d="M12 8C9.794 8 8 9.794 8 12C8 14.206 9.794 16 12 16C14.206 16 16 14.206 16 12C16 9.794 14.206 8 12 8ZM12 14C10.897 14 10 13.103 10 12C10 10.897 10.897 10 12 10C13.103 10 14 10.897 14 12C14 13.103 13.103 14 12 14Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BxMoneyIcon;
