import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import { cn } from "../../utils/utils";
import WistiaPlayer from "../WistiaPlayer";
import "./styles.css";

export interface CallToActionProps
  extends ComponentPropsWithoutRef<"section"> { }

const CallToAction = ({ className, ...props }: CallToActionProps) => {
  return (
    <section
      {...props}
      className={cn(
        "relative px-6 py-12.5 md:py-15",
        className,
      )}
    >
      <div className="hidden text-center md:block">
        <SmallAlert className="mb-7.5" />
      </div>

      <h2 className="mb-[18px] text-center text-32 font-black md:mb-[25px] md:text-48">
        Let’s Find Your <br className="md:hidden" /> Channel Idea in 30 Minutes
      </h2>

      <p className="mb-[25px] text-center text-14 font-medium uppercase md:mb-10 md:text-16">
        Please complete this last step to continue
      </p>

      <div className="mx-auto aspect-video lg:w-1/2">
        <WistiaPlayer id={"hf1a8s69w5"} />
      </div>

      {/* Background gradient */}
      <div className="green-radial-gradient absolute bottom-0 left-0 -z-10 h-full w-full"></div>
    </section>
  );
};

const SmallAlert = ({
  className,
  ...props
}: ComponentPropsWithoutRef<"div">) => {
  return (
    <div
      {...props}
      className={clsx(
        "text-14 font-medium",
        "inline-flex h-[30px] min-w-[151px] items-center justify-center rounded-[17px]",
        "cursor-pointer bg-primary px-3 py-[9px] leading-none text-white",
        className,
      )}
    >
      This gift is on me 🎉
    </div>
  );
};

export default CallToAction;
