import { ComponentPropsWithoutRef } from "react";
import { cn } from "../../../utils/utils";

export interface GetInstantAccessVideoProps
  extends ComponentPropsWithoutRef<"div"> {}

const GetInstantAccessVideo = ({
  className,
  ...props
}: GetInstantAccessVideoProps) => {
  return (
    <div
      {...props}
      className={cn("h-[182px] w-[333px] md:h-[304px] md:w-[602px]", className)}
    >
      <div
        style={{
          position: "relative",
          paddingTop: "56.25%",
        }}
      >
        <iframe
          src="https://iframe.mediadelivery.net/embed/185727/d13df80f-54e9-4cd9-af93-23f34f3946ad?autoplay=true&loop=false&muted=false&preload=true&responsive=true"
          loading="lazy"
          style={{
            border: 0,
            position: "absolute",
            top: 0,
            height: "100%",
            width: "100%",
            borderRadius: "18px",
          }}
          allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default GetInstantAccessVideo;
