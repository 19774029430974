const FacebookIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="white" />
      <path
        d="M15.001 5.00195C9.47895 5.00195 5.00195 9.47895 5.00195 15.001C5.00195 19.991 8.65795 24.127 13.439 24.88V17.892H10.899V15.001H13.439V12.798C13.439 10.29 14.932 8.90695 17.215 8.90695C18.309 8.90695 19.455 9.10195 19.455 9.10195V11.561H18.191C16.951 11.561 16.563 12.333 16.563 13.124V14.999H19.334L18.891 17.89H16.563V24.878C21.344 24.129 25 19.992 25 15.001C25 9.47895 20.523 5.00195 15.001 5.00195Z"
        fill="#1570EF"
      />
    </svg>
  );
};

export default FacebookIcon;
