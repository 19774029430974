import { ComponentPropsWithoutRef } from "react";

export interface FlashOnIconProps extends ComponentPropsWithoutRef<"svg"> {}

const FlashOnIcon = (props: FlashOnIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0968 11.0867L12.0911 21.3201C11.7382 21.8287 10.9079 21.6003 10.9079 20.988L10.8976 15.1344C10.8976 14.4598 10.3268 13.9201 9.621 13.9097L5.43836 13.8578C4.9298 13.8474 4.62882 13.3181 4.90905 12.9134L11.9147 2.67992C12.2676 2.17136 13.0979 2.3997 13.0979 3.01205L13.1083 8.86566C13.1083 9.54028 13.6791 10.08 14.3848 10.0903L18.5675 10.1422C19.0656 10.1422 19.3666 10.6819 19.0968 11.0867Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FlashOnIcon;
