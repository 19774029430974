import { ReactNode, useRef } from "react";
import "./sliding-div.css";
import { cn } from "../../../utils/utils";

export const SlidingDiv = (props: {
  showing: ReactNode;
  bottom: ReactNode;
  className?: string;
}) => {
  const iconContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      onClick={() => {
        if (iconContainerRef.current) {
          iconContainerRef.current.classList.remove("mobile");
          const timeout = setTimeout(() => {
            if (iconContainerRef.current)
              iconContainerRef.current.classList.add("mobile");
            clearTimeout(timeout);
          }, 1000);
        }
      }}
      ref={iconContainerRef}
      id="icon-container"
      className={cn("icon-container", props.className)}
    >
      <div className="icon icon-top">{props.showing}</div>
      <div className="icon icon-bottom">{props.bottom}</div>
    </div>
  );
};
