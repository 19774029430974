import { ReactNode } from "react";
import "./carousel.css";
import { cn } from "../../../utils/utils";

export const Carousel = ({
  items,
  mode = "horizontal",
  className,
  slideClassName,
}: {
  items: ReactNode[];
  mode?: "horizontal" | "vertical";
  className?: string;
  slideClassName?: string;
}) => {
  return (
    <>
      {mode === "horizontal" ? (
        <div
          x-data="{}"
          x-init="$nextTick(() => {
           let ul = $refs.logos;
           ul.insertAdjacentHTML('afterend', ul.outerHTML);
           ul.nextSibling.setAttribute('aria-hidden', 'true');
       })"
          className="w-full inline-flex flex-nowrap overflow-hidden "
        >
          <div
            className={cn(
              "w-full inline-flex flex-nowrap overflow-hidden",
              className,
            )}
          >
            <ul
              className={cn(
                "flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none max-w-[unset] animate-infinite-scroll",
                slideClassName,
              )}
            >
              {items.map((item, index) => {
                return (
                  <li className="!ml-5 !mr-[15px]" key={index}>
                    {item}
                  </li>
                );
              })}
            </ul>
            <ul
              className={cn(
                "flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none max-w-[unset] animate-infinite-scroll",
                slideClassName,
              )}
            >
              {items.map((item, index) => {
                return (
                  <li className="!ml-5 !mr-[15px]" key={index}>
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : (
        <div className={cn(`carousel-${mode}`, className)}>
          <div className={cn(`carousel-slide-${mode}`, slideClassName)}>
            {items}
          </div>

          {/* this element using to fill the empty spacing */}
          <div className={cn(`carousel-slide-${mode}`, slideClassName)}>
            {items}
          </div>
        </div>
      )}
    </>
  );
};
