import { useMemo } from "react";
import ct from "countries-and-timezones";

export const useCurrentCountryCode = () => {
  const countryCode = useMemo(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // native JS
    return ct.getTimezone(timeZone)?.countries?.[0];
  }, []);

  return countryCode;
};
