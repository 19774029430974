/**
 * Formats a number into a specified currency format.
 *
 * @param amount - The amount of money to format.
 * @param locale - The locale string, e.g., 'en-US'.
 * @param currency - The currency code, e.g., 'USD'.
 * @param options - Additional formatting options.
 * @returns The formatted currency string.
 */
export function formatCurrency(
  amount: number,
  locale: string = "en-US",
  currency: string = "USD",
  options?: Intl.NumberFormatOptions,
): string {
  const defaultOptions: Intl.NumberFormatOptions = {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const formatOptions = { ...defaultOptions, ...options };
  return new Intl.NumberFormat(locale, formatOptions).format(amount);
}

// Usage examples
// console.log(formatCurrency(1234.56)); // "$1,234.56"
// console.log(formatCurrency(1234.56, "en-GB", "GBP")); // "£1,234.56"
// console.log(formatCurrency(1234.56, "de-DE", "EUR")); // "1.234,56 €"
