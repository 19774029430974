export const DiamondIcon = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0746 0.75H5.92543C5.60918 0.75 5.31493 0.913167 5.14718 1.18083L1.97918 6.25H18.0208L14.8528 1.18083C14.6851 0.913167 14.3908 0.75 14.0746 0.75ZM9.32168 17.4205C9.68559 17.8211 10.3144 17.8211 10.6783 17.4205L19.1667 8.08333H0.833344L9.32168 17.4205Z"
        fill="white"
      />
    </svg>
  );
};
