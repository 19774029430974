import { ComponentPropsWithoutRef } from "react";
import { cn } from "../../utils/utils";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { decodeValue } from "../../libs/common";

export interface SaleOffProps extends ComponentPropsWithoutRef<"p"> {}

const SaleOff = ({ className, ...props }: SaleOffProps) => {
  const salesExpire = Cookies.get("_seefg")
    ? decodeValue(Cookies.get("_seefg")!)
    : "";

  const isFinishQuiz = Cookies.get("_vrfq")
    ? decodeValue(Cookies.get("_vrfq")!)
    : "";

  console.log("isFinishQuiz", isFinishQuiz);
  return (
    <p
      {...props}
      className={cn(
        "flex w-full justify-center text-base font-semibold md:text-xl",
        className,
      )}
    >
      Only $
      {(salesExpire === "0" && isFinishQuiz?.length > 0) || salesExpire === "0"
        ? `3.00`
        : `7.00`}
      &nbsp;
      <span className="text-[#8E8E8E] line-through">$39.00</span>
      &nbsp;
      {(salesExpire === "0" && isFinishQuiz?.length > 0) ||
      (salesExpire === "0" && isFinishQuiz?.length === 0)
        ? `as of ${dayjs().format("MMM D")}`
        : `before results expire`}
    </p>
  );
};

export default SaleOff;
