const ArrowLeftIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.293 6.29297L7.586 12L13.293 17.707L14.707 16.293L10.414 12L14.707 7.70697L13.293 6.29297Z"
        fill="#484848"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
