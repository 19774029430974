import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Flex,
    Box,
    Text,
  } from "@chakra-ui/react";
  import ThreeDotsIcon from "../../ThreeDotsIcon";
  import { useNavigate } from "react-router-dom";
  import { membershipCourse, ROUTES } from "../../../constants";
  import { SectionProps } from "../types/MembershipCourses.type";
  import { useIsMobile } from "../../../hooks/useIsMobile";
  
  type LeftPanelProps = {
    courseContent: SectionProps[];
    courseName: (typeof membershipCourse)[keyof typeof membershipCourse];
    activeSectionIndex: number;
    activeContentIndex: number;
  };
  
  const LeftPanel = ({
    courseContent,
    courseName,
    activeSectionIndex,
    activeContentIndex,
  }: LeftPanelProps) => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();
  
    const handleContentClick = (sectionIndex: number, contentIndex: number) => {
      navigate(
        `${ROUTES.BLUEPRINT}?${courseName}&sectionIndex=${sectionIndex}&contentIndex=${contentIndex}`,
      );
    };
  
    return (
      <div className="w-full md:w-1/3">
        <Box
          background="#fff"
          borderBottomRightRadius="20px"
          borderBottom={{
            base: "none",
            md: "1px solid #EAECF0",
          }}
          borderRight={{
            base: "none",
            md: "1px solid #EAECF0",
          }}
          p={{
            base: "7px 20px 24px 20px",
            md: "20px 5px",
          }}
          className="md:block"
        >
          <Accordion
            allowToggle
            borderColor="#fff"
            p="0"
            defaultIndex={[activeSectionIndex]}
          >
            {courseContent.map((section, sectionIndex) => {
              return (
                <AccordionItem key={sectionIndex} mt="24px">
                  <h2>
                    <AccordionButton
                      py="0"
                      pl={{
                        base: 0,
                        md: "25px",
                      }}
                      pr={{
                        base: 0,
                        md: "15px",
                      }}
                      _hover={{ background: "transparent" }}
                    >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontSize="18px"
                        fontWeight="900"
                        lineHeight="22.77px"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                      >
                        {section.section}
                      </Box>
                      <AccordionIcon
                        boxSize={{
                          base: "24px",
                          md: "20px",
                        }}
                      />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel p="0" mt="20px">
                    {section.content.map((content, contentIndex) => {
                      const isContentActive =
                        activeSectionIndex === sectionIndex &&
                        activeContentIndex === contentIndex;
                      return (
                        <Flex
                          key={content.title}
                          as="button"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                          background={isContentActive ? "#FFFAEB" : "transparent"}
                          _focus={{ background: "#FFFAEB" }}
                          border="none"
                          textAlign="left"
                          fontSize="14px"
                          lineHeight="17.31px"
                          fontWeight={isContentActive ? 700 : 500}
                          color={isContentActive ? "#000" : "#888888"}
                          p={{
                            base: isContentActive ? "8px 16px" : "8px 25px",
                            md: "8px 15px 8px 25px",
                          }}
                          borderRadius="9px"
                          cursor="pointer"
                          onClick={() =>
                            handleContentClick(sectionIndex, contentIndex)
                          }
                        >
                          <Text
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            {content.title}
                          </Text>
                          {isContentActive && !isMobile && <ThreeDotsIcon />}
                        </Flex>
                      );
                    })}
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </Box>
      </div>
    );
  };
  
  export default LeftPanel;