import { Image } from "@chakra-ui/react";
import Photo from "../assets/images/photo.webp";
import SaleDog from "../assets/images/sale-dog.webp";
import SalesGroup2 from "../assets/images/sales_group2.webp";
import SalesReport from "../assets/images/sales_report.png";
import SalesAlgo from "../assets/images/salesAlgo.webp";
import SalesAlgo2 from "../assets/images/salesAlgo2.webp";
import SalesRv from "../assets/images/salesrv.png";
import SalesYt from "../assets/images/salesYt.png";
import CountDown from "../components/CountDown";
import { useSalesPrice } from "../components/CountDown/useSales";
import { useSalesExpired } from "../components/CountDown/useSalesExpired";
import SalesButton from "../components/SalesButton";
import SalesCard from "../components/SalesCard";
import SalesFAQ from "../components/SalesFAQ";
import EverythingYouNeed from "../components/SalesPageComponents/EverythingYouNeed";
import GetInstantAccess from "../components/SalesPageComponents/GetInstantAccess";
import ResultSpeak from "../components/SalesPageComponents/ResultSpeak";
import SpotFormSection from "../components/SalesPageComponents/SpotForm";
import StoryTelling from "../components/SalesPageComponents/StoryTelling";
import StoryTellingWrapImage from "../components/SalesPageComponents/StoryTellingWrapImage";
import Syllabus from "../components/SalesPageComponents/Syllabus";
import WannaInstantAccess from "../components/SalesPageComponents/WannaInstantAccess";
import Footer from "../components/shared/Footer";
import StoryTellingGroup from "../components/ui/StoryTelling/StoryTellingGroup";
import StorytellingParagraph from "../components/ui/StoryTelling/StoryTellingParagraph";
import { cn } from "../utils/utils";

const SalesPage = () => {
  const isSalesExpired = useSalesExpired();
  const salePrice = useSalesPrice();

  return (
    <>
      <CountDown className="sticky top-0 flex h-[54px] items-center justify-center py-0 md:py-0" />

      <GetInstantAccess />

      <StoryTelling className="mb-15 md:mb-20">
        {/* For dynamic data from api, you can use "html" props */}
        <StorytellingParagraph
          beforeTitle={
            <Image
              src={SaleDog}
              className={cn(
                "h-[164px] w-[202px] md:h-[258px] md:w-[318px]",
                "mx-auto mb-[18px] -translate-y-6 -rotate-[8deg] transform rounded-2.5xl md:mb-[67px] md:-translate-y-3",
              )}
              style={{
                boxShadow: "0px 24px 42px 0px rgba(0, 0, 0, 0.13)",
              }}
            />
          }
          title="Five years ago, I was just like you…"
        >
          <p>
            I was just a regular student, went to college, and eventually
            dropped out…
          </p>
          <p>Then I got stuck in a 9-5 job...</p>
          <p>I had no free time</p>
          <p>And I was desperately looking for ways to make more money</p>
          <p>
            Back then, I had no idea how much money you could make from
            YouTube...
          </p>
          <p>I had never created a successful YouTube channel before...</p>
          <p>
            But I was determined, so I pushed through and cracked the code in
            just a few months.
          </p>
        </StorytellingParagraph>

        <StorytellingParagraph
          title="The reason I could make a full-time income from YouTube in months is because..."
          titleProps={{ className: "lg:max-w-[648px]" }}
          extensions={
            <SalesCard
              variant="subscriber"
              className={cn("mx-auto mt-7.5 w-full max-w-[920px] md:mt-10")}
            />
          }
        >
          <p>
            <mark>
              YouTube has been paying its creators $63 million daily for the
              last three years…
            </mark>
          </p>
          <p>
            <mark>
              And everyone on YouTube right now can get a piece of the pie.
            </mark>
          </p>
          <p>Mr. Beast makes over $50 million a year from YouTube…</p>
          <p>Guys from Dude Perfect make over $20 million a year…</p>
          <p>
            And Ryan, who’s just a 12 year old kid, pulls in $32 million a year!
          </p>
        </StorytellingParagraph>

        <StoryTellingGroup>
          <StorytellingParagraph
            title="These are the top players. But in total it's only about 0.5% of what YouTube has actually paid out…"
            titleProps={{
              className: "max-w-full md:max-w-[752px] mb-[25px] md:mb-10",
            }}
          >
            <p>
              <mark>
                The remaining 99.5% go to people like me, running
                behind-the-scenes faceless channels.
              </mark>
            </p>
            <p>
              I mean, look at the graph that shows YouTube's growth over the
              last 13 years…
            </p>
          </StorytellingParagraph>

          <StorytellingParagraph
            extensions={
              <Image
                src={SalesReport}
                alt=""
                loading="lazy"
                className="mx-auto flex w-full max-w-[345px] md:max-w-[920px]"
              />
            }
          />

          <StorytellingParagraph>
            <p>It's been steadily growing every single year…</p>
            <p>…And as it grows further, your income will grow with it.</p>
            <p>Just like mine has with the 3 channels I own:</p>
          </StorytellingParagraph>

          <StorytellingParagraph
            extensions={
              <SalesCard className="mx-auto w-full md:max-w-[920px]" />
            }
          />

          <Image
            src={Photo}
            className="z-10 mx-auto hidden w-full max-w-[1192px] md:flex"
            loading="lazy"
          />

          <StorytellingParagraph
            className="flex md:hidden"
            extensions={
              <Image
                src={SalesGroup2}
                loading="lazy"
                className="w-screen -translate-x-1 transform items-center justify-center"
              />
            }
          />

          <StorytellingParagraph>
            <p>
              Now, I get to buy whatever I want, travel the world, and be my own
              boss…
            </p>
            <p>All without ever working a 9-5 again.</p>
            <p>
              And that’s exactly what I help people like you to achieve every
              single day.
            </p>
            <p>
              The truth is that YouTube gave me the key to financial freedom.
            </p>
          </StorytellingParagraph>
        </StoryTellingGroup>

        <StorytellingParagraph
          title="It's is the world's biggest video platform…"
          titleProps={{ className: "max-w-full md:max-w-[523px]" }}
        >
          <p>
            And more people open YouTube daily than Facebook, Instagram, or
            TikTok…
          </p>

          <Image
            className="my-[15px] w-full md:my-5"
            src={SalesYt}
            alt="Youtube"
            loading="lazy"
          />

          <p>
            But here’s the thing: even though it may{" "}
            <span className="hidden md:inline">
              seem like there are a lot of YouTube <br className="sm:hidden" />{" "}
              channels
            </span>{" "}
            <mark className="inline md:hidden">
              seem like there are a lot of YouTube <br className="sm:hidden" />{" "}
              channels
            </mark>{" "}
            already…
          </p>

          <p>YouTube actually has a desperate need for new channels.</p>

          <p>
            <mark>
              That’s why YouTube is promoting and boosting new channels now more
              than ever before!
            </mark>
          </p>

          <p>
            And they’re willing to pay top dollar for{" "}
            <br className="sm:hidden" /> high-quality channels and content.
          </p>
        </StorytellingParagraph>

        <StorytellingParagraph
          title={
            <>
              And that's where you <br className="md:hidden" /> come in.
            </>
          }
          titleProps={{ className: "md:max-w-[628px]" }}
        >
          <p>
            See, contrary to popular belief, you don’t need hundreds of millions
            of views like Mr. Beast or Dude Perfect…
          </p>
          <p>
            Or to show your face, use your voice, or{" "}
            <br className="sm:hidden" /> even create the videos yourself…
          </p>
          <p>
            But of course, you can if you want to; it’s{" "}
            <br className="sm:hidden" /> up to you.
          </p>
          <p>
            But <i>if</i> your goal is to chill and just have enough money to do
            whatever you want…
          </p>
          <p>
            Then the{" "}
            <mark>
              only thing you need is the right channel idea that people will
              watch over <br className="sm:hidden" /> and over again.
            </mark>
          </p>
          <p>
            Once you have the right channel idea and the right system,
            everything else falls into place.
          </p>
          <p>
            Everything becomes easy. Even things like creating a video you can
            get paid for...
          </p>
          <p>Or figuring out what to say in that video.</p>
          <p>
            All of that will fall into place <i>if</i> you nail the channel
            idea.
          </p>
          <p>
            Think of the channel idea as your <br className="sm:hidden" />{" "}
            “direction”.
          </p>
          <p>
            Now, finding the right channel idea <br className="sm:hidden" />{" "}
            sounds simple enough…
          </p>
        </StorytellingParagraph>

        <StoryTellingGroup>
          <StorytellingParagraph
            title={
              <>
                But this is actually where <br className="md:hidden" /> all
                beginners mess up…
              </>
            }
            titleProps={{ className: "md:max-w-[521px]" }}
          >
            <p>
              See, if finding the right channel idea was intuitive, then
              everyone would have a profitable channel…
            </p>
            <p>But they don't.</p>
            <p>
              Thankfully, after launching 3 successful channels from scratch…
            </p>
            <p>
              Spending over half a million dollars testing to see what actually
              works…
            </p>
            <p>Being known for “breaking the YouTube algorithm</p>
          </StorytellingParagraph>

          <StoryTellingGroup>
            <StoryTellingWrapImage src={SalesAlgo} className="text-center" />

            <StorytellingParagraph>
              <p>
                …And going behind the scenes of the biggest channels in the
                world…
              </p>
            </StorytellingParagraph>

            <StoryTellingWrapImage src={SalesAlgo2} className="text-center" />

            <StorytellingParagraph>
              <p>
                <mark>
                  I discovered the formula for choosing the right channel idea.
                </mark>
              </p>
              <p>
                <mark>
                  It’s easy, repeatable, and works every{" "}
                  <br className="sm:hidden" /> single time.
                </mark>
              </p>
              <p>
                Unfortunately, it's not something I can explain in 5 minutes…
              </p>
            </StorytellingParagraph>
          </StoryTellingGroup>
        </StoryTellingGroup>

        <StoryTellingGroup>
          <StorytellingParagraph
            title="And the devil is in the details…"
            titleProps={{ className: "md:max-w-[700px]" }}
          >
            <p>
              So that’s why I created the Faceless <br className="sm:hidden" />{" "}
              Income Fast-Start…
            </p>
            <p>
              It will show you how to use the exact same{" "}
              <br className="sm:hidden" /> same system as I do to run all my{" "}
              <br className="sm:hidden" /> profitable faceless channels…
            </p>
            <p>That generate a stable income for me each and every month.</p>
            <p>All without making the videos myself,</p>
            <p>Without showing my face,</p>
            <p>Or even being on camera.</p>
            <p>
              Using this exact system, I was able to{" "}
              <br className="sm:hidden" /> make $429,000 last year from{" "}
              <i>just</i> YouTube ads.
            </p>
            <p>
              And this is not even including my other income streams from these
              videos, like sponsors, which would bring that figure to{" "}
              <mark>well over $1m</mark>.
            </p>
          </StorytellingParagraph>

          <StoryTellingWrapImage
            variant="dark"
            src={SalesRv}
            className="text-center"
          />

          <StorytellingParagraph>
            <p>
              <mark>
                This system allows me to get paid while I sleep, travel, and
                truly enjoy life…
              </mark>
            </p>
            <p>
              <mark>
                Without ever having to wake up to a job I hate just to “afford
                life.”
              </mark>
            </p>
            <p>
              It’s the same system that could give you{" "}
              <br className="sm:hidden" /> the life you want…
            </p>
            <p>
              And the same system you could be using in just a few minutes
              because I’m giving <br className="sm:hidden" /> you{" "}
              <b>everything.</b>
            </p>
          </StorytellingParagraph>

          <StorytellingParagraph>
            <SalesButton />
          </StorytellingParagraph>
        </StoryTellingGroup>

        <StoryTellingGroup>
          <StorytellingParagraph
            title={
              <>
                I took everything from my 5 years of experience on YouTube and
                turned it into <br className="md:hidden" /> a 5 Day Fast-Start…
              </>
            }
            titleProps={{ className: "md:max-w-[701px]" }}
          >
            <p>
              It's a 5-day step-by-step guide that walks you through each step
              and gets you going.
            </p>
            <p>
              But most importantly, it will get you going{" "}
              <br className="sm:hidden" /> in the right direction.
            </p>
            <p>
              Once you're inside, you’ll discover the{" "}
              <br className="sm:hidden" /> secret to how to find your channel
              idea…
            </p>
            <p>How to outsource the video creation process…</p>
            <p>How to “hack” the algorithm for views…</p>
            <p>How to scale your income…</p>
            <p>And much more.</p>
            <p>I didn’t hold anything back.</p>
            <p>
              I literally turned 5 years of my experience into 5 days for you…
            </p>
            <p>So you can stop dreaming and start living.</p>
          </StorytellingParagraph>

          <Syllabus className="mx-auto md:max-w-[988px]" />

          <StorytellingParagraph
            title="The results speak for themselves…"
            titleProps={{ className: "md:max-w-[381px]" }}
            className="-mt-8"
          />

          <div>
            <ResultSpeak className="mx-auto mb-4 md:max-w-[1060px]" />

            <StorytellingParagraph
              title={
                <>
                  Here's why I'm giving everything away for{" "}
                  <mark className="pl-2">only ${salePrice}</mark>
                </>
              }
              titleProps={{ className: "md:max-w-[701px]" }}
            >
              <p>
                The reason you're getting this bargain is because I’m not doing
                it for the money…
              </p>
              <p>As you saw, YouTube pays my bills more than generously.</p>
              <p>So that’s not changing.</p>
              <p>The real reason is my mission.</p>
              <p>
                <b>
                  My mission is to help over 500 people create successful
                  faceless channels this year and achieve their goals…
                </b>
              </p>
              <p>
                Whether it’s replacing their jobs, retiring their parents, or
                just traveling around the world.
              </p>
              <p>
                You’ll find yourself living the dream, not{" "}
                <br className="sm:hidden" /> just chasing it…
              </p>
              <p>
                Once you use everything you'll get in{" "}
                <br className="sm:hidden" /> these 5 days...
              </p>
              <p>
                Being able to buy whatever you want without looking at the price
                tag…
              </p>
              <p>
                Providing for your family without ever{" "}
                <br className="sm:hidden" /> feeling the pinch, and not just
                scraping by but truly living on your terms.
              </p>
              <p>
                Or even traveling, walking on the beach, feeling the warm sand
                underneath your <br className="sm:hidden" /> feet as you sip
                pinacoladas with your <br className="sm:hidden" /> friends.
              </p>
            </StorytellingParagraph>
          </div>

          <SalesButton />
        </StoryTellingGroup>

        <StoryTellingGroup id="everythingyouget">
          <StorytellingParagraph
            title={
              <>
                Here's everything you <br className="md:hidden" /> will get:
              </>
            }
            titleProps={{
              className: cn(
                "md:max-w-[633px]",
                isSalesExpired
                  ? "scroll-mt-[8px]"
                  : "scroll-mt-[calc(54px+9px)]",
              ),
            }}
          />
          <EverythingYouNeed className="mx-auto max-w-[771px]" />
        </StoryTellingGroup>

        <SpotFormSection
          id="CHECKOUT"
          className="w-full max-w-[1200px] sm:mx-auto"
        />

        <StorytellingParagraph
          title={
            <>
              Try the Faceless Income <br className="sm:hidden" /> Fast-Start
              with <br className="hidden lg:block" /> The 365-
              <br className="sm:hidden" />
              Day Money-Back <br className="sm:hidden" /> Guarantee!
            </>
          }
          titleProps={{
            className:
              "lg:max-w-[819px] before:left-[20px] before:top-[45px] after:top-[45px] after:right-[20px]",
            ribbon: true,
          }}
          className="mb-5"
        >
          <p>Here’s the deal:</p>
          <p>
            I’m so confident that you’ll see the value in the Faceless Income
            Fast-Start that I’m giving you my{" "}
            <b>
              365-day, no-questions
              <br className="sm:hidden" />
              -asked guarantee.
            </b>
          </p>
          <p>
            Yeah, you read that right – If you feel like you haven’t gotten your
            money’s worth, just shoot me an email any day of the year... Heck,
            even if it’s day 364.
          </p>
          <p>You’ll get a full refund, no questions asked.</p>
          <p>
            You could literally watch everything, implement it, make money, keep
            the <br className="sm:hidden" /> money, and still get your money
            back if you’re not satisfied.
          </p>
          <SalesButton className="mt-5" />
        </StorytellingParagraph>
      </StoryTelling>

      <StoryTellingGroup className="bg-lightPink px-6 py-12.5 md:px-0 md:pb-7.5 md:pt-15">
        <StorytellingParagraph
          title="Are you trying to look for more answers?"
          titleProps={{ className: "md:max-w-[519px]" }}
        >
          <SalesFAQ allowToggle defaultIndex={[0]} />
        </StorytellingParagraph>
      </StoryTellingGroup>

      <Footer className="mb-[95px] md:mb-0" />

      <WannaInstantAccess />
    </>
  );
};

export default SalesPage;
