const ArrowDownIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9968 15.498L16.9999 22.4964L10.003 15.498L6.99683 18.5042L16.9999 28.5059L27.003 18.5042L23.9968 15.498Z"
        fill="#484848"
      />
      <path
        d="M23.9968 5.58105L16.9999 12.5794L10.003 5.58105L6.99683 8.58722L16.9999 18.5889L27.003 8.58722L23.9968 5.58105Z"
        fill="#484848"
      />
    </svg>
  );
};

export default ArrowDownIcon;
