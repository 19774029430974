const CheckIcon = () => {
  return (
    <svg
      style={{
        minWidth: "20",
        minHeight: "21"
      }}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99996 2.16602C5.40496 2.16602 1.66663 5.90435 1.66663 10.4993C1.66663 15.0943 5.40496 18.8327 9.99996 18.8327C14.595 18.8327 18.3333 15.0943 18.3333 10.4993C18.3333 5.90435 14.595 2.16602 9.99996 2.16602ZM8.33413 14.1768L5.23996 11.0893L6.41663 9.90935L8.33246 11.8218L12.7441 7.41018L13.9225 8.58852L8.33413 14.1768Z"
        fill="#1570EF"
      />
    </svg>
  );
};

export default CheckIcon;
