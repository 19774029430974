import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import Payment from "payment";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function convertNewlinesToBreaks(text: string) {
  return text.replace(/\n/g, "<br>");
}

export const checkIOS = () => {
  const userAgent = window.navigator.userAgent;
  const isIOS = /iPad|iPhone|iPod/.test(userAgent);
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

  return isIOS || isSafari;
};

export const clearNumber = (value = "") => {
  return value.replace(/\D+/g, "");
};

export function formatCreditCardNumber(value: string) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8,
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export const formatCVC = (value: string) => {
  const clearValue = clearNumber(value);
  const maxLength = 4;

  return clearValue.slice(0, maxLength);
};

export const formatExpirationDate = (
  value: string,
  validCallBack: () => void,
  errorCallback: () => void,
) => {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    const month = Number(clearValue.slice(0, 2));
    const year = Number(clearValue.slice(2, 4));

    const currentYear = new Date().getFullYear() % 100;
    const currentMonth = new Date().getMonth() + 1;

    if (
      month > 12 ||
      year < currentYear ||
      (year === currentYear && month < currentMonth)
    ) {
      errorCallback();
    } else {
      validCallBack();
    }

    return `${clearValue.slice(0, 2)} / ${year}`;
  }

  return clearValue;
};

export const setLocalStorage = (key: string, value: unknown) => {
  try {
    if (!value) {
      localStorage.removeItem(key);
      return;
    }

    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    localStorage.setItem(key, "");
  }
};

export const getLocalStorage = (key: string) => {
  try {
    const value = localStorage.getItem(key);
    if (value) return JSON.parse(value);

    return null;
  } catch (error) {
    return null;
  }
};

export const setSessionStorage = (key: string, value: unknown) => {
  try {
    if (!value) {
      sessionStorage.removeItem(key);
      return;
    }

    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    sessionStorage.setItem(key, "");
  }
};
