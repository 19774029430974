const ArrowRight18 = () => {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.75 8.24998H4.81045L8.7802 4.28023L7.7197 3.21973L1.93945 8.99998L7.7197 14.7802L8.7802 13.7197L4.81045 9.74998H15.75V8.24998Z"
          fill="#484848"
        />
      </svg>
    );
  };
  
  export default ArrowRight18;