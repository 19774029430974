import { ReactNode } from "react";
import { cn } from "../../../utils/utils";

export const GraySection = ({
  label,
  icon,
  children,
  className,
}: {
  label?: string;
  icon?: ReactNode;
  children?: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        "rounded-[20px] border border-[#E7E7E7] bg-[#F9FAFB] p-[15px] md:p-[25px]",
        className,
      )}
    >
      {label && (
        <div className="flex items-center gap-3 border-b border-[#E7E7E7] pb-[10px]">
          {icon}
          <span className="text-18 font-bold">{label}</span>
        </div>
      )}
      {children}
    </div>
  );
};
