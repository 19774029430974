import { DoubleArrowRight } from "../assets/icons";
import FastStartLinkSection from "../components/FastStartLinkSection";
import Footer from "../components/shared/Footer";

export const NotFoundPage = () => {
  return (
    <>
      <FastStartLinkSection />
      <div className="h-[100vh] relative">
        <div className="h-full text-[130px] md:text-[400px] flex justify-center items-center">
          <svg
            width="2.4em"
            height="1em"
            viewBox="0 0 975 415"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M312.973 331.077H268.503V409.126H160.682V331.077H0V251.35L167.814 5.875H268.503V249.672H312.973V331.077ZM160.682 249.672V202.675C160.682 193.863 161.102 182.114 161.521 167.007C162.36 151.901 162.78 144.348 163.199 143.509H160.262C153.969 157.776 146.837 170.784 138.866 183.372L94.3954 249.672H160.682Z"
              fill="#FFEEEE"
            />
            <path
              d="M632.659 208.129C632.659 279.464 620.493 331.916 596.999 365.066C573.085 398.215 536.586 415 487.08 415C438.834 415 402.754 397.796 378.001 362.968C353.668 328.14 341.502 276.527 341.502 208.129C341.502 136.375 353.249 83.9231 377.582 50.3539C401.495 16.7846 437.995 0 487.5 0C535.327 0 571.827 17.6239 596.16 52.452C620.493 87.2801 632.659 138.893 632.659 208.129ZM449.742 208.129C449.742 253.868 452.679 285.339 458.133 302.123C464.006 319.328 473.236 327.72 486.661 327.72C500.086 327.72 509.735 318.908 515.609 301.284C521.063 283.66 524 252.609 524 208.129C524 163.231 521.063 132.179 515.609 114.135C509.735 96.092 500.506 87.2801 487.08 87.2801C473.655 87.2801 464.006 96.092 458.552 113.296C452.679 130.501 449.742 161.972 449.742 208.129Z"
              fill="#FFEEEE"
            />
            <path
              d="M975 331.077H930.529V409.126H822.709V331.077H662.027V251.35L829.841 5.875H930.529V249.672H975V331.077ZM823.128 249.672V202.675C823.128 193.863 823.548 182.114 823.967 167.007C824.806 151.901 825.226 144.348 825.645 143.509H822.709C816.416 157.776 809.284 170.784 801.312 183.372L756.422 250.091H823.128V249.672Z"
              fill="#FFEEEE"
            />
          </svg>
        </div>
        <a
          href="/home"
          className="bg-[#D92D20] p-[18px] md:p-5 md:px-10 md:text-center absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 flex gap-2 md:gap-[10px] rounded-[15px] text-white text-16 md:text-24 font-bold items-center text-nowrap"
        >
          RETURN TO HOME <DoubleArrowRight />
        </a>
      </div>
      <Footer />
    </>
  );
};
