import { Box, Flex } from "@chakra-ui/react";
import product1 from "../assets/images/product-1.png";
import product2 from "../assets/images/product-2.png";

import day1 from "../assets/images/membership/Day1.png";
import day2 from "../assets/images/membership/Day2.png";
import day3 from "../assets/images/membership/Day3.png";
import day4 from "../assets/images/membership/Day4.png";
import day5 from "../assets/images/membership/Day5.png";
import day6 from "../assets/images/membership/Day6.png";

import calculator from "../assets/images/calculator.png";
import lightbulb from "../assets/images/lightbulb.png";
import ordering from "../assets/images/ordering.png";
import warning from "../assets/images/warning.png";

import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/shared/Footer";

import dayjs from "dayjs";
import BookCallPopUp from "../components/BookCallPopUp";
import FastStartMainVideo from "../components/FastStartPortal/FastStartMainVideo";
import FastStartProducts from "../components/FastStartPortal/FastStartProducts";
import FastStartVideos from "../components/FastStartPortal/FastStartVideos";
import SuccessStory from "../components/FastStartPortal/SuccessStory";
import NavbarProfile from "../components/NavbarProfile";
import ViralProfitsLogo from "../components/ViralProfitsLogo";
import { ROUTES } from "../constants";
import { useIsMobile } from "../hooks/useIsMobile";
import { useAuth } from "../providers/AuthProvider";

export type FastStartProductType = {
  imageUrl: string;
  title: string;
  description: string;
  buttonClassName?: string;
  badge: string;
  button?: JSX.Element;
  secondButton?: JSX.Element;
};

export type VideoType = {
  imageUrl: string;
  title: string;
  videoId: string;
};

type ProductButtonProps = {
  text: string;
  disabled?: boolean;
  onClick?: () => void;
};
const ProductButton = ({ text, disabled, onClick }: ProductButtonProps) => {
  return (
    <button
      className={`'md-min-h-[49px] :disabled:bg-gray-300 min-h-[44px] whitespace-normal rounded-[14px] bg-[#f2f4f7] px-6 py-3 !text-16 font-medium !leading-[19.78px] transition-all duration-300 ease-linear hover:bg-gray-300 lg:min-w-min lg:!text-20 lg:!leading-[24.73px] xl:whitespace-nowrap ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
      onClick={() => {
        if (!disabled) onClick?.();
      }}
    >
      {text}
    </button>
  );
};

const MemberShipPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const isMobile = useIsMobile();

  const {
    isPurchased7FigureLaunchPad = false,
    isPurchased7FigureBluePrint = false,
    isPurchasedEndlessVideoIdea = false,
  } = user || {};

  const [activeVideoIndex, setActiveVideoIndex] = useState(-1);

  const getPurchasedStatus = (status: boolean) => {
    if (status) {
      return "Purchased";
    }
    return "Not Purchased";
  };

  const getMenuParam = () => {
    if (isMobile) {
      return "&menu=1";
    }

    return "";
  };

  const purchaseProducts: FastStartProductType[] = [
    {
      imageUrl: product1,
      title: "Endless Video Idea System",
      description:
        "Jake's endless video idea system is the ultimate shortcut to finding new, unique and proven video ideas for any faceless channel",
      buttonClassName: "!px-1 min-[811px]:!py-3 min-[811px]:!px-6",
      badge: getPurchasedStatus(isPurchasedEndlessVideoIdea),
      button: (
        <ProductButton
          text="Find Proven Video Ideas In Minutes"
          disabled={!isPurchasedEndlessVideoIdea}
          onClick={() =>
            navigate(`${ROUTES.BLUEPRINT}?course=EndlessIdea${getMenuParam()}`)
          }
        />
      ),
    },
    {
      imageUrl: product2,
      title: "7-Figure Launchpad",
      description:
        "Skip the learning and get straight to earning: With the 7-Figure Launchpad, you get access to proven tools, scripts, templates, systems, and more, all refined over my 5 years on YouTube. It allows you to create viral videos on command, attract sponsors & mulitply your income. Over 91% of our students got their 7-Figure Launchpad because to skip months of trial end error.",
      badge:
        getPurchasedStatus(isPurchased7FigureLaunchPad) ||
        getPurchasedStatus(isPurchased7FigureBluePrint),
      button: (
        <ProductButton
          text={!isPurchased7FigureBluePrint && !isPurchased7FigureLaunchPad
            ? "Get Views On Demand, Attract Sponsors, Brand Deals And Multiply Your Income"
            : "Get Views On Demand"}
          onClick={() => !isPurchased7FigureLaunchPad ? navigate(`${ROUTES.UPSELL1}`) :
            navigate(
              `${ROUTES.BLUEPRINT}?course=7FigureLaunchpad${getMenuParam()}`,
            )
          }
        />
      ),
      secondButton: isPurchased7FigureBluePrint ? (
        <ProductButton
          text="Attract Sponsors, Brand Deals And Multiply Your Income"
          onClick={() => {
            navigate(
              `${ROUTES.BLUEPRINT}?course=7FigureBlueprint${getMenuParam()}`,
            );
          }}
        />
      ) : undefined,
    },
  ];

  const freeBonuses: FastStartProductType[] = [
    {
      imageUrl: lightbulb,
      title: "100+ CHANNEL IDEAS",
      description:
        "Jake’s personal list of the untapped channel ideas that he’s eyeballing right now. If Jake was to start over today, these would be the profitable channel ideas he would start because NO ONE is doing them.",
      badge: "Free Bonus",
      button: (
        <ProductButton
          text="Download 100+  CHANNEL IDEAS"
          onClick={() => {
            window.open(
              "https://drive.google.com/file/d/1UuyXKeQj97xVmGWzfz2mFIcr4c1DMjA7",
            );
          }}
        />
      ),
    },
    {
      imageUrl: calculator,
      title: "CHANNEL PROFIT CALCULATOR",
      description:
        "This plug-and play channel profit calculator helps you predict how your channel will perform based on data from Jake’s channels.",
      badge: "Free Bonus",
      button: <ProductButton text="Use Calculator" onClick={() => {}} />,
    },
    {
      imageUrl: ordering,
      title: "CHANNEL LAUNCH CHECKLIST",
      description:
        "Make sure you don’t miss any crucial elements from this 10+ step checklist (applies to every channel, regardless of niche), and don’t leave any money on the table.",
      badge: "Free Bonus",
      button: (
        <ProductButton
          text="Download Checklist"
          onClick={() => {
            window.open(
              "https://docs.google.com/document/d/1Mt0nQyelHxtE2gTckyKJaznBlI0cIzPYV0QxJjrq1KM",
            );
          }}
        />
      ),
    },
    {
      imageUrl: warning,
      title: "YOUTUBE FLAG WORDS",
      description:
        "Few people are aware of them, but they do exist; avoid these niches and mentioning any of these words, as they could lead to YouTube stop sending you payouts.",
      badge: "Free Bonus",
      button: (
        <ProductButton
          text="Download Checklist"
          onClick={() => {
            window.open(
              "https://www.loom.com/share/ddf8fabaaee94864a820024d83f58604?sid=363667bd-1824-4f52-a978-af55f0609b33",
            );
          }}
        />
      ),
    },
  ];

  const videos: VideoType[] = useMemo(() => {
    return [
      {
        imageUrl: day1,
        title: "How I Make Over $1,000,000 Per Year With YouTube",
        videoId: "0uie5of0o8",
      },
      {
        imageUrl: day2,
        title: "Your Million-Dollar YouTube Channel",
        videoId: "nvw66ic4zr",
      },
      {
        imageUrl: day3,
        title: "Your Profit Factory",
        videoId: "8p6sh2n6bm",
      },
      {
        imageUrl: day4,
        title: "Getting Your First Paycheck: $0 to $10,000",
        videoId: "zk2rmd2o0i",
      },
      {
        imageUrl: day5,
        title: "From $10,000 to $100,000",
        videoId: "y6a9pjkzz2",
      },
      {
        imageUrl: day6,
        title: "Scaling Beyond 6-Figures",
        videoId: "rbxuhp7lql",
      },
    ];
  }, []);

  const { isOpen: isOpenBookCallPopUp, handleClose: handleCloseBookCallPopUp } =
    useBookCallPopup();

  return (
    <>
      <Flex
        as="nav"
        borderBottom="1px solid #EAECF0"
        height="60px"
        alignItems="center"
        justifyContent="center"
        gap="1rem"
      >
        <ViralProfitsLogo />
        <Box
          border="1px solid #d9d9d9"
          display="inline-block"
          height="29px"
          mx={{
            base: "15px",
            md: "18px",
          }}
        />
        <Box
          p="6px 10px"
          background="#FEF3F2"
          color="#D92D20"
          fontSize="14px"
          fontWeight="500"
          lineHeight="17.31px"
          height="29px"
          borderRadius="9px"
          me="13px"
        >
          Five day fast start
        </Box>
        <NavbarProfile />
      </Flex>

      <Box backgroundColor="#F9FAFB">
        <div className="px-6 md:px-10">
          <Box width="100%" maxWidth="1110px" margin="0 auto" pt="30px">
            <FastStartVideos
              videos={videos}
              activeVideoIndex={activeVideoIndex}
              setActiveVideoIndex={setActiveVideoIndex}
            />

            {activeVideoIndex === -1 ? (
              <FastStartMainVideo
                activeVideoIndex={-1}
                selectedVideo={Object({
                  title: "Thank you",
                  videoId: "cirtflugsy",
                })}
              />
            ) : (
              <FastStartMainVideo
                activeVideoIndex={activeVideoIndex}
                selectedVideo={videos[activeVideoIndex]}
              />
            )}

            <FastStartProducts
              headline="Your purchased products"
              products={purchaseProducts}
            />

            <FastStartProducts
              headline="Your free bonuses"
              products={freeBonuses}
            />

            <BookCallPopUp
              isOpen={isOpenBookCallPopUp}
              onClose={handleCloseBookCallPopUp}
            />
          </Box>
          <Box maxWidth="1300px" mx="auto">
            <SuccessStory />
          </Box>
        </div>
      </Box>
      <Footer />
    </>
  );
};

const useBookCallPopup = () => {
  const { user } = useAuth();

  const [isOpen, setIsOpen] = useState(false);

  const { getBookCallPopupStatus, setBookCallPopupStatus } =
    useBookCallPopupStorage();

  const watchedVideos =
    typeof user?.watchedVideos === "object"
      ? Object.values(user.watchedVideos)
      : user?.watchedVideos || [];

  const day1Video = watchedVideos?.find((video) => video.videoIndex === 0);
  const isWatchedDay1Video =
    day1Video?.completed &&
    dayjs(day1Video?.updatedAt).isValid() &&
    // Check if the day 1 video was watched and the last update was more than 24 hours ago
    dayjs(day1Video?.updatedAt).isBefore(dayjs().subtract(24, "hours"));

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    // condition: if user watched day 1 video and popup is not opened before, open the popup
    if (isWatchedDay1Video && getBookCallPopupStatus() !== "opened") {
      setIsOpen(true);
      setBookCallPopupStatus("opened");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWatchedDay1Video]);

  return {
    isOpen,
    handleClose,
  };
};

// TODO: move this to a database
const useBookCallPopupStorage = () => {
  const getBookCallPopupStatus = () => {
    return localStorage.getItem("book-call-popup");
  };

  const setBookCallPopupStatus = (status: "opened" | "closed") => {
    localStorage.setItem("book-call-popup", status);
  };

  return { getBookCallPopupStatus, setBookCallPopupStatus };
};

export default MemberShipPage;
