export const JCBIcon = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9016 12.544C19.9016 14.2538 18.5217 15.6461 16.827 15.6461H0.0985107V3.3599C0.0985107 1.65009 1.47843 0.257812 3.17307 0.257812H19.9016V12.544Z"
        fill="white"
      />
      <path
        d="M15.5804 1.29688C14.3699 1.29688 13.3773 2.28612 13.3773 3.51963V5.82787H16.4882C16.5608 5.82787 16.6456 5.82787 16.7061 5.84009C17.4082 5.87672 17.9287 6.24311 17.9287 6.87818C17.9287 7.37891 17.5776 7.80637 16.924 7.89186V7.91628C17.6381 7.96514 18.1829 8.36816 18.1829 8.99102C18.1829 9.66273 17.5776 10.1024 16.7787 10.1024H13.3652V14.6212H16.5972C17.8076 14.6212 18.8002 13.6319 18.8002 12.3984V1.29688H15.5804Z"
        fill="url(#paint0_linear_270_2055)"
      />
      <path
        d="M14.4547 9.39405H15.7257C15.7439 9.39405 15.7741 9.391 15.8044 9.38794C15.8346 9.38489 15.8649 9.38184 15.8831 9.38184C16.1252 9.33299 16.3309 9.11315 16.3309 8.80783C16.3309 8.51472 16.1252 8.29489 15.8831 8.23382C15.8468 8.22161 15.7741 8.22161 15.7257 8.22161H14.4547V9.39405Z"
        fill="url(#paint1_linear_270_2055)"
      />
      <path
        d="M15.7257 6.4996C15.9678 6.53624 16.1736 6.73164 16.1736 7.02475C16.1736 7.31786 15.9678 7.51327 15.7257 7.54991C15.7136 7.56212 15.641 7.56212 15.6047 7.56212H14.4547V6.48738H15.6047C15.6247 6.48738 15.652 6.49109 15.6765 6.49441C15.6966 6.49713 15.7148 6.4996 15.7257 6.4996Z"
        fill="url(#paint2_linear_270_2055)"
      />
      <path
        d="M3.40312 1.29688C2.19267 1.29688 1.20009 2.28612 1.20009 3.51963V9.00323C1.81743 9.30856 2.45897 9.50396 3.10051 9.50396C3.8631 9.50396 4.27465 9.03987 4.27465 8.4048V5.81566H6.16297V8.39259C6.16297 9.39405 5.54563 10.2123 3.45154 10.2123C2.18056 10.2123 1.18799 9.93142 1.18799 9.93142V14.609H4.41991C5.63036 14.609 6.62294 13.6197 6.62294 12.3862V1.29688H3.40312Z"
        fill="url(#paint3_linear_270_2055)"
      />
      <path
        d="M9.49173 1.29688C8.28127 1.29688 7.2887 2.28612 7.2887 3.51963V6.42631C7.84551 5.95 8.81387 5.64468 10.3754 5.71796C11.2106 5.75459 12.1063 5.98664 12.1063 5.98664V6.92704C11.6584 6.69499 11.1258 6.48737 10.4359 6.43852C9.24964 6.35303 8.53547 6.93925 8.53547 7.96514C8.53547 9.00323 9.24964 9.58946 10.4359 9.49175C11.1258 9.4429 11.6584 9.22307 12.1063 9.00323V9.94363C12.1063 9.94363 11.2227 10.1757 10.3754 10.2123C8.81387 10.2856 7.84551 9.98027 7.2887 9.50396V14.6334H10.5206C11.7311 14.6334 12.7236 13.6441 12.7236 12.4106V1.29688H9.49173Z"
        fill="url(#paint4_linear_270_2055)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_270_2055"
          x1="13.3753"
          y1="7.02322"
          x2="18.815"
          y2="7.02322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007940" />
          <stop offset="0.2285" stopColor="#00873F" />
          <stop offset="0.7433" stopColor="#40A737" />
          <stop offset="1" stopColor="#5CB531" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_270_2055"
          x1="13.3753"
          y1="7.02322"
          x2="18.815"
          y2="7.02322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007940" />
          <stop offset="0.2285" stopColor="#00873F" />
          <stop offset="0.7433" stopColor="#40A737" />
          <stop offset="1" stopColor="#5CB531" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_270_2055"
          x1="13.3753"
          y1="7.02322"
          x2="18.815"
          y2="7.02322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007940" />
          <stop offset="0.2285" stopColor="#00873F" />
          <stop offset="0.7433" stopColor="#40A737" />
          <stop offset="1" stopColor="#5CB531" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_270_2055"
          x1="1.19753"
          y1="7.95369"
          x2="6.72121"
          y2="7.95369"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F286F" />
          <stop offset="0.4751" stopColor="#004E94" />
          <stop offset="0.8261" stopColor="#0066B1" />
          <stop offset="1" stopColor="#006FBC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_270_2055"
          x1="7.2574"
          y1="7.95369"
          x2="12.6222"
          y2="7.95369"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C2C2F" />
          <stop offset="0.1735" stopColor="#882730" />
          <stop offset="0.5731" stopColor="#BE1833" />
          <stop offset="0.8585" stopColor="#DC0436" />
          <stop offset="1" stopColor="#E60039" />
        </linearGradient>
      </defs>
    </svg>
  );
};
