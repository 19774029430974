import { Image, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { ArrowToRight } from "../assets/icons";
import logoViralTop from "../assets/images/logo_viral_top.png";
import EmailDomainSuggestion from "../components/EmailDomainSuggestion";
import Footer from "../components/shared/Footer";

type FormData = {
  fullName: string;
  email: string;
};

export const WaitListPage = () => {
  const toast = useToast();
  const { handleSubmit, register, watch, setValue, reset } =
    useForm<FormData>();
  const [showEmailSuggestions, setShowEmailSuggestions] = useState(false);
  const emailWatch = watch("email");

  watch((data, { name }) => {
    setShowEmailSuggestions(
      name === "email" && !!data?.email && !data?.email?.includes("@"),
    );
  });

  const onSubmitForm = (data: unknown) => {
    console.log(data);
    toast({
      title: "Submit successfully",
      description: "We will contact you soon.",
      status: "success",
      duration: 4000,
      position: "top-right",
      isClosable: true,
    });
    reset();
  };

  const handleOnClickLogin = () => {
    window.location.href =
      "https://www.skool.com/faceless-income-accelerator-3767";
  };

  return (
    <>
      <div className="h-[100vh] min-h-[600px] bg-lightPink">
        <div className="ml-auto mr-auto w-full px-5 lg:max-w-[1100px] 2xl:max-w-[1280px]">
          <div className="flex items-center justify-between py-4">
            <Link
              to="/"
              className="w-fit font-Benzin_bold text-sm font-normal md:text-base"
            >
              <Image
                w="100%"
                maxW="118px"
                h="100%"
                maxH="20"
                src={logoViralTop}
                objectFit="contain"
              />
            </Link>
            <div
              className="flex w-fit cursor-pointer gap-1 rounded-lg bg-white px-[10px] py-1 text-xl shadow-btnBlackBackground md:px-3 md:py-2"
              onClick={handleOnClickLogin}
            >
              <span className="hidden text-sm font-bold md:inline">
                Log in as a member
              </span>
              <ArrowToRight />
            </div>
          </div>

          <div className="ml-auto mr-auto mt-8 w-fit rounded-full border border-[#E6E6E6] bg-white md:mt-28">
            <p className="text-smmd:text-lg px-[20px] py-[10px] font-bold">
              Thank you for your support
            </p>
          </div>

          <div className="ml-auto mr-auto mt-5 max-w-[277px] text-center text-4xl font-black leading-[48px] md:mt-8 md:max-w-full md:text-5xl md:leading-[60px]">
            ENROLLMENT IS CLOSED
          </div>

          <div className="ml-auto mr-auto mt-5 max-w-[349px] text-center text-[16px] font-normal leading-[20.24px] md:max-w-[680px] md:text-[22px] md:leading-[27.83px]">
            Please leave your email here so we can put you on the waiting list &
            you'll be the first to know when the doors open again.
          </div>

          <div className="ml-auto mr-auto mt-8 max-w-[500px]">
            <form
              onSubmit={handleSubmit(onSubmitForm)}
              className="flex flex-col gap-4"
            >
              <input
                placeholder="Full Name"
                className="max-h-[38px] rounded-xl border border-[#EAECF0] px-5 py-3 text-sm text-[#888888] md:max-h-[42px]"
                {...register("fullName")}
              />
              <input
                type="email"
                placeholder="Email Address"
                className="max-h-[38px] rounded-xl border border-[#EAECF0] px-5 py-3 text-sm text-[#888888] md:max-h-[42px]"
                onFocus={() => {
                  setShowEmailSuggestions(
                    !!emailWatch && !emailWatch?.includes("@"),
                  );
                }}
                {...register("email", { required: "Email is required" })}
              />

              <div className="relative">
                {showEmailSuggestions && (
                  <EmailDomainSuggestion
                    email={emailWatch}
                    onEmailSuggestionClick={(email) => {
                      setValue("email", email);
                      setShowEmailSuggestions(false);
                    }}
                  />
                )}
              </div>
              <button
                type="submit"
                className="rounded-xl bg-[#D92D20] py-4 text-center text-xl font-bold uppercase text-white"
              >
                Join the waitlist!
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
