import { SectionProps } from "../types/MembershipCourses.type";
export const figureBlueprintCourseContent: SectionProps[] = [
  {
    section: "The Most Profitable Affiliates",
    content: [
      {
        title: "Watch this first!",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/64fc7ea8-3c70-4a11-a833-6f99f0cf44c2",
      },
      {
        title: "The Most Profitable Affiliates",
        htmlSrc: `Audible - Earn $5 for every Audible Free Trial sign-up and $10 for every Audible Gold Digital Membership sign-up! Their affiliate program is under Amazon, so sign up for Amazon Associates here

Webull - Refer 3 friends, and get 9 Free Stocks!

Wealthfront

Moomoo

Coinbase

Epidemic Sound - Referrers who bring in over 12 paying subscribers can become Epidemic Sound Ambassadors. You get a free subscription, 50/50 revenue share for every new paying subscriber, and paid creative opportunities.

Skillshare - Earn 40% commission, up to $67, for every new customer you refer to Skillshare`,
      },
      {
        title: "Add this affiliate disclaimer",
        htmlSrc: `Add this affiliate disclaimer to the bottom of your videos' descriptions:

AFFILIATE DISCLOSURE: Some of the links in this video's description are affiliate links, meaning, at no additional cost to you, the owner may earn a commission if you click through, make a purchase, and/or opt-in.`,
      },
      {
        title: "How to Get Approved as an Affiliate",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/1f2b51f7-1159-4688-becb-53ad60fad5fc",
        htmlSrc: `Hi NAME_OR_COMPANY_NAME,

My name is YOUR_NAME and I am the owner of a brand new YouTube channel called CHANNEL_NAME.

We are planning on publishing out first video soon entitled:

ATTACH_IMAGE_OF_TITLE_THUMBNAIL_MOCKUP_OF_YOUR_VIDEO_FROM_CLICKPILOT

You can take a look at the draft of the video here: LINK_TO_FINAL_DRAFT_OF_YOUR_VIDEO

Based on what we know about the YouTube algorithm and the market for these videos, we expect this video to get potentially hundreds of thousands of views. [CHANGE_AS_NEEDED]

And we think this video would be perfect to promote PRODUCT in it as an affiliate.

If you're interested, I'd love to get the affiliate link set up!

Thanks,

YOUR_NAME

Founder of CHANNEL_OR_COMPANY_NAME`,
      },
      {
        title: "Get Affiliates to Pay You MORE!",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/df719b48-6315-46a1-a0b7-280a8d42f319",
        htmlSrc: `Hi NAME!

Hope you're doing well.

I wanted to quickly discuss increasing our affiliate commission.

Over the past TIME_FRAME, we've gotten NUMBER_OF_SALES_OR_SIGN_UPS for COMPANY.

We love working with you and we plan on continuing to promote your product in our future videos.

Would you be open to increasing the commission from CURRENT_COMMISSION to DESIRED_COMMISSION?

Thanks!

YOUR_NAME`,
      },
    ],
  },

  {
    section: "How Sponsorships Work",
    content: [
      {
        title: "How Sponsorships Work",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/a3fab8b5-ff50-451a-a05a-4afd955fe95c",
        htmlSrc: `Why do brands even want to sponsor YouTube channels:

Your audience trusts you, they’re paying for access to that trust

They get free video production

What they’re looking for: brand awareness vs conversions

90% of the advertising brands are looking for ROI versus brand awareness.

Since 90% of sponsors are looking to get a return on their investment versus pure brand awareness, that means they’re looking to make money on your sponsor spot. 

These are the two numbers they care about:

LTV = Lifetime value

CPA = Cost per acquisition

If they think they can make more money than what they pay you for a sponsor spot, they’ll sponsor you.

The first offer they give you is the CPA they want, and the number of sales they think you’ll get. 

They’ll look at your views, maybe engagement (likes, comments), and maybe they’ll look at your sub count (more if you have a super low sub count)`,
      },
      {
        title: "How much should you charge?",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/411963ec-3c65-4841-a031-65cd69e335a5",
        htmlSrc: `Niches:

Tech: $10 - $40 CPM

Finance: $10 - $100 CPM

Tech Professional (Software Engineers): $20-$90 CPM

Commentary: $10-$20 CPM

Lifestyle: $10 - $20 CPM

Faceless Video Essay / Documentaries (Finance, Politics, Geography): $10-$30 CPM

For different view counts:

Average 50K views

$10 CPM - $500

$20 CPM - $1,000

$30 CPM - $1,500

$40 CPM - $2,000

Average 100k views

$10 CPM - $1,000

$20 CPM - $2,000

$30 CPM - $3,000

$40 CPM - $4,000

Average 500K

$10 CPM - $5,000

$20 CPM - 10,000

$30 CPM - $15,000

$40 CPM - $20,000

Average 1M views

$10 CPM - $10,000

$20 CPM - $20,000

$30 CPM - $30,000

$40 CPM - $40,000`,
      },
      {
        title: "Terms you should know",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/de0414c4-daed-440e-a5ec-f56317399bf8",
        htmlSrc: `Understanding and discussing these terms in detail during the collaboration negotiation is essential for a transparent and mutually beneficial partnership between creators and brands.

This is just a reminder that each brand has its own definition, but this is the general consensus. Please make sure to clarify with the brand.

Payment Terms: 

Net 30 after the video goes live (and invoiced, depending on the brand)

Flat Fee: A fixed amount agreed upon between the creator and the brand for their services, regardless of the performance or outcomes.

CPM: Payment is based on a set amount for every 1,000 impressions of their ad,. For instance, if the agreed CPM rate is $5, the advertiser pays $5 for every 1,000 ad views. Payment is usually based on the number of impressions delivered after 30 days and another 30 days for the brand to settle the payment.

Usage Rights: The permissions granted to the brand regarding how they can use the created content. It may include rights for online and offline promotion, advertising, paid media, and other purposes.

Exclusivity: A provision in the collaboration agreement that restricts the creator from working with competing brands or creating similar content for a specified period.

Performance Metrics:

Key Performance Indicators (KPIs): Metrics used to evaluate the success of a campaign. Examples include click-through rates, conversion rates, engagement rates, and reach.

Conversion: When an audience takes a desired action, such as purchasing or signing up for a newsletter.

Content Ownership:

Intellectual Property: Creators often retain the intellectual property rights to their content unless specified otherwise in the agreement.

License: The permission granted by the creator to the brand to use the content, typically for a defined purpose and duration.

Disclosure and Compliance:

FTC Guidelines: The Federal Trade Commission (FTC) in the United States requires creators to disclose their relationships with brands in sponsored content to maintain transparency. Hashtags like #ad or #sponsored are commonly used for disclosure.

Timeline and Deliverables:

Timeline: The schedule for when specific tasks and deliverables are due. Adhering to the timeline is crucial for the collaboration's success.

Deliverables are the tangible items or actions that the creator is expected to provide, such as blog posts, social media posts, or videos.

Termination Clause: Specifies the conditions under which either party can terminate the collaboration agreement. This includes breaches of contract, failure to deliver, or other specified events.

Negotiation and Amendments:

Negotiation is the process of discussing and reaching an agreement on terms. Both parties may negotiate various aspects of the collaboration before finalizing the agreement.

Amendments: Changes or additions to the original agreement made by mutual consent of the creator and the brand.

Ad Placement:

Pre-roll:

Pre-roll ads are advertisements that appear before the main video content starts.

Placement: These ads are typically shown at the beginning of a video, and viewers will encounter them before they can access the main content. The advertisement will be within the first 3-5 minutes of the video.

Mid-roll:

Mid-roll ads are advertisements that appear in the middle of the video content.

Placement: These ads are inserted in the middle of a video, and viewers will encounter them while watching the main content. Typically, the placement of the video will occur between 40-60% of the total duration.

Post-roll:

Post-roll ads are advertisements that appear after the main video content has finished playing.

Placement: These ads are shown at the end of a video, and viewers will encounter them once the main content is concluded.`,
      },
    ],
  },

  {
    section: "Getting Your First Sponsor ASAP!",
    content: [
      {
        title: "Have Sponsors Reach Out to You!",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/a4a4812c-510e-4f73-9b22-6abf96008e51",
        htmlSrc: `Add this text in the description of all your videos:

Interested in becoming a sponsor in one of our videos? Email us at youremail@gmail.com

Add your contact email to your about page as well`,
      },
      {
        title: "How to Find the Right Sponsors",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/f97a360d-d28a-49af-b8b8-310ba87a389d",
        htmlSrc: `Easiest way is to reach out to sponsors you see sponsoring people in your niche because they’re already used to sponsoring your content

Avoid reaching out to companies that don’t already sponsor YouTube channels because they’re not bought into influencer marketing

Avoid sponsors that wouldn’t align to your audience`,
      },
      {
        title: "List of Popular YouTube Sponsors",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/3667917e-2e49-4966-bd4d-9d710016f5b3",
        htmlSrc: `Brilliant

Morning Brew

Ground News

Blinkist

BetterHelp

Aura

Eskter

Ridge

Squarespace

Skillshare

NordVPN

Surfshark

Incogni

Vessi

Keeps

AG1

Babbel

Raycon

HelloFresh

Trade Coffee

Bespoke Post

Grammarly

Liquid I.V.

Public

Acorns

air up

American Hartford Gold

Anker

ASUS

Audible

AutoDS

Bokksu

Bright Cellars

ButcherBox

Canva

Casekoo

Casetify

Chime

CleanMyMac X

CookUnity

CoPilot

Cozy Earth

DeleteMe

Invideo

Seeking Alpha

Insta360

Factor

FOREO

Freshbooks

Fundrise

Guard.io

Helix Sleep

Holzkern

Hostinger

Hubspot

Huel

Kittl

KiwiCo

LG

Lingoda

Love Bonito

Magic Spoon

Masterworks

Morgan & Morgan

MyHeritage

Nebula

Newegg

Opal

Opera

Oura Ring

Paperlike

Noom

Private Internet Access

Pitaka

Printify

Rakuten

Rocket Money

Rosetta Stone

Scentbird

SHEATH

ShipStation

Shopify

Shortform

SoFi

StartEngine

Storyblocks

Tailor Brands

Cometeer

TubeBuddy

Webull

Whoop

Curiosity Stream

My Patriot Supply

Zocdoc

Zoe

ZBiotics

War Thunder

thredUP

Simplisafe

The Daily Upside`,
      },
      {
        title: "How to Get Sponsored on a Brand New Channel!",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/7376a45d-ce47-4c59-8029-5e613290fa1c",
        downloadSrc: [
          "https://docs.google.com/document/d/1am-n0nc0p1nXOnyYQka3nXz_inHrinT1v9aS91A9x9o/edit",
        ],
      },
      {
        title: "The Million Dollar Sponsor Contract Template",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/053c76bf-a515-4954-b9c4-c38c797f0230",
        downloadSrc: [
          "https://docs.google.com/document/d/1_l-6RETCw3VyoFQasMg1J11xNJmwbnXO-l73RreQq4w/edit#heading=h.8q7z9kv1nmar",
        ],
      },
      {
        title: "If you need to send sponsors an invoice",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/92cca9a1-04b4-4fbc-9592-6f915bcf9416",
        htmlSrc: `We recommend using this free invoice generator.`,
      },
    ],
  },

  {
    section: "Creating Your First Sponsor Spot",
    content: [
      {
        title: "Enhanced Million Dollar Ad Script Template",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/3bdbd334-6eef-4401-90ed-72710664bab1",
        downloadSrc: [
          "https://docs.google.com/document/d/1puXnHw_Rr0iaY9lClTNOC6zn-zSpzn2a0RwKZ2Osfw8/edit#heading=h.fuhtghu6x2r",
        ],
      },
      {
        title: "Actual Sponsor Scripts We've Used",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/9df5d409-5593-4037-8391-2d5edbeeba27",
        downloadSrc: [
          "https://docs.google.com/document/d/14eve8As00Ve3Es7ZyG6-s7cCzAOsDcTrUHgkL55iuso/edit",
          "https://docs.google.com/document/d/1QfWmKLEc6oKiBH1yeypkWVVM3TdMCRboD848AdrbthE/edit",
          "https://docs.google.com/document/d/1esJLKaHOam1xxMm6W0SoSWjJUp7IjGIZ7W7Ioy7dyPI/edit",
          "https://docs.google.com/document/d/13W_a7ZN5WY-ATzgfsTicbPFzqBg0yotfD8oa6DaTr8A/edit",
        ],
      },
      {
        title: "Video Editing Template for Sponsor Spots",
        downloadSrc: [
          "https://drive.google.com/drive/folders/1sxBfYjpF6xHKQUE0H-FzH4ge80xqiyt7",
        ],
      },
      {
        title: "Watch me write a sponsor spot from scratch!",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/869c41cd-7f33-49b3-b87f-b4e25c88acd1",
      },
    ],
  },

  {
    section: "Getting MORE Sponsors!",
    content: [
      {
        title: "Million Dollar Sponsor Email Templates",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/094d5b4e-31a8-4e30-991a-a7d10aac1f1e",
        htmlSrc: `New channel outreach email for CPM Model

CPM outreach for new channel

Sponsor outreach email template

Outreach Email

Sponsor inbound email reply templates

 Inbound Reply Option #1 

 Inbound Reply Option #2

Inbound Reply Option #3

Sponsor negotiation asks for more money templates

Negotiation Email

Sponsor Follow-Up Email

Follow Up Template

Long-term Partnership Outreach Email 

Template`,
      },
      {
        title: "How to NOT Get Ripped Off",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/caa3162d-c408-4f38-aea9-ed81a8fcd04c",
        htmlSrc: `Clearly Define Terms: Outline expectations, deliverables, and compensation clearly from the start.

Negotiate Fairly: Know your worth and negotiate compensation that reflects your audience size and engagement rates.

Review Contracts Thoroughly: Have a detailed written agreement covering payment, scope of work, timeline, and usage rights.

Research Brand Reputation: Investigate the brand's track record in influencer marketing, and ask for references from past collaborators.

Avoid Excessive Exclusivity: Be cautious with exclusivity clauses, and don't commit to network deals that limit collaboration opportunities.`,
      },
      {
        title: "Watch Us Close a $12,000 Deal!",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/3b48ec7d-809d-445c-8860-98ede5273a20",
      },
    ],
  },

  {
    section: "BONUS: How to Get Others to Close Deals For You",
    content: [
      {
        title: "Watch this first!",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/44a25d8f-b184-4e88-a482-03831dc311c1",
      },
      {
        title: "List of Brand Agencies We Trust",
        htmlSrc: `SpaceStation

InfluenceLogic

ThoughtLeaders

Boss Management

Centus

Veritone

Ad Results Media

Faved

Influential

NanoZebra

Pixly

Makrwatch

TATAM`,
      },
      {
        title: "How to NOT Get Screwed Over By Agencies",
        htmlSrc: `Exclusivity can be good or bad

Beware of predators

They’ll offer you a small amount of money, then they reach out to the sponsor, they pocket the difference

They should only get a percentage of whatever deals they bring`,
      },
    ],
  },

  {
    section: "BONUS: Get Access to Jake's Sponsor Manager",
    content: [
      {
        title: "Watch this!",
        videoSrc:
          "https://iframe.mediadelivery.net/embed/204326/c072664e-d881-4ecf-97ae-b8fa3c6e8aac",
        htmlSrc: `• As a special reward, once your channel averages around 50,000 views a video, (your last 3 videos must have 50,000 views) you will get private access to my sponsor manager

• When you meet this requirement, email me personally to jake@viralprofits.yt so me or my team can show you how to take it further and connect you with my brand deals manager if it's a good fit. Email with the subject line "7-FIGURE BLUEPRINT - SPONSOR MANAGER" with a link to your channel and a screenshot of your analytics tab`,
      },
    ],
  },
];
