import { Flex, Progress } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import bgDesktop from "../assets/images/calendar-booking/calendar-booking-bg-desktop.webp";
import bgMobile from "../assets/images/calendar-booking/calendar-booking-bg-mobile.webp";
import { cn } from "../utils/utils";

const content = [
  {
    text: "Thank you for your answers",
    time: 3000,
    highlight: true,
  },
  {
    text: "Checking your answers...",
    time: 3000,
  },
  {
    text: "Checking if you qualify...",
    time: 5000,
  },
  {
    text: "Congrats, loading the next step...",
    time: 2000,
  },
];

const initCalendlyWidget = () => {
  const script = document.createElement("script");
  script.src = "https://assets.calendly.com/assets/external/widget.js";
  script.async = true;
  document.body.appendChild(script);
};

const QualifyPage = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const [params] = useSearchParams();

  const name = params.get("name")?.split(" ")[0] || "";
  initCalendlyWidget();

  useEffect(() => {
    if (currentStep === content.length) {
      return;
    }

    const { time } = content[currentStep];

    const interval = setInterval(() => {
      setCurrentStep((prevStep) => prevStep + 1);
    }, time);

    return () => {
      clearInterval(interval);
    };
  }, [currentStep]);

  const renderContent = ({ text, highlight }: { text: string, highlight?: boolean }) => {
    return (
      <div className="w-full flex flex-col gap-7.5 items-center">
        <div className="w-full md:w-[389px]">
          <Progress
            size={'sm'}
            isIndeterminate
            background={'rgba(217, 217, 217, 1)'}
            borderRadius={10}
          />
        </div>
        <p className="text-center text-28 font-black md:text-32">
          {text} {highlight && <span className="text-primary">{name}</span>}
        </p>
      </div>
    );
  };

  return (
    <Flex
      className="min-h-screen w-screen items-center justify-center bg-cover px-6 py-6"
      backgroundImage={{
        base: `url(${bgMobile})`,
        md: `url(${bgDesktop})`,
      }}
    >
      {/* preload the calendly widget but do not display it yet, saves us extra loading time of the widget */}
      <div className={cn("flex w-full flex-col items-center justify-center", currentStep !== content.length && "h-0 w-0 opacity-0")}>
        <p className="md:0 mb-7.5 w-[230px] text-center text-[32px] font-black leading-[35.84px] md:w-full md:text-48 md:leading-none">
          Please choose a time slot:
        </p>
        <div
          className="calendly-inline-widget h-[600px] min-h-[320px] w-full overflow-hidden rounded-[14px] md:h-[700px]"
          data-url="https://calendly.com/d/3nh-qwp-4nk/test-group-discovery-jake-tran?hide_gdpr_banner=1&primary_color=d92d20"
        />
      </div>

      {
        content[currentStep] && <>
          {renderContent(content[currentStep])}
        </>
      }
    </Flex>
  );
};

export default QualifyPage;
