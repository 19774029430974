import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo } from "react";
import ProgressBar from "../../ui/ProgressBar/ProgressBar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./reminder-modal.css";
import { DoubleArrowRight } from "../../../assets/icons";
import { Carousel } from "../Carousel/Carousel";

export const ReminderModal = ({
  firstName,
  onCloseModalCallback,
  isLoaderSuccess,
  hasOpen = false,
}: {
  firstName?: string;
  onCloseModalCallback?: () => void;
  isLoaderSuccess?: boolean;
  hasOpen?: boolean;
}) => {
  const [isLargerThanLg] = useMediaQuery("(min-width: 1024px)");

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: false,
  });

  useEffect(() => {
    if (isLoaderSuccess) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const addEvent = (obj: any, evt: any, fn: any) => {
        if (obj.addEventListener) {
          obj.addEventListener(evt, fn, false);
        } else if (obj.attachEvent) {
          obj.attachEvent("on" + evt, fn);
        }
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      addEvent(document, "mouseout", function (e: any) {
        e = e ? e : window.event;

        // Get the mouse coordinates
        const mouseX = e.clientX;
        const mouseY = e.clientY;

        // Check if the mouse has left the viewport
        if (
          mouseY <= 0 || // Mouse left at the top
          mouseX <= 0 || // Mouse left at the left
          mouseX >= window.innerWidth || // Mouse left at the right
          mouseY >= window.innerHeight // Mouse left at the bottom
        ) {
          // Check if the related target is null or the related target's nodeName is "HTML"
          const from = e.relatedTarget || e.toElement;
          if ((!from || from.nodeName == "HTML") && !isOpen) {
            onOpen();
          }
        }
      });
    }
  }, [isLoaderSuccess]);

  const listSlide = useMemo(
    () => [
      {
        imgUrl: "/slide-feedback/closing-in-on-50k-for-my-video.png",
        alt: "first",
      },
      {
        imgUrl: "/slide-feedback/erick-castillo-i-want-to-take-a-moment.png",
        alt: "first",
      },
      {
        imgUrl:
          "/slide-feedback/reinaldo-martinez-channel-update-thousands-of-views.png",
        alt: "first",
      },
      {
        imgUrl: "/slide-feedback/lucian-p-acclerator-paying-for-itself.png",
        alt: "first",
      },
      {
        imgUrl:
          "/slide-feedback/will-major-i-lauched-my-channel-and-posted-my-first-video.png",
        alt: "first",
      },
      {
        imgUrl: "/slide-feedback/tommy-h-success-story.png",
        alt: "first",
      },
      {
        imgUrl:
          "/slide-feedback/lucian-p-just-landered-an-affiliate-from-a-random-caller.png",
        alt: "lucian-p-just-landered-an-affiliate-from-a-random-caller",
      },
    ],
    [],
  );

  const handleCloseReminderModal = useCallback(() => {
    onClose();
    onCloseModalCallback?.();
  }, [onClose, onCloseModalCallback]);

  return (
    <div>
      {listSlide.map((item, index) => {
        return (
          <link
            rel="preload"
            as="image"
            href={"/images" + item.imgUrl}
            key={index}
          />
        );
      })}
      <Modal
        closeOnEsc
        closeOnOverlayClick
        isCentered
        isOpen={!hasOpen && isOpen}
        onClose={handleCloseReminderModal}
      >
        <ModalOverlay className="backdrop-blur-sm"/>
        <div className="p-2">
          <ModalContent className="!mx-8 !max-w-[calc(100%-48px)] !rounded-[20px] bg-[#FFFBFA] lg:!min-w-[1000px] lg:!max-w-[1268px] lg:!p-0 min-[1270px]:!min-w-[1262px]">
            {isLargerThanLg && <ModalCloseButton />}
            <ModalBody className="!p-0">
              <div className="md:max-w-[1262px] lg:grid lg:grid-cols-3">
                <div className="hidden overflow-hidden lg:inline-block lg:max-h-[700px] xl:max-h-[626px]">
                  <div>
                    <Carousel
                      mode="vertical"
                      className="px-[50px]"
                      items={listSlide.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="mb-11 rounded-[7px] bg-[#F7F7F7] p-[10px] shadow-sliderShadow"
                          >
                            <img
                              loading="eager"
                              fetchPriority="high"
                              src={"/images" + item.imgUrl}
                              alt=""
                              width={"100%"}
                              height={"auto"}
                            />
                          </div>
                        );
                      })}
                    />
                  </div>
                </div>
                <div className="p-[22px] pb-0 lg:col-span-2 lg:py-[64px] lg:pr-[50px]">
                  <ProgressBar
                    infinite
                    stages={[
                      { end: 30, duration: 100 },
                      { end: 90, duration: 9000 },
                    ]}
                  />
                  <div className="mt-[50px]">
                    <div className="text-26 font-black text-[#D92D20] lg:text-40">
                      {`Don't decide now${firstName ? " "+firstName?.toUpperCase(): ""}...`}
                    </div>
                    <div className="mt-5 text-pretty text-16 lg:pr-10 lg:text-20">
                      <div>
                        Just try it out and if at any point in the next 365
                        days, you feel like you haven’t gotten your money’s
                        worth, just send us an email at{" "}
                        <a
                          href="mailto:hello@viralprofits.yt"
                          className="text-[#D92D20] underline underline-offset-4"
                        >
                          hello@viralprofits.yt,
                        </a>{" "}
                        and we'll send you your money back.
                      </div>
                      <div className="mt-[25px]">
                        You could literally watch everything, implement it, make
                        money, keep the money, and still get your money back if
                        you're not satisfied.
                      </div>
                      <div className="mt-[25px]">
                        Remember, we’ve never had a single refund request since
                        this Fast-Start launched. Don’t miss out
                        <span className="capitalize">{firstName ? " "+firstName : ""}</span>, check
                        the screenshots on the left to see the rave reviews from
                        our students!
                      </div>
                      <div
                        className="mt-[35px] flex w-full cursor-pointer items-center justify-center rounded-[17px] bg-[#D92D20] px-[25px] py-[18px] text-left text-16 font-bold uppercase text-white lg:py-5 lg:text-center lg:text-24"
                        onClick={handleCloseReminderModal}
                      >
                        <span className="mr-[10px] md:text-pretty">
                          YES, GIVE ME INSTANT ACCESS TO THE FAST-START
                        </span>
                        <DoubleArrowRight className="inline-block" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-fit w-full max-w-[345px] overflow-hidden min-[400px]:max-w-full lg:hidden">
                  <Carousel
                    className="rounded-lg pb-[18px] pt-[22px]"
                    mode="horizontal"
                    items={listSlide.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="rounded-[7px] bg-[#F7F7F7] p-[6px] shadow-sliderShadow"
                        >
                          <img
                            src={"/images" + item.imgUrl}
                            alt=""
                            loading="eager"
                            fetchPriority="high"
                            className="inline-block h-[156px] w-auto rounded-lg"
                          />
                        </div>
                      );
                    })}
                  />
                </div>
              </div>
            </ModalBody>
          </ModalContent>
        </div>
      </Modal>
    </div>
  );
};
