import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { recurring } from "../../../apis/payment";
import { ROUTES } from "../../../constants";
import { useDelayNavigation } from "../../../hooks/useDelayNavigation";
import { gtag_report_conversion, GTAGEvent } from "../../../utils/gtag";
import ArrowDownIcon from "../../ArrowDownIcon";
import { FirstYoutubePaycheckBtn, SevenFigureLaunchpad } from "../../UpSell";

const RapidViralSecrets = () => {
  const [isRecurring, setIsRecurring] = useState(false);
  const toast = useToast();
  const navigate = useDelayNavigation();

  const handleNavigateToMemberShipPage = () => {
    navigate(ROUTES.MEMBERSHIP);
  };

  const handleClickDownsellPayment = async () => {
    try {
      setIsRecurring(true);
      const res = await recurring();

      gtag_report_conversion(GTAGEvent.DOWNSELL_PURCHASE, {
        url: ROUTES.UPSELL2,
        value: 97,
        transaction_id:
          res.data.metadata.customerVaultId ?? res.data.metadata.paymentIntent,
      });

      navigate(ROUTES.UPSELL2);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log("error", error);

      toast({
        status: "error",
        position: "top-right",
        description: error?.response?.data?.message,
      });
    } finally {
      setIsRecurring(false);
    }
  };

  return (
    <>
      <SevenFigureLaunchpad
        subHeading="Make your videos viral and start profiting in just 3-4 weeks"
        startingText={
          <>
            <span className="font-semibold text-[#D92D20]">$97 today</span>, and{" "}
            <span className="font-semibold text-[#D92D20]">$97 later</span>
          </>
        }
      />

      <div className="align-center mx-auto mt-15 flex flex-col px-6 text-center md:max-w-[1013px]">
        <div id="regular-price" className="mx-auto text-22 md:text-34">
          <ArrowDownIcon />
        </div>
        <p className="mb-[15px] mt-[30px] text-20 text-black md:mb-5 md:mt-10 md:text-32">
          Regular price: <span className="font-medium line-through">$197</span>
        </p>

        <div className="mx-auto mb-[15px] inline-block h-[58px] w-max rounded-[38px] border border-[#D92D20] bg-[#FEF7F8] px-[29px] py-[14px] text-20 md:mb-5 md:h-[74px] md:text-32">
          <span className="font-black">Today's price: </span>
          <span className="font-medium text-primary">$97 </span>
        </div>
        <p className="mb-7.5 text-18 md:mb-12.5 md:text-28">
          With one payment of $97 after 30 days
        </p>
        <FirstYoutubePaycheckBtn
          className="mx-auto mb-[18px]"
          disabled={isRecurring}
          onClick={handleClickDownsellPayment}
        />
        <p className="mb-15 text-16 text-[#959595] md:text-22">
          Your card on file will be billed $97 now, followed by one more $97
          payment 30 days from today
        </p>
        <p
          className="max-w-[1013px] cursor-pointer text-18 font-semibold text-primary underline underline-offset-[5px] md:text-24"
          onClick={handleNavigateToMemberShipPage}
        >
          No, thanks, I don't want to get my first paycheck from YouTube as fast
          as possible; I'd rather figure everything out myself. I understand
          that this is my last chance to get this deal.
        </p>
      </div>
    </>
  );
};

export default RapidViralSecrets;
