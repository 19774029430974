import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import LogoutIcon from "../components/LogoutIcon";
import AvatarIcon from "../components/AvatarIcon";
import ArrowLeftIcon from "../components/ArrowLeftIcon";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import { ROUTES } from "../constants";
import MembershipCourse from "../components/MembershipCourse";
import { figureBlueprintCourseContent } from "../components/MembershipCourse/content/figureBlueprintCourseContent";
import { launchpadCourseContent } from "../components/MembershipCourse/content/launchpadCourseContent";
import { endlessIdeaCourseContent } from "../components/MembershipCourse/content/endlessIdeaCourseContent";
import { CourseName } from "../components/MembershipCourse/types/MembershipCourses.type";
import { CloseIcon } from "../assets/icons";

const BluePrint = () => {
  const [isHoveredAvatar, setIsHoveredAvatar] = useState(false);
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const {
    isPurchased7FigureLaunchPad = false,
    isPurchased7FigureBluePrint = false,
    isPurchasedEndlessVideoIdea = false,
  } = user || {};

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const course = (queryParams.get("course") || "") as CourseName;
  const isOpenedMenu = !!queryParams.get("menu");

  const validCourses = ["7FigureBlueprint", "7FigureLaunchpad", "EndlessIdea"];

  const courseContent = {
    "7FigureBlueprint": figureBlueprintCourseContent,
    "7FigureLaunchpad": launchpadCourseContent,
    EndlessIdea: endlessIdeaCourseContent,
  };

  const handleGoBackToPortal = () => {
    navigate(ROUTES.MEMBERSHIP);
  };

  const handleOnCloseMenu = () => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete("menu");
    navigate(currentUrl.pathname + currentUrl.search, { replace: true });
  };

  useEffect(() => {
    if (
      validCourses.indexOf(course) === -1 ||
      (course === "7FigureLaunchpad" && !isPurchased7FigureLaunchPad) ||
      (course === "7FigureBlueprint" && !isPurchased7FigureBluePrint) ||
      (course === "EndlessIdea" && !isPurchasedEndlessVideoIdea)
    ) {
      navigate(ROUTES.MEMBERSHIP);
    }
  }, []);

  return (
    <>
      {/* Header */}
      <Flex
        as="nav"
        borderBottom="1px solid #EAECF0"
        height="60px"
        alignItems="center"
        justifyContent="space-between"
        px={{
          base: "20px",
          md: "30px",
        }}
      >
        <>
          {isOpenedMenu ? (
            <>
              <Text className="text-16 font-bold">Menu</Text>
              <Box onClick={handleOnCloseMenu}>
                <CloseIcon />
              </Box>
            </>
          ) : (
            <>
              {/* Back to 5-Day Fast Start */}
              <Flex
                alignItems="center"
                cursor="pointer"
                onClick={handleGoBackToPortal}
              >
                <ArrowLeftIcon />
                <Heading
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="20.24px"
                  ml="2px"
                >
                  Back to 5-Day Fast Start
                </Heading>
              </Flex>
              {/* Avatar */}
              <Flex
                height="60px"
                alignItems="center"
                onMouseOver={() => setIsHoveredAvatar(true)}
                onMouseOut={() => setIsHoveredAvatar(false)}
              >
                <Flex
                  className="-mr-6 md:mr-0"
                  position="relative"
                  height="40px"
                  alignItems="center"
                  p="8px 12px"
                  borderRadius="12px"
                  cursor="pointer"
                  background={isHoveredAvatar ? "#f2f4f7" : "transparent"}
                >
                  <AvatarIcon />
                  <Box
                    className="hidden md:block"
                    ml="8px"
                    fontSize="16px"
                    fontWeight="450"
                    lineHeight="20.24px"
                    color="#9b9b9b"
                  >
                    Welcome,{" "}
                    <Box as="span" color="#000" fontWeight="500">
                      {user?.firstName}
                    </Box>
                  </Box>

                  {isHoveredAvatar && (
                    <Flex
                      position="absolute"
                      top="calc(100% + 6px)"
                      right="0"
                      alignItems="center"
                      p="8px 29px 8px 12px"
                      background="#fff"
                      border="1px solid #eaecf0"
                      borderRadius="7px"
                      onClick={logout}
                    >
                      <LogoutIcon />{" "}
                      <Text
                        ml="5px"
                        fontSize="14px"
                        lineHeight="17.31px"
                        fontWeight="500"
                      >
                        Logout
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </>
          )}
        </>
      </Flex>

      {/* Courses */}
      <MembershipCourse
        courseName={`course=${course}`}
        courseContent={courseContent[course]}
        isOpenedMenu={isOpenedMenu}
      />
    </>
  );
};

export default BluePrint;
