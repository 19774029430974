import { useEffect, useState } from "react";

const SALES_EXPIRED_KEY = "salesExpire";
const SALES_EXPIRED_TIME_KEY = "startTime";

const getSalePrice = () => {
  return parseInt(localStorage.getItem(SALES_EXPIRED_KEY) || "") ? `7` : `3`;
};

export function useSalesPrice() {
  const [value, setValue] = useState(() => getSalePrice());

  useEffect(() => {
    window.addEventListener("storage", (event) => {
      const { key } = event;

      if (key === SALES_EXPIRED_KEY || key === SALES_EXPIRED_TIME_KEY) {
        setValue(getSalePrice());
      }
    });
  }, []);

  return value;
}
