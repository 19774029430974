import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import CloseIcon from "../CloseIcon";
import Calendar from "../Calendar";

type BookCallPopUpProps = {
  isOpen: boolean;
  onClose: () => void;
};

// TODO: Need to show this popup when
// 1. The user has watched Day 1 (so after Day 1) => need tracking the watched video
// TABLE UserVideoWatched (
//   id INT PRIMARY KEY,
//   userId INT,
//   videoId INT,
//   watchedAt TIMESTAMP
// );

// id is a unique identifier for each record.
// userId is the ID of the user who watched the video.
// videoId is the ID of the video that was watched.
// watchedAt is the timestamp of when the video was watched.

// get watched videos of user SELECT videoId FROM UserVideoWatched WHERE userId = 1;

// 2. The user has not booked a call yet => need tracking the booked call, boolean value
// 3. Show this popup also when a user tries to watch Day 2.

const BookCallPopUp = ({ isOpen, onClose }: BookCallPopUpProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        p={{
          base: "20px",
          md: "30px",
        }}
        borderRadius="20px"
        maxWidth={{
          base: "90%",
          md: "614px",
        }}
        height="80vh"
        maxHeight="980px"
        overflowX="scroll"
        css={{
          "&::-webkit-scrollbar": {
            width: "0px",
            height: "0px",
          },
        }}
      >
        <ModalBody p="0" position="relative" textAlign="center">
          <Box
            className="text-26"
            fontWeight="900"
            mb="19px"
            textAlign="center"
            maxWidth={{
              base: "248px",
              md: "420px",
            }}
            mx="auto"
          >
            LET’S FIND YOUR CHANNEL IDEA IN 30 MINUTES!
          </Box>

          <Box
            onClick={onClose}
            position="absolute"
            right="0"
            top="5px"
            cursor="pointer"
          >
            <CloseIcon />
          </Box>

          <Box className="text-18 mb-[20px]">
            We noticed you haven't scheduled your complimentary{" "}
            <span className="font-bold">Channel Introductory Session</span> yet.
            To make things simple, just select the time that works best for you
            from the options below:
          </Box>

          <Box
            height={{
              base: "330px",
              md: "502px",
            }}
            border="1px solid #EAECF0"
            borderRadius="16px"
          >
            <Calendar />
          </Box>

          <Text className="text-18 font-bold mb-[15px] mt-[25px]">
            What is the “1:1 Channel Introductory Session”
          </Text>

          <Text className="text-18">
            The "Channel Introductory Session" is a quick Zoom call with a
            professional YouTube coach from my team. During this session, we'll
            nail down together the ideal channel concept that’s customized just
            for you. We're offering this session free of charge as a welcoming
            gift to ensure you start off on the right foot—because choosing the
            perfect channel idea is crucial to your entire YouTube journey! 👍
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BookCallPopUp;
