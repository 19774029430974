import { VariantProps, cva } from "class-variance-authority";
import { ComponentPropsWithoutRef } from "react";
import { cn } from "../../utils/utils";

const abstractButtonStyles = cva(
  [
    "xs:text-16",
    "px-2 xs:px-4.5",
    "md:text-2xl flex items-center justify-center rounded-[15px]",
    "md:h-17.5 h-14 text-white md:px-10 font-bold",
    "text-base leading-[20.24px] md:text-2xl md:leading-[30.36px]",
    "disabled:opacity-50 disabled:cursor-not-allowed",
  ],
  {
    variants: {
      variant: {
        "contained:primary": "bg-primary",
      },
    },
    defaultVariants: {
      variant: "contained:primary",
    },
  },
);

export interface AbstractButtonProps
  extends Omit<ComponentPropsWithoutRef<"button">, "color">,
    VariantProps<typeof abstractButtonStyles> {}

const AbstractButton = ({
  variant = "contained:primary",
  className,
  children,
  ...props
}: AbstractButtonProps) => {
  return (
    <button
      type="button"
      {...props}
      className={cn(abstractButtonStyles({ variant, className }))}
    >
      {children}
    </button>
  );
};

export default AbstractButton;
