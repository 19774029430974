import { ComponentPropsWithoutRef } from "react";
import JoinTheProgramImage from "../../../assets/images/home-join-the-program.png";
import { cn } from "../../../utils/utils";

export interface JoinTheProgramProps extends ComponentPropsWithoutRef<"div"> {}

const JoinTheProgram = ({ className, ...props }: JoinTheProgramProps) => {
  return (
    <div
      {...props}
      className={cn("w-full px-6 text-center md:px-0", className)}
    >
      <h2 className="mx-auto mb-10 max-w-[745px] text-center text-24 font-black leading-[30.36px] md:text-[46px] md:leading-[58.19px]">
        Join the program that reveals how thousands of people got their first
        profitable faceless channel up and running in just 5 days…
      </h2>

      <p className="mb-10 text-20 font-bold-450 font-medium leading-[25.3px] text-black md:text-26 md:leading-[32.14px]">
        The Faceless Income Fast-Start
      </p>

      <img
        src={JoinTheProgramImage}
        className="mx-auto mb-10 w-full max-w-[817px]"
      />

      <button
        onClick={() => {
          window.open(
            "/your-results",
          );
        }}
        style={{
          background: "linear-gradient(180deg, #FF0000 0%, #D73F3F 100%)",
          boxShadow:
            "0px 0px 20px 0px #FFFFFF14 inset, 6px -6px 16px 0px #FFFFFF14 inset, 1px 7px 20px -8px #D73F3FCF, 0px -7px 20px 0px #FF303000",
        }}
        className={cn(
          "mx-auto flex min-[1440px]:h-[69px] max-w-[604px] items-center justify-center w-full",
          "rounded-2xl min-[1440px]:px-8 text-20 font-bold leading-[25.3px] text-white text-[20px]",
          "mb-[25px]",
          "h-[78px] px-[14px] py-[20px]"
        )}
      >
        Check available spots in the <br className="sm:hidden" /> Faceless
        Income Fast-Start
      </button>

      <p className="mx-auto max-w-[729px] text-16 font-normal leading-[22.77px] text-black md:text-18">
        <b className="font-bold">The Faceless Income 5-day Fast Start</b> is a{" "}
        <br className="sm:hidden" />
        series of five short but immensely valuable <br className="sm:hidden" />{" "}
        trainings that will show you how to use <br className="sm:hidden" /> the
        same system I use to run all the profitable <br className="sm:hidden" />{" "}
        faceless channels that generate stable income{" "}
        <br className="sm:hidden" /> every month on autopilot, all without
        having to <br className="sm:hidden" /> be creative or “techy,” get on
        camera, record, <br className="sm:hidden" /> or create videos yourself.
      </p>
    </div>
  );
};

export default JoinTheProgram;
