export const MailIcon = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3334 0.666016H2.66671C1.65471 0.666016 0.833374 1.48735 0.833374 2.49935V13.4993C0.833374 14.5113 1.65471 15.3327 2.66671 15.3327H17.3334C18.3454 15.3327 19.1667 14.5113 19.1667 13.4993V2.49935C19.1667 1.48735 18.3454 0.666016 17.3334 0.666016ZM17.3334 4.97435L10 9.86385L2.66671 4.97435V2.7716L10 7.66018L17.3334 2.7716V4.97435Z"
        fill="white"
      />
    </svg>
  );
};
