// src/Timer.js
import { useState, useEffect } from "react";
import { IHeadlessComponent } from "../BaseHeadless/base.headless";

export interface CheckoutTimerProps
  extends IHeadlessComponent<{
    minutes: number;
    seconds: number;
    addMoreMinutes: (number: number) => void;
  }> {
  initialMinutes?: number;
  initialSeconds?: number;
}

const CountdownLogic = (props: CheckoutTimerProps) => {
  const { children, initialMinutes = 0, initialSeconds = 0 } = props;
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const addMoreMinutes = (additionalMinutes: number) => {
    setMinutes((prev) => prev + additionalMinutes);
  };

  return children({
    minutes,
    seconds,
    addMoreMinutes,
  });
};

export default CountdownLogic;
