import { useState, useMemo, useEffect } from "react";
import { FlagDetection, FullScreenModal } from "../ui";
import React from "react";
import ProgressBar from "../ui/ProgressBar/ProgressBar";

export const LoaderModal = React.memo(
  ({ callbackSuccessLoader }: { callbackSuccessLoader?: () => void }) => {
    const [modal, setShowModal] = useState<boolean>(true);
    const [index, setIndex] = useState<number>(0);

    const textProgress = useMemo(() => {
      return [
        {
          text: "Checking for available spots...",
          loading: { end: 0, duration: 1000 },
        },
        {
          text: "Searching for discounted spots...",
          loading: { end: 50, duration: 8000 },
        },
        {
          text: "Congratulations, your discount has been applied!",
          loading: { end: 70, duration: 2000 },
        },
        {
          text: "Holding a discounted spot for you!",
          loading: { end: 90, duration: 6000 },
        },
        {
          text: "Holding a discounted spot for you!",
          loading: { end: 100, duration: 1000 },
        },
      ];
    }, []);

    useEffect(() => {
      let timer: string | number | NodeJS.Timeout | undefined;
      if (index < textProgress.length) {
        timer = setTimeout(() => {
          setIndex((prev) => prev + 1);
        }, textProgress[index].loading.duration);
      } else {
        callbackSuccessLoader?.();
        setShowModal(false);
      }

      return () => {
        clearTimeout(timer);
      };
    }, [index, textProgress.length]);

    return (
      <FullScreenModal open={modal}>
        <div className="flex flex-col items-center justify-center">
          <FlagDetection className="flex h-fit max-h-[41px] items-center justify-center text-[63px]" />
          <p className="mt-[25px] text-center text-32 font-black md:mt-[39px] md:text-40">
            {index < textProgress.length
              ? textProgress[index].text
              : textProgress[textProgress.length - 1].text}
          </p>
          <div className="mt-[25px] w-full max-w-[345px] md:max-w-[735px]">
            <ProgressBar stages={textProgress.map((item) => item.loading)} />
          </div>
        </div>
      </FullScreenModal>
    );
  },
);
