export const VisaIcon = () => {
  return (
    <svg
      width="29"
      height="10"
      viewBox="0 0 29 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7555 0.762541L12.8897 9.56205H10.6337L12.4997 0.762541H14.7555ZM24.2475 6.44441L25.4353 3.1399L26.1188 6.44441H24.2475ZM26.7647 9.56205H28.8516L27.0306 0.762541H25.1044C24.6715 0.762541 24.3063 1.01654 24.1438 1.40813L20.759 9.56205H23.1282L23.5986 8.24804H26.4931L26.7647 9.56205ZM20.8766 6.68894C20.8862 4.36645 17.6933 4.23862 17.7154 3.20117C17.722 2.88506 18.0204 2.54974 18.6722 2.46368C18.9959 2.42106 19.8862 2.38848 20.897 2.85777L21.2928 0.991755C20.7499 0.792899 20.0512 0.601562 19.1823 0.601562C16.9524 0.601562 15.3829 1.79776 15.3696 3.51059C15.3556 4.77726 16.4901 5.48411 17.345 5.90522C18.2239 6.33663 18.5192 6.61291 18.5154 6.99865C18.5096 7.58937 17.8142 7.84949 17.1655 7.8598C16.031 7.8779 15.3727 7.55093 14.8479 7.30417L14.4391 9.23229C14.9661 9.47654 15.9391 9.68877 16.9486 9.69963C19.3184 9.69963 20.8689 8.51819 20.8766 6.68894ZM11.5316 0.762541L7.87634 9.56205H5.4911L3.69245 2.53943C3.58314 2.10691 3.48818 1.94871 3.15611 1.76629C2.61425 1.46968 1.71851 1.19089 0.93042 1.01821L0.984247 0.762541H4.8231C5.31223 0.762541 5.75251 1.09118 5.86348 1.65962L6.8136 6.75189L9.16129 0.762541H11.5316Z"
        fill="#1434CB"
      />
    </svg>
  );
};
