import { Image } from "@chakra-ui/react";
import { ComponentPropsWithoutRef } from "react";
import { cn } from "../../utils/utils";
import { VariantProps, cva } from "class-variance-authority";

const wrapImageStyles = cva(
  [
    "inline-block leading-[0]",
    "md:p-[12px] px-[10px] py-[6px]",
    "shadow-[0px_0px_48px_0px_#00000017]",
    "rounded-[14px] md:rounded-[18px]",
  ],
  {
    variants: {
      variant: {
        white: "bg-white",
        dark: "bg-[#4E4E4E]",
      },
    },
    defaultVariants: {
      variant: "white",
    },
  },
);

export interface StoryTellingWrapImageProps
  extends ComponentPropsWithoutRef<"div">,
    VariantProps<typeof wrapImageStyles> {
  src: string;
  alt?: string;
}

const StoryTellingWrapImage = ({
  className,
  src,
  alt,
  variant,
  ...props
}: StoryTellingWrapImageProps) => {
  return (
    <div {...props} className={cn("leading-[0]", className)}>
      <div className={cn(wrapImageStyles({ variant }))}>
        <Image
          src={src}
          alt={alt}
          loading="lazy"
          className="mx-auto w-full md:max-w-[920px]"
        />
      </div>
    </div>
  );
};

export default StoryTellingWrapImage;
