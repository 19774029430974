export const CloseWarning = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-[10px] bg-black py-[15px] text-center text-14 font-medium uppercase text-white">
      <div className="max-w-[345px] md:max-w-[600px]">
        IMPORTANT: DO NOT CLOSE THIS WINDOW OR CLICK THE “BACK BUTTON”
      </div>
      <div className="max-w-[345px] md:max-w-[600px]">
        CLICKING YOUR “BACK” BUTTON CAN RESULT IN YOUR ORDER BEING DOUBLE-BILLED
      </div>
    </div>
  );
};
