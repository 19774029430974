import { Flex, Progress } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import bgDesktop from "../assets/images/calendar-booking/calendar-booking-bg-desktop.webp";
import bgMobile from "../assets/images/calendar-booking/calendar-booking-bg-mobile.webp";
import { ROUTES } from "../constants";
import { useDelayNavigation } from "../hooks/useDelayNavigation";

const content = [
  {
    text: "Thank you for your answers",
    time: 2000,
    highlightText: (name: string) => name,
  },
  {
    text: "You're great",
    time: 1500,
    highlightText: (name: string) => {
      if (!name) return "";
      return `${name}!`;
    },
  },
];

const WaitListPage = () => {
  const navigate = useDelayNavigation();
  const [currentStep, setCurrentStep] = useState(0);

  const [params] = useSearchParams();

  const name = params.get("name")?.split(" ")[0] || "";

  useEffect(() => {
    if (currentStep === content.length) return;

    const { time } = content[currentStep];

    const interval = setInterval(() => {
      setCurrentStep((prevStep) => prevStep + 1);
    }, time);

    return () => {
      clearInterval(interval);
    };
  }, [currentStep]);

  const renderContent = (step: number) => {
    const { text, highlightText } = content[step];
    return (
      <div className="w-full flex flex-col gap-7.5 items-center">
        <div className="w-full md:w-[389px]">
          <Progress
            size={'sm'}
            isIndeterminate
            background={'rgba(217, 217, 217, 1)'}
            borderRadius={10}
          />
        </div>
        <p className="text-center text-28 font-black md:text-32">
          {text}{" "}
          {highlightText && (
            <span className="text-primary">{highlightText(name)}</span>
          )}
        </p>
      </div>
    );
  };

  const handleNavigateToMemberShipPage = () => {
    navigate(ROUTES.MEMBERSHIP);
  };

  return (
    <Flex
      className="min-h-screen w-screen items-center justify-center bg-cover px-6 py-6"
      backgroundImage={{
        base: `url(${bgMobile})`,
        md: `url(${bgDesktop})`,
      }}
    >
      <div className="flex w-full flex-col items-center justify-center text-center">
        {currentStep === content.length ? (
          <>
            <p className="mb-5 flex h-[36px] items-center text-[32px] font-black md:h-[61px] md:text-48">
              Thank you!
            </p>
            <p className="mb-2.5 flex h-[54px] items-center text-24 font-semibold leading-[26.88px] md:mb-[15px] md:h-[46px] md:text-[36px] md:leading-normal">
              You have been added to the waitlist 🙌
            </p>
            <p className="mb-7.5 flex h-[54px] items-center text-24 font-medium leading-[26.88px] md:mb-[38px] md:h-[46px] md:text-[36px] md:font-[400] md:leading-normal">
              We'll contact you when there's availability to work with Jake.
            </p>

            <button
              onClick={handleNavigateToMemberShipPage}
              className="h-[66px] w-full rounded-[15px] bg-primary text-24 font-semibold text-white md:h-[70px] md:w-[315px]">
              Back to Fast Start
            </button>
          </>
        ) : (
          <>
            <div className="mb-7.5 h-2 w-full rounded-[10px] md:w-[389px]"></div>
            {renderContent(currentStep)}
          </>
        )}
      </div>
    </Flex>
  );
};

export default WaitListPage;
