export const LockIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C9.243 2 7 4.243 7 7V10H6C4.896 10 4 10.896 4 12V20C4 21.104 4.896 22 6 22H8H11H13H15H18C19.104 22 20 21.104 20 20V12C20 10.896 19.104 10 18 10H17V7C17 4.243 14.757 2 12 2ZM9 7C9 5.346 10.346 4 12 4C13.654 4 15 5.346 15 7V10H9V7ZM13 17.723V20H11V17.723C10.273 17.301 9.831 16.445 10.061 15.499C10.237 14.779 10.841 14.197 11.567 14.046C12.861 13.774 14 14.753 14 16C14 16.739 13.596 17.377 13 17.723Z"
        fill="white"
      />
    </svg>
  );
};
