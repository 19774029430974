import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  DateCalendar,
  DateCalendarProps,
} from "@mui/x-date-pickers/DateCalendar";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import "./index.css";
import dayjs, { Dayjs } from "dayjs";

import "@fontsource/inter";

import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(updateLocale);

// Set the first day of the week to Monday
dayjs.updateLocale("en", {
  weekStart: 1,
});

const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface CalendarProps extends DateCalendarProps<any> {}

const Calendar = (props: CalendarProps) => {
  const handleOnChangeDate = (value: Dayjs) => {
    console.log(value.toString());
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            {...props}
            views={["day"]}
            showDaysOutsideCurrentMonth
            fixedWeekNumber={5}
            dayOfWeekFormatter={(weekday) => `${weekday.format("dd")}`}
            onChange={handleOnChangeDate}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Calendar;
